import React, { useState } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Radio, { RadioProps } from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import CustomSearchField from "../../../../components/src/CustomSearchField";
import { Typography } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { debounce } from 'lodash';
import { City } from 'country-state-city'
import { Country } from 'country-state-city';
import { onChange } from "react-native-reanimated";
import AutoCompleteSearch from "../../../../..//packages/components/src/AutoCompleteSearch";
import { StateOfCatelogue } from "../CatalogueController1";


const useStyles = makeStyles({
    root: {
        "&:hover": {
            backgroundColor: "transparent",
        },
    },
    "& .MuiAutocomplete-popper": {
        zIndex: 1000000000000000000000000000000000000,
    },
    icon: {
        borderRadius: "50%",
        width: 16,
        height: 16,
        boxShadow:
            "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
        backgroundColor: "white",
        backgroundImage:
            "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
        "$root.Mui-focusVisible &": {
            outline: "2px auto #64748B",
            outlineOffset: 2,
        },
        "input:hover ~ &": {
            backgroundColor: "#ebf1f5",
        },
        "input:disabled ~ &": {
            boxShadow: "none",
            background: "rgba(206,217,224,.5)",
        },
    },
    checkedIcon: {
        backgroundColor: "#137cbd",
        backgroundImage: "linear-gradient(180deg,#1F497D,#1F497D)",
        "&:before": {
            display: "block",
            width: 16,
            height: 16,
            backgroundImage: "radial-gradient(#fff,#fff 28%,transparent 32%)",
            content: '""',
        },
        "input:hover ~ &": {
            backgroundColor: "#64748B",
        },

    },
    selectEmpty: {
        border: "1px solid #EEECE1",
        borderRadius: "8px",
        margin: "0px !important",
        padding: "2px 5px",
        background: "white",
        "& .MuiInput-input": {
            background: "white",
        },
        "& .MuiInput-input:focus": {
            backgroundColor: "white",
        },
        "& .MuiInput-underline": {
            display: "none"
        },
        "& .MuiInput-formControl": {
            marginTop: "2px"
        }
    },
    headerTitle: {
        "color": "#1F497D",
        "fontFamily": "Arial",
        "fontSize": "14px",
        "fontStyle": "normal",
        "fontWeight": "bold",
        "lineHeight": "18px"
    },
    radioTitle: {
        "color": "#1F497D",
        "fontFamily": "Arial",
        "fontSize": "12px",
        "fontStyle": "normal",
        "fontWeight": "lighter",
        "lineHeight": "18px"
    },
    titleOfRadio: {
        fontFamily: "Arial",
        fontWeight: "lighter",
        color: "#1F497D",
        fontSize: "14px",
        "marginTop": "5px"
    },
    rootAutoComplete: {
        "& .MuiInputBase-root": {
            padding: "5px !important"
        },
    },
    option: {
        color: "#1F497D",
        fontFamily: "Arial",
        fontSize: "14px",
        "&[data-focus='true']": {
            backgroundColor: "rgba(31, 73, 125, 0.1)",
        },
        "&[aria-selected='true']": {
            backgroundColor: "rgba(31, 73, 125, 0.2)",
        }
    }
});

function StyledRadio(props: RadioProps) {
    const classes = useStyles();

    return (
        <Radio
            className={classes.root}
            disableRipple
            color="default"
            checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
            icon={<span className={classes.icon} />}
            {...props}
        />
    );
}

type Props = {
    countries?: { label: string, value: string, isoCode: string }[],
    city?: { label: string, value: string }[],
    cityListHandler?: (e: React.ChangeEvent<HTMLInputElement>) => void,
    countryListHandler?: (e: React.ChangeEvent<HTMLInputElement>) => void,
    locationRadioButtonHandler?: (event: React.ChangeEvent<HTMLInputElement>, value: string) => void,
    handleCountryChange: (value: string, isoCode: string | null | undefined) => void,
    handleCityChange: (value: string) => void,
    state: StateOfCatelogue
}

export default function CustomizedRadios(props: Props) {
    const classes = useStyles();
    const { countries, handleCityChange, handleCountryChange, city, countryListHandler, cityListHandler, locationRadioButtonHandler, state } = props


    return (
        <FormControl component="fieldset">
            <label className={classes.headerTitle}>Location Preferences</label>
            <RadioGroup
                defaultValue="Country"
                aria-label="Country"
                name="customized-radios"
                onChange={locationRadioButtonHandler}
            >
                <FormControlLabel
                    value="Country"
                    control={<>&nbsp;&nbsp;&nbsp;</>}
                    label={<Typography className={classes.titleOfRadio}>Country</Typography>}
                />
                <Autocomplete
                    id="combo-box-demo"
                    className={classes.rootAutoComplete}
                    options={countries || []}
                    onChange={(event, value)=> handleCountryChange((event.target as HTMLElement).textContent || "", value?.isoCode)}
                    autoSelect
                    classes={{
                        option: classes.option
                    }}
                    value={{label: state.selectedCountryRadio, value: state.selectedCountryRadio, isoCode: state.selectedCountryRadio}}
                    getOptionLabel={(option) => option.label}
                    renderInput={(params) => <AutoCompleteSearch
                        name="country"
                        title=""
                        params={params}
                        placeHolder="Search"
                    />}
                />
                <FormControlLabel value="City" control={<>&nbsp;&nbsp;&nbsp;</>} label={<Typography className={classes.titleOfRadio}>City</Typography>} />
                <Autocomplete
                    id="combo-box-demo"
                    className={classes.rootAutoComplete}
                    options={city || []}
                    onChange={(event)=> handleCityChange((event.target as HTMLElement).textContent || "")}
                    autoSelect
                    classes={{
                        option: classes.option
                    }}
                    //@ts-ignore
                    value={state.selectedCityRadio}
                    getOptionLabel={(option) => option.label}
                    renderInput={(params) => <AutoCompleteSearch
                        name="country"
                        placeHolder="Search"
                        title=""
                        onChange={cityListHandler}
                        params={params}
                    />}
                />
            </RadioGroup>
        </FormControl>
    );
}
