import { Box, Button, Divider, Grid, TextField, Theme, Typography, createStyles, makeStyles, styled } from '@material-ui/core'
import React from 'react'
import { logoModal } from '../assets'
import { Autocomplete } from '@material-ui/lab'
import AutoCompleteInputText from './AutoCompleteInputText'
import { City, Country, ICity, ICountry } from 'country-state-city'
import { S } from '../ProfilePageForRecruiterController.web'
import { getAllCitiesFromApi, handleKeyboardScroll, handleScroll } from '../../../../../packages/components/src/Utils.web'
import PhoneInput, { CountryData } from "react-phone-input-2";
import 'react-phone-input-2/lib/style.css';
interface Props {
    updateUserDetailsFromChat: () => void;
    handleCloseModal: (value: boolean) => void;
    companyListHandler: (event: React.ChangeEvent<HTMLInputElement>) => void;
    handleCitiesList: (value: { value: string, label: string }[]) => void;
    handleChangeChatBotField: (value: string, fieldName: string, cId?: string, countryData?: CountryData | {} | undefined) => void;
    validateOTP: (email: string, otp: number) => void;
    state: S;
    handleKeyDown: (name: string, value: boolean) => void;
    sendOTPonMail: (value: string) => void
}

const stylesList = makeStyles((theme: Theme) =>
    createStyles({
        experienceBtnContainerSelected: {
            border: "1px solid #1F497D",
            borderRadius: "26px",
            color: "#FFFFFF",
            fontFamily: "Arial",
            background: "#1F497D",
            padding: "6px 12px",
            fontWeight: "lighter",
            margin: "5px",
            cursor: "pointer",
            fontSize: "14px"
        },
        skillButton: {
            borderRadius: "26px",
            border: "1px solid #1F497D",
            padding: "6px 12px",
            color: "#FFFFFF",
            fontFamily: "Arial",
            fontWeight: "lighter",
            background: "#1F497D",
            fontSize: "14px",
            margin: "5px",
            cursor: "pointer",
        },
        root: {
            display: 'flex',
            position: 'relative',
            alignItems: 'center',
            borderRadius: "12px",
            "& .MuiOutlinedInput-input": {
                borderRadius: "12px",
                border: "0px solid #9C9C9C",
                background: "#FFF",
                color: "#6D97C1",
                "fontFamily": "Arial",
                "fontSize": "14px",
                "fontStyle": "normal",
                "fontWeight": "lighter",
                "lineHeight": "22px",
                "&:hover": {
                    border: "0px solid #9C9C9C !important",
                },
                "&:focus": {
                    border: "0px solid #9C9C9C !important",
                },
                "&::placeholder": {
                    "color": "#64748B",
                    "fontFamily": "Arial",
                    "fontSize": "14px",
                    "fontStyle": "normal",
                    "fontWeight": "lighter",
                    "lineHeight": "22px"
                },

            },
            "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "rgba(0, 0, 0, 0.23) !important",
                border: "px solid #9C9C9C !important",
                "&:hover": {
                    border: "2px solid #9C9C9C !important",
                    borderColor: "#9C9C9C !important",
                },
                "&:focus": {
                    border: "2px solid #9C9C9C !important",
                    borderColor: "#9C9C9C !important",
                },
            },
            "& .MuiOutlinedInput-root": {
                height: "44px",
            },
            "& .MuiFormHelperText-contained": {
                marginLeft: '0px'
            },
            "& .MuiInputBase-input": {
                height: "10px"
            },
        },
        experienceBtnContainer: {
            borderRadius: "26px",
            padding: "6px 12px",
            border: "1px solid #1F497D",
            color: "#1F497D",
            fontFamily: "Arial",
            fontWeight: "lighter",
            fontSize: "14px",
            margin: "5px",
            cursor: "pointer",
        },

    })
);

const ChatBotFormRecruiter = (props: Props) => {
    let {
        handleChangeChatBotField,
        handleCitiesList,
        companyListHandler,
        handleCloseModal,
        updateUserDetailsFromChat,
        handleKeyDown,
        sendOTPonMail,
        state: {
            userDetails,
            countriesList,
            cities,
            companyList,
            totalSeconds,
            chatBotAnswersRecruiter: {
                city,
                country,
                contact,
                job_title,
                current_company,
                personalEmail,
                workEmail,
                enterOTP,
                websiteLink,
                companySize,
                tempPersonalEmail,
                tempWorkEmail,
                tempOTP,
                personalEmailError,
                workEmailError,
            },
            chatBotAnswersRecruiterToShow: {
                showcurrent_company,
                showpersonalEmail,
                showworkEmail,
                showenterOTP,
                showwebsiteLink,
                showContactNumberError,
                showwebsiteLinkMain
            },
            loadingChatButton
        }
    } = props;

    let styles = stylesList()

    let component = (renderComponent: boolean, condition: boolean, component: JSX.Element, question: string, answer: string) => {

        if (condition) {
            if (renderComponent) {
                return component
            }
            return <></>
        } else {
            if (question.length > 0 && answer.length > 0) {
                return <>
                    <Grid item sm={12} style={{ padding: "14px 25px 0px 25px", display: "flex" }}>
                        <span style={{ display: "flex", textAlign: "start", background: "#F8FAFC", padding: "18px", borderRadius: "12px 12px 12px 0px", width: "auto", alignItems: "center", }}>
                            <span style={{ fontFamily: "Arial", fontSize: '14px', fontWeight: "lighter", color: "#17365D" }}>{question}</span>
                        </span>
                    </Grid>
                    <Grid item sm={12} style={{ padding: "14px 25px 0px 25px", justifyContent: "flex-end", display: "flex", }}>
                        <span style={{ display: "flex", background: "rgba(109, 151, 193, 0.2)", padding: "18px", borderRadius: "12px 12px 0px 12px", width: "auto", alignItems: "center", textAlign: "start", }}>
                            <span style={{ fontSize: '14px', color: "#1F497D", fontFamily: "Arial", fontWeight: "lighter" }}>{answer}</span>
                        </span >
                    </Grid>
                </>
            } else {
                return <></>
            }
        }

    }


    let countryCondition = () => !((country?.value !== "") && (current_company !== ""));

    let cityConditionOne = () => !!country;
    let cityConditionTwo = () => !((city?.value !== "") && (current_company !== ""));

    let contactConditionOne = () => !!city;
    let contactConditionTwo = () => !((contact !== "") && (current_company !== ""));

    let personalEmailConditionOne = () => (showpersonalEmail && !!contact);
    let personalEmailConditionTwo = () => !((personalEmail !== "") && (companySize !== ""));

    let workEmailConditionOne = () => (showworkEmail && !!personalEmail);
    let workEmailConditionTwo = () => !((workEmail !== "") && (companySize !== ""));

    let enterOTPConditionOne = () => (showenterOTP && !!workEmail);
    let enterOTPConditionTwo = () => !((enterOTP !== "") && (companySize !== ""));

    let jobTitleConditionOne = () => !!enterOTP;
    let jobTitleConditionTwo = () => !((job_title !== "") && (companySize !== ""));

    let currentCompanyConditionOne = () => (showcurrent_company && !!job_title);
    let currentCompanyConditionTwo = () => !((job_title !== "") && (companySize !== ""));

    let websiteLinkConditionOne = () => (showwebsiteLinkMain && !!current_company);
    let websiteLinkConditionTwo = () =>  !((websiteLink !== "") && (companySize !== ""));



    return (
        <div 
        id="chat-bot-one-modal"
        tabIndex={0}
        onKeyDown={(event) => handleKeyboardScroll(event, "chat-bot-one-modal")}
        style={{ margin: "0px", display: "flex", flexDirection: "column", padding: "0px", width: "100%", justifyContent: "space-between", height: "100vh" }}>
            <style>
                {
                    `.MuiAutocomplete-option:hover {
                        color: #FFFFFF !important;
                        background-color:  #1F497D !important;
                        border-radius: 8px !important;
                    }`
                }
            </style>
            <div>
                <Grid item sm={12} style={{ display: "flex", justifyContent: "space-between", width: "100%", alignItems: "center", padding: "14px 25px 0px 25px" }}>
                    <img src={logoModal} style={{ height: "55px", width: "55px" }} />
                    <svg width="14" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ cursor: "pointer" }} id="close-modal" height="14" onClick={() => handleCloseModal(false)}>
                        <path d="M14 1.91L12.59 0.5L7 6.09L1.41 0.5L0 1.91L5.59 7.5L0 13.09L1.41 14.5L7 8.91L12.59 14.5L14 13.09L8.41 7.5L14 1.91Z" fill="#334155" />
                    </svg>
                </Grid>
                <Grid item sm={12} style={{ padding: "30px 25px 0px 25px", display: "flex" }}>
                    <span style={{ background: "#F8FAFC", padding: "18px", display: "flex", alignItems: "center", textAlign: "start", borderRadius: "12px 12px 12px 0px" }}>
                        <span style={{ fontWeight: "lighter", fontFamily: "Arial", fontSize: '14px', color: "#17365D" }}>Hello {userDetails.full_name}, welcome to the job recruiter profile setup! Let's get started to enhance your job recruiting experience.</span>
                    </span>
                </Grid>
                {
                    component(true, countryCondition(), <>
                        <Grid item sm={12} style={{ padding: "14px 25px 0px 25px", display: "flex" }}>
                            <span style={{ display: "flex", alignItems: "center", textAlign: "start", background: "#F8FAFC", padding: "18px", borderRadius: "12px 12px 12px 0px" }}>
                                <span style={{ fontFamily: "Arial", fontSize: '14px', fontWeight: "lighter", color: "#17365D" }}>In which country do you live?</span>
                            </span>
                        </Grid>
                        <Grid item sm={12} style={{ padding: "14px 25px 0px 25px" }}>
                            <Autocomplete
                                id="combo-box-demo"
                                options={countriesList.map((country: {name: string, isoCode: number}) => ({
                                    value: country.name,
                                    label: country.name,
                                    cId: `${country.isoCode}`
                                }))}
                                value={country}
                                getOptionLabel={(option) => option?.label || ''}
                                onChange={async (event, value) => {
                                    if (value?.cId) {
                                        let cities = await getAllCitiesFromApi(value.cId);
                                        handleCitiesList(cities);
                                        handleChangeChatBotField(value.value, "country", value.cId);
                                        setTimeout(() => {
                                            document.getElementById("combo-box-demo-1")!.focus();
                                        })
                                    }
                                }}
                                renderInput={(params) => <AutoCompleteInputText errors={{}}
                                    params={params}
                                    touched={false}
                                    title=""
                                    name="country_field"
                                    placeHolder="Country"
                                    borderRadius={"12px"}
                                />}
                            />
                        </Grid>
                    </>,
                        "In which country do you live?",
                        country?.value as string
                    )
                }
                {
                    component(cityConditionOne(), cityConditionTwo(), <>
                        <Grid item sm={12} style={{ padding: "14px 25px 0px 25px", display: "flex" }}>
                            <span style={{ display: "flex", alignItems: "center", textAlign: "start", background: "#F8FAFC", padding: "18px", borderRadius: "12px 12px 12px 0px", width: "auto" }}>
                                <span style={{ fontFamily: "Arial", fontSize: '14px', fontWeight: "lighter", color: "#17365D" }}>In which city do you live?</span>
                            </span>
                        </Grid>
                        <Grid item sm={12} style={{ padding: "14px 25px 0px 25px" }}>
                            <Autocomplete
                                id="combo-box-demo-1"
                                options={cities}
                                getOptionLabel={(option) => option?.label ? option.label : ""}
                                value={city}
                                onChange={(event, value) => {
                                    if (value?.value) {
                                        handleChangeChatBotField(value.value, "city");
                                    }
                                }}
                                renderInput={(params) => <AutoCompleteInputText
                                    errors={{}}
                                    title=""
                                    name="city_field"
                                    placeHolder="City"
                                    params={params}
                                    touched={false}
                                    borderRadius={"12px"}
                                />
                                }
                            />
                        </Grid>
                    </>,
                        "In which city do you live?",
                        city?.value || ""
                    )
                }
                {
                    component(contactConditionOne(), contactConditionTwo(), <>
                        <Grid item sm={12} style={{ padding: "14px 25px 0px 25px", display: "flex" }}>
                            <span style={{ display: "flex", alignItems: "center", textAlign: "start", background: "#F8FAFC", padding: "18px", borderRadius: "12px 12px 12px 0px", width: "auto" }}>
                                <span style={{ fontFamily: "Arial", fontSize: '14px', fontWeight: "lighter", color: "#17365D" }}>Could you please provide your contact number?</span>
                            </span>
                        </Grid>
                        <Grid item sm={12} style={{ padding: "14px 25px 0px 25px" }}>
                            <PhoneInputStyled
                                containerStyle={{
                                    height: "44px"
                                }}
                                inputStyle={{
                                    height: "44px",
                                    color: "#6D97C1",
                                    width: "100%",
                                    borderRadius: "12px"
                                }}
                                dropdownStyle={{
                                    borderBottomLeftRadius: "8px",
                                    borderTopLeftRadius: "8px",
                                    background: "white"
                                }}
                                autoFormat
                                enableSearch
                                specialLabel="Contact Number"
                                data-test-id="PhoneInputStyled"
                                searchNotFound="Country Not Found"
                                placeholder={"Enter your contact number here"}
                                value={contact}
                                onChange={(value, countryData) => {
                                    handleChangeChatBotField(value, "contact", "", countryData)
                                }}
                                onKeyDown={(event) => {
                                    if (event.key === 'Enter' && !showContactNumberError) {
                                        handleKeyDown("showpersonalEmail", true)
                                        setTimeout(() => {
                                            document.getElementById("personalEmail")!.focus();
                                        });
                                    } 
                                }}
                            />
                            {showContactNumberError && <p id="error-id" style={{
                                "color": "#DC2626",
                                "fontFamily": "Arial",
                                "fontSize": "12px",
                                "fontStyle": "normal",
                                "fontWeight": "lighter",
                                "lineHeight": "18px",
                                "display": "flex"
                                , marginTop: '6px'
                            }}>Please enter valid contact number</p>}
                        </Grid>
                    </>,
                        "Could you please provide your contact number?",
                        contact
                    )
                }
                {
                    component(personalEmailConditionOne(), personalEmailConditionTwo(), <>
                        <Grid item sm={12} style={{ padding: "14px 25px 0px 25px", display: "flex" }}>
                            <span style={{ display: "flex", alignItems: "center", textAlign: "start", background: "#F8FAFC", padding: "18px", borderRadius: "12px 12px 12px 0px", width: "auto" }}>
                                <span style={{ fontFamily: "Arial", fontSize: '14px', fontWeight: "lighter", color: "#17365D" }}>Perfect! What is your current personal email id?</span>
                            </span>
                        </Grid>
                        <Grid item sm={12} style={{ padding: "14px 25px 0px 25px" }}>
                            <TextField
                                fullWidth
                                id="personalEmail"
                                name="personalEmail"
                                type='string'
                                variant="outlined"
                                value={tempPersonalEmail}
                                InputLabelProps={{ shrink: true }}
                                InputProps={{ classes: { root: styles.root } }}
                                placeholder="Enter your personal email"
                                onChange={(event) => handleChangeChatBotField(event.target.value, "personalEmail")}
                                onKeyDown={(event) => {
                                    if (event.key === "Enter") {
                                        handleChangeChatBotField(tempPersonalEmail, "personalEmailExt");
                                        handleKeyDown("showworkEmail", true)
                                    }
                                }}
                            />
                        </Grid>
                        {
                            personalEmailError && <Grid style={{ padding: "10px 24px" }}>
                                <Typography style={{ fontFamily: "Arial", fontWeight: "lighter", fontSize: "12px", color: "#DC2626" }}>{personalEmailError}</Typography>
                            </Grid>
                        }
                    </>,
                        "Perfect! What is your current personal email id?",
                        personalEmail
                    )
                }
                {
                    component(workEmailConditionOne(), workEmailConditionTwo(), <>
                        <Grid item sm={12} style={{ padding: "14px 25px 0px 25px", display: "flex" }}>
                            <span style={{ display: "flex", alignItems: "center", textAlign: "start", background: "#F8FAFC", padding: "18px", borderRadius: "12px 12px 12px 0px", width: "auto" }}>
                                <span style={{ fontFamily: "Arial", fontSize: '14px', fontWeight: "lighter", color: "#17365D" }}>Perfect! What is your current work email id?</span>
                            </span>
                        </Grid>
                        <Grid item sm={12} style={{ padding: "14px 25px 0px 25px" }}>
                            <TextField
                                fullWidth
                                id="workEmail"
                                name="workEmail"
                                type='string'
                                variant="outlined"
                                value={tempWorkEmail}
                                InputLabelProps={{ shrink: true }}
                                InputProps={{ classes: { root: styles.root } }}
                                placeholder="Enter your work email"
                                onChange={(event) => handleChangeChatBotField(event.target.value, "workEmail")}
                                onKeyDown={(event) => {
                                    if (event.key === "Enter") {
                                        handleChangeChatBotField(tempWorkEmail, "workEmailExt");
                                    }
                                }}
                                disabled={workEmail !== ""}
                            />
                        </Grid>
                        {
                            workEmailError && <Grid style={{ padding: "10px 24px" }}>
                                <Typography style={{ fontFamily: "Arial", fontWeight: "lighter", fontSize: "12px", color: "#DC2626" }}>{workEmailError}</Typography>
                            </Grid>
                        }
                    </>,
                        "Perfect! What is your current work email id?",
                        workEmail
                    )
                }
                {
                    component(enterOTPConditionOne(), enterOTPConditionTwo(), <>
                        <Grid item sm={12} style={{ padding: "14px 25px 0px 25px", display: "flex" }}>
                            <span style={{ display: "flex", alignItems: "center", textAlign: "start", background: "#F8FAFC", padding: "18px", borderRadius: "12px 12px 12px 0px", width: "auto" }}>
                                <span style={{ fontFamily: "Arial", fontSize: '14px', fontWeight: "lighter", color: "#17365D" }}>Please enter the OTP sent to your {workEmail}</span>
                            </span>
                        </Grid>
                        <Grid item sm={12} style={{ padding: "14px 25px 0px 25px" }}>
                            <TextField
                                fullWidth
                                id="enterOTP"
                                name="enterOTP"
                                type='string'
                                variant="outlined"
                                value={tempOTP}
                                InputLabelProps={{ shrink: true }}
                                InputProps={{ classes: { root: styles.root } }}
                                placeholder="Enter OTP"
                                disabled={!!enterOTP}
                                onChange={(event) => handleChangeChatBotField(event.target.value, "enterOTP")}
                                onKeyDown={(event) => {
                                    if (event.key === "Enter") {
                                        handleChangeChatBotField(tempOTP, "enterOTPExt")
                                    }
                                }}
                            />
                        </Grid>
                        {
                            !enterOTP && <Grid style={{ padding: "10px 25px" }}>
                                <Button style={{
                                    fontFamily: "Arial",
                                    fontWeight: "lighter",
                                    fontSize: "14px",
                                    color: "#1F497D",
                                    height: "32px",
                                    border: "1px solid #1F497D",
                                    borderRadius: "26px",
                                    textTransform: "none",
                                    padding: "6px 12px",
                                    cursor: "none"
                                }}>
                                    {Math.floor(totalSeconds / 60).toString().padStart(2, '0') + ":" + (totalSeconds % 60).toString().padStart(2, '0') + " Left"}
                                </Button>
                                &nbsp;
                                &nbsp;
                                &nbsp;
                                <Button style={{
                                    fontFamily: "Arial",
                                    fontWeight: "lighter",
                                    fontSize: "14px",
                                    color: "#FFFFFF",
                                    height: "32px",
                                    background: "#1F497D",
                                    border: "1px solid #1F497D",
                                    borderRadius: "26px",
                                    padding: "6px 12px",
                                    textTransform: "none"
                                }}
                                    onClick={() => sendOTPonMail(workEmail)}
                                >
                                    Didn’t receive OTP?&nbsp;<b style={{ fontWeight: "bold" }}>Resend</b>
                                </Button>
                            </Grid>
                        }
                    </>,
                        "",
                        ""
                    )
                }
                {
                    component(jobTitleConditionOne(), jobTitleConditionTwo(), <>
                        <Grid item sm={12} style={{ padding: "14px 25px 0px 25px", display: "flex" }}>
                            <span style={{ display: "flex", alignItems: "center", textAlign: "start", background: "#F8FAFC", padding: "18px", borderRadius: "12px 12px 12px 0px", width: "auto" }}>
                                <span style={{ fontFamily: "Arial", fontSize: '14px', fontWeight: "lighter", color: "#17365D" }}>Great! Could you tell us what is your job title?</span>
                            </span>
                        </Grid>
                        <Grid item sm={12} style={{ padding: "14px 25px 0px 25px" }}>
                            <TextField
                                fullWidth
                                id="job_title"
                                name="job_title"
                                type='string'
                                variant="outlined"
                                InputLabelProps={{ shrink: true }}
                                onKeyDown={(event) => {
                                    if (event.key === 'Enter') {
                                        handleKeyDown("showcurrent_company", true)
                                        setTimeout(() => {
                                            document.getElementById("combo-box-demo-2")!.focus();
                                        }, 1000);
                                    } 
                                }}
                                InputProps={{ classes: { root: styles.root } }}
                                placeholder="Investment Professor"
                                onChange={(event) => handleChangeChatBotField(event.target.value, "job_title")}
                            />
                        </Grid>
                    </>,
                        "Great! Could you tell us what is your job title?",
                        job_title
                    )
                }
                {
                    component(currentCompanyConditionOne(), currentCompanyConditionTwo(),
                        <>
                            <Grid item sm={12} style={{ padding: "14px 25px 0px 25px", display: "flex" }}>
                                <span style={{ display: "flex", alignItems: "center", textAlign: "start", background: "#F8FAFC", padding: "18px", borderRadius: "12px 12px 12px 0px", width: "auto" }}>
                                    <span style={{ fontFamily: "Arial", fontSize: '14px', fontWeight: "lighter", color: "#17365D" }}>Could you please tell us what is your current company?</span>
                                </span>
                            </Grid>
                            <Grid item sm={12} style={{ padding: "14px 25px 0px 25px" }}>
                                <Autocomplete
                                    id="combo-box-demo-2"
                                    options={companyList}
                                    getOptionLabel={(option) => option ? option : ""}
                                    value={current_company}
                                    onChange={(event, value) => {
                                        if (value) {
                                            handleScroll()
                                            handleChangeChatBotField(value, "current_company")
                                            handleKeyDown("showwebsiteLinkMain", true);
                                        }
                                    }}
                                    onKeyDown={(event) => {
                                        if (event.key === 'Enter') {
                                            handleKeyDown("showwebsiteLinkMain", true);
                                            handleScroll();
                                            setTimeout(() => {
                                                document.getElementById("websiteLink")!.focus();
                                            }, 1000);
                                        }
                                    }}
                                    renderInput={(params) => <AutoCompleteInputText
                                        errors={{}}
                                        title=""
                                        name="city_field"
                                        placeHolder="Current company"
                                        params={params}
                                        touched={false}
                                        borderRadius={"12px"}
                                        onChange={(event) =>{
                                            handleChangeChatBotField(event.target.value, "current_company")
                                            companyListHandler(event)
                                        }}
                                    />
                                    }
                                />
                            </Grid>
                        </>,
                        "Could you please tell us what is the current company?",
                        current_company
                    )
                }
                {
                    component(websiteLinkConditionOne(),websiteLinkConditionTwo(), <>
                        <Grid item sm={12} style={{ padding: "14px 25px 0px 25px", display: "flex" }}>
                            <span style={{ display: "flex", alignItems: "center", textAlign: "start", background: "#F8FAFC", padding: "18px", borderRadius: "12px 12px 12px 0px", width: "auto" }}>
                                <span style={{ fontFamily: "Arial", fontSize: '14px', fontWeight: "lighter", color: "#17365D" }}>Could you please provide us a website link?</span>
                            </span>
                        </Grid>
                        <Grid item sm={12} style={{ padding: "14px 25px 0px 25px" }}>
                            <TextField
                                fullWidth
                                id="websiteLink"
                                name="websiteLink"
                                type='string'
                                variant="outlined"
                                onKeyDown={(event) => {
                                    if (event.key === 'Enter') {
                                        handleKeyDown("showwebsiteLink", true)
                                    } 
                                }}
                                InputLabelProps={{ shrink: true }}
                                InputProps={{ classes: { root: styles.root } }}
                                placeholder="www.goofle.com"
                                onChange={(event) => handleChangeChatBotField(event.target.value, "websiteLink")}
                            />
                        </Grid>
                    </>,
                        "Could you please provide us a website link?",
                        websiteLink
                    )
                }
                {
                    component(
                        ( showwebsiteLink && !!websiteLink),
                        (companySize === ""),
                        <>
                            <Grid item sm={12} style={{ padding: "30px 25px 0px 25px", display: "flex" }}>
                                <span style={{ display: "flex", alignItems: "center", textAlign: "start", background: "#F8FAFC", padding: "18px", borderRadius: "12px 12px 12px 0px" }}>
                                    <span style={{ fontFamily: "Arial", fontSize: '14px', fontWeight: "lighter", color: "#17365D" }}>Great! Could you tell us about the size of the company?</span>
                                </span>
                            </Grid>
                            <Grid item sm={12} style={{ padding: "14px 25px 0px 25px", display: "flex", justifyContent: "start", flexWrap: "wrap" }}>
                                {
                                    ["50-100", "100-150", "150-200"]
                                        .map(value => <Box id="companySize-btn" className={companySize === value ? styles.experienceBtnContainerSelected : styles.experienceBtnContainer} onClick={() => handleChangeChatBotField(value, "companySize")}>
                                            <Typography>{value}</Typography>
                                        </Box>)
                                }
                            </Grid>
                        </>,
                        "Great! Could you tell us about the size of the company?",
                        companySize
                    )
                }
            </div>
            <div>
                <Divider style={{ width: "100%", height: "1px", background: "#E2E8F0", marginTop: "35px" }} />
                <Grid container sm={12} style={{ padding: "15px 25px", justifyContent: "end", display: "flex" }}>
                    <Button
                        id="target-button"
                        disabled={!companySize || loadingChatButton}
                        onClick={() => {
                            updateUserDetailsFromChat();
                        }}
                        style={{
                            color: "#FFF",
                            background: "#1F497D",
                            padding: "12px 28px",
                            borderRadius: "8px",
                            textTransform: "none",
                            opacity: !companySize ? 0.6 : 1,
                        }}>
                        Save
                    </Button>
                </Grid>
            </div>
        </div >
    )
}

const PhoneInputStyled = styled(PhoneInput)({
    '& .flag-dropdown': {
        "&:hover" : {
            borderRight: "0px !important",
            border: "2px solid #9c9c9c",
        },
        "&:focus" : {
            borderRight: "0px !important",
            border: "2px solid #9c9c9c",
        },
        background: "white",
        borderBottomLeftRadius: "8px !important",
        borderTopLeftRadius: "8px !important",
        borderRight: "0px !important"
    },
    "& .open": {
        background: "white",
        borderBottomLeftRadius: "8px !important",
        borderTopLeftRadius: "8px !important",
    },
    '& .form-control': {
        "&:focus" : {
            border: "2px solid #9c9c9c"
        },
        "&:hover" :{
            border: "2px solid #9c9c9c"
        },
        "&::placeholder": {
            "color": "#64748B",
            "fontSize": "14px",
            "fontFamily": "Arial",
            "fontStyle": "normal",
            "fontWeight": "lighter",
            "lineHeight": "22px"
        },        
    },
    "& .selected-flag": {
        borderTopLeftRadius: "8px !important",
        background: "white",
        borderBottomLeftRadius: "8px !important",
    },
    '& .form-control:focus + div': {
        border: "2px solid #9c9c9c"
    },
    '& .form-control:hover + div': {
        border: "2px solid #9c9c9c"
    },
});
export default ChatBotFormRecruiter;