import { Box, Button, Divider, Grid, TextField, Theme, Typography, createStyles, makeStyles, styled } from '@material-ui/core'
import React from 'react'
import { logoModal } from '../assets'
import { Autocomplete } from '@material-ui/lab'
import AutoCompleteInputText from './AutoCompleteInputText'
import { City, Country, ICity, ICountry } from 'country-state-city'
import { S } from '../RecruiterProfileController.web'
import CurrenyFieldWithCurrency from "../../../../components/src/CurrenyFieldWithCurrency.web"
import { getAllCitiesFromApi, handleKeyboardScroll } from '../../../../../packages/components/src/Utils.web'
import PhoneInput, { CountryData } from "react-phone-input-2";
import 'react-phone-input-2/lib/style.css';

interface Props {
    handleChangeChatBotField: (value: string, fieldName: string, cId?: string, countryData?: CountryData | {} | undefined) => void;
    state: S;
    handleCitiesList: (value: { value: string, label: string }[]) => void;
    companyListHandler: (event: React.ChangeEvent<HTMLInputElement>) => void;
    handleCloseModal: (value: boolean) => void;
    updateUserDetailsFromChat: () => void;
    handleKeyDown: (name: string, value: boolean) => void;
}

const stylesList = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            alignItems: 'center',
            borderRadius: "12px",
            position: 'relative',
            "& .MuiOutlinedInput-input": {
                borderRadius: "12px",
                border: "0px solid #9C9C9C",
                background: "#FFF",
                color: "#6D97C1",
                "fontFamily": "Arial",
                "fontSize": "14px",
                "fontStyle": "normal",
                "fontWeight": "lighter",
                "lineHeight": "22px",
                "&:hover": {
                    border: "0px solid #9C9C9C !important",
                },
                "&:focus": {
                    border: "0px solid #9C9C9C !important",
                },
                "&::placeholder": {
                    "color": "#64748B",
                    "fontFamily": "Arial",
                    "fontSize": "14px",
                    "fontStyle": "normal",
                    "fontWeight": "lighter",
                    "lineHeight": "22px"
                },

            },
            "& .MuiFormHelperText-contained": {
                marginLeft: '0px'
            },
            "& .MuiOutlinedInput-root": {
                height: "44px",
            },
            "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "rgba(0, 0, 0, 0.23) !important",
                border: "px solid #9C9C9C !important",
                "&:hover": {
                    border: "2px solid #9C9C9C !important",
                    borderColor: "#9C9C9C !important",
                },
                "&:focus": {
                    border: "2px solid #9C9C9C !important",
                    borderColor: "#9C9C9C !important",
                },
            },
            "& .MuiInputBase-input": {
                height: "10px"
            },
        },
        experienceBtnContainer: {
            border: "1px solid #1F497D",
            borderRadius: "26px",
            padding: "6px 12px",
            color: "#1F497D",
            fontFamily: "Arial",
            fontWeight: "lighter",
            margin: "5px",
            cursor: "pointer",
            fontSize: "14px"
        },
        experienceBtnContainerSelected: {
            border: "1px solid #1F497D",
            borderRadius: "26px",
            padding: "6px 12px",
            color: "#FFFFFF",
            fontFamily: "Arial",
            fontWeight: "lighter",
            background: "#1F497D",
            margin: "5px",
            cursor: "pointer",
            fontSize: "14px"
        },
        skillButton: {
            border: "1px solid #1F497D",
            borderRadius: "26px",
            padding: "6px 12px",
            color: "#FFFFFF",
            fontFamily: "Arial",
            fontWeight: "lighter",
            background: "#1F497D",
            margin: "5px",
            cursor: "pointer",
            fontSize: "14px"
        }
    })
);

const ChatBotFormOne = (props: Props) => {

    let {
        handleChangeChatBotField,
        handleCitiesList,
        companyListHandler,
        handleCloseModal,
        updateUserDetailsFromChat,
        handleKeyDown,
        state: {
            userDetails,
            cities,
            countriesList,
            skillsList,
            companyList,
            chatBotAnswers: {
                city,
                country,
                contact,
                skills,
                experience,
                job_title,
                current_company,
                current_salary,
                currency_value,
                tempSkill
            },
            chatBotAnswersToShow: {
            showjob_title,
            showexperience,
            showskills,
            showcurrent_company,
            showContactNumberErrorOne
            },
            loaderButton
        }
    } = props;

    let styles = stylesList()

    let component = (renderComponent: boolean, condition: boolean, component: JSX.Element, question: string, answer: string, skills?: string[]) => {

        if (condition) {
            if (renderComponent) {
                return component
            }
            return <></>
        } else {
            return <>
                <Grid item sm={12} style={{ padding: "14px 25px 0px 25px", display: "flex" }}>
                    <span style={{ display: "flex", alignItems: "center", textAlign: "start", background: "#F8FAFC", padding: "18px", borderRadius: "12px 12px 12px 0px", width: "auto" }}>
                        <span style={{ fontFamily: "Arial", fontSize: '14px', fontWeight: "lighter", color: "#17365D" }}>{question}</span>
                    </span>
                </Grid>
                <Grid item sm={12} style={{ padding: "14px 25px 0px 25px", display: "flex", justifyContent: "flex-end" }}>
                    {
                        skills ? skills.map((skill) => <span style={{ margin: "5px", display: "flex", alignItems: "center", textAlign: "start", background: "rgba(109, 151, 193, 0.2)", padding: "18px", borderRadius: "12px", width: "auto" }}>
                            <span style={{ fontFamily: "Arial", fontSize: '14px', fontWeight: "lighter", color: "#1F497D" }}>{skill}</span>
                        </span >) : <span style={{ display: "flex", alignItems: "center", textAlign: "start", background: "rgba(109, 151, 193, 0.2)", padding: "18px", borderRadius: "12px 12px 0px 12px", width: "auto" }}>
                            <span style={{ fontFamily: "Arial", fontSize: '14px', fontWeight: "lighter", color: "#1F497D" }}>{answer}</span>
                        </span >
                    }

                </Grid>
            </>
        }

    }

    let countryConditionX = () => !((country?.value !== "") && (showcurrent_company));

    let cityConditionOneX = () => !!country;
    let cityConditionTwoX = () => !((city?.value !== "") && (showcurrent_company));

    let contactConditionOneX = () => !!city;
    let contactConditionTwoX = () => !((contact !== "") && (showcurrent_company));

    let jobTitleConditionOneX = () => (showjob_title && !!contact);
    let jobTitleConditionTwoX = () => !((job_title !== "") && (showcurrent_company));

    let experienceConditionOneX = () => (showexperience && !!job_title);
    let experienceConditionTwoX = () => !((experience !== "") && (showcurrent_company));

    let currentCompanyConditionOne = () => skills.length > 0;
    let currentCompanyConditiontwo  = () =>!((skills.length > 0) && (showcurrent_company));

    return (
        <div
        id="chat-bot-one-modal"
        tabIndex={0}
        onKeyDown={(event) => handleKeyboardScroll(event, "chat-bot-one-modal")}
        style={{ margin: "0px", padding: "0px", width: "100%", display: "flex", flexDirection: "column", justifyContent: "space-between", height: "100vh" }}>
            <style>
                {
                    `.MuiAutocomplete-option:hover {
                            background-color:  #1F497D !important;
                            color: #FFFFFF !important;
                            border-radius: 8px !important;
                    }`
                }
            </style>
            <div>
                <Grid item sm={12} style={{ display: "flex", justifyContent: "space-between", width: "100%", alignItems: "center", padding: "14px 25px 0px 25px" }}>
                    <img src={logoModal} style={{ height: "55px", width: "55px" }} />
                    <svg width="14" height="14" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg" style={{cursor: "pointer"}} onClick={() => handleCloseModal(false)}>
                        <path d="M14 1.91L12.59 0.5L7 6.09L1.41 0.5L0 1.91L5.59 7.5L0 13.09L1.41 14.5L7 8.91L12.59 14.5L14 13.09L8.41 7.5L14 1.91Z" fill="#334155" />
                    </svg>
                </Grid>
                <Grid item sm={12} style={{ padding: "30px 25px 0px 25px", display: "flex" }}>
                    <span style={{ display: "flex", alignItems: "center", textAlign: "start", background: "#F8FAFC", padding: "18px", borderRadius: "12px 12px 12px 0px" }}>
                        <span style={{ fontFamily: "Arial", fontSize: '14px', fontWeight: "lighter", color: "#17365D" }}>Hello {userDetails.full_name}, welcome to the job seeker profile setup! Let's get started to enhance your job search experience.</span>
                    </span>
                </Grid>
                {
                    component(true, countryConditionX(), <>
                        <Grid item sm={12} style={{  display: "flex", padding: "14px 25px 0px 25px", }}>
                            <span style={{  textAlign: "start", background: "#F8FAFC", padding: "18px", borderRadius: "12px 12px 12px 0px", display: "flex", alignItems: "center", }}>
                                <span style={{ fontFamily: "Arial",  color: "#17365D", fontSize: '14px', fontWeight: "lighter", }}>In which country do you live?</span>
                            </span>
                        </Grid>
                        <Grid item sm={12} style={{ padding: "14px 25px 0px 25px" }}>
                            <Autocomplete
                                id="combo-box-demo"
                                options={countriesList.map((country: {name: string, isoCode: number}) => ({
                                    value: country.name,
                                    label: country.name,
                                    cId: `${country.isoCode}`
                                }))}
                                getOptionLabel={(option) => option?.label || ''}
                                value={country}
                                onChange={async (event, value) => {
                                    if (value?.cId) {
                                        let cities = await getAllCitiesFromApi(value.cId);
                                        handleCitiesList(cities);
                                        handleChangeChatBotField(value.value, "country", value.cId)
                                        setTimeout(() => {
                                            document.getElementById("combo-box-demo-1")!.focus();
                                        })
                                    }
                                }}
                                renderInput={(params) => <AutoCompleteInputText errors={{}}
                                    params={params}
                                    touched={false}
                                    title=""
                                    name="country_field"
                                    placeHolder="Country"
                                    borderRadius={"12px"}
                                />}
                            />
                        </Grid>
                    </>,
                        "In which country do you live?",
                        country?.value as string
                    )
                }
                {
                    component(cityConditionOneX(), cityConditionTwoX(), <>
                        <Grid item sm={12} style={{ padding: "14px 25px 0px 25px", display: "flex" }}>
                            <span style={{ background: "#F8FAFC", padding: "18px", borderRadius: "12px 12px 12px 0px", width: "auto",  display: "flex", alignItems: "center", textAlign: "start", }}>
                                <span style={{  fontWeight: "lighter", color: "#17365D", fontFamily: "Arial", fontSize: '14px' }}>In which city do you live?</span>
                            </span>
                        </Grid>
                        <Grid item sm={12} style={{ padding: "14px 25px 0px 25px" }}>
                            <Autocomplete
                                getOptionLabel={(option) => option?.label ? option.label : ""}
                                id="combo-box-demo-1"
                                value={city}
                                options={cities}
                                onChange={(event, value) => {
                                    if (value?.value) {
                                        handleChangeChatBotField(value.value, "city")
                                        setTimeout(() => {
                                            document.getElementById("contact")?.focus();
                                        });
                                    }
                                }}
                                renderInput={(params) => <AutoCompleteInputText
                                    errors={{}}
                                    title=""
                                    name="city_field"
                                    placeHolder="City"
                                    params={params}
                                    touched={false}
                                    borderRadius={"12px"}
                                />
                                }
                            />
                        </Grid>
                    </>,
                        "In which city do you live?",
                        city?.value || ""
                    )
                }
                {
                    component(contactConditionOneX(), contactConditionTwoX(), <>
                        <Grid item sm={12} style={{ padding: "14px 25px 0px 25px", display: "flex" }}>
                            <span style={{ textAlign: "start", background: "#F8FAFC", padding: "18px",  display: "flex", alignItems: "center",  borderRadius: "12px 12px 12px 0px", width: "auto" }}>
                                <span style={{ fontFamily: "Arial", fontSize: '14px', fontWeight: "lighter", color: "#17365D" }}>Could you please provide your contact number?</span>
                            </span>
                        </Grid>
                        <Grid item sm={12} style={{ padding: "14px 25px 0px 25px" }}>
                        <PhoneInputStyledOne
                                containerStyle={{
                                    height: "44px"
                                }}
                                inputStyle={{
                                    height: "44px",
                                    color: "#6D97C1",
                                    width: "100%",
                                    borderRadius: "12px"
                                }}
                                dropdownStyle={{
                                    borderBottomLeftRadius: "8px",
                                    borderTopLeftRadius: "8px",
                                    background: "white"
                                }}
                                autoFormat
                                enableSearch
                                specialLabel="Contact Number"
                                data-test-id="PhoneInputStyled"
                                searchNotFound="Country Not Found"
                                placeholder={"Enter your contact number here"}
                                value={contact}
                                onChange={(value, countryData) => {
                                    handleChangeChatBotField(value, "contact", "", countryData)
                                }}
                                onKeyDown={(event) => {
                                    if (event.key === 'Enter' && !showContactNumberErrorOne) {
                                        handleKeyDown("showjob_title", true)
                                        setTimeout(() => {
                                            document.getElementById("job_title")!.focus();
                                        });
                                    } 
                                }}
                            />
                            {showContactNumberErrorOne && <p 
                            id="error-id"
                            style={{
                                "fontWeight": "lighter",
                                "lineHeight": "18px",
                                "fontSize": "12px",
                                "color": "#DC2626",
                                "fontStyle": "normal",
                                "fontFamily": "Arial",
                                "display": "flex"
                                , marginTop: '6px'
                            }}>Please enter valid contact number</p>}
                        </Grid>
                    </>,
                        "Could you please provide your contact number?",
                        contact
                    )
                }
                {
                    component(jobTitleConditionOneX(), jobTitleConditionTwoX(), <>
                        <Grid item sm={12} style={{ padding: "14px 25px 0px 25px", display: "flex" }}>
                            <span style={{ display: "flex", alignItems: "center", textAlign: "start", background: "#F8FAFC", padding: "18px", borderRadius: "12px 12px 12px 0px", width: "auto" }}>
                                <span style={{  fontSize: '14px', fontWeight: "lighter", color: "#17365D", fontFamily: "Arial", }}>Perfect! What is your current job title or the title of the role you are seeking?</span>
                            </span>
                        </Grid>
                        <Grid item sm={12} style={{ padding: "14px 25px 0px 25px" }}>
                            <TextField
                                name="job_title"
                                fullWidth
                                id="job_title"
                                variant="outlined"
                                type='string'
                                onKeyDown={(event) => {
                                    if (event.key === 'Enter') {
                                        handleKeyDown("showexperience", true)
                                    } 
                                }}
                                InputLabelProps={{ shrink: true }}
                                InputProps={{ classes: { root: styles.root } }}
                                placeholder="Investment Professor"
                                onChange={(event) => handleChangeChatBotField(event.target.value, "job_title")}
                            />
                        </Grid>
                    </>,
                        "Perfect! What is your current job title or the title of the role you are seeking?",
                        job_title
                    )
                }
                {
                    component(experienceConditionOneX(), experienceConditionTwoX(), <>
                        <Grid item sm={12} style={{ padding: "14px 25px 0px 25px", display: "flex" }}>
                            <span style={{ display: "flex", alignItems: "center",  background: "#F8FAFC", padding: "18px", textAlign: "start", borderRadius: "12px 12px 12px 0px", width: "auto" }}>
                                <span style={{  fontSize: '14px', fontWeight: "lighter", color: "#17365D", fontFamily: "Arial", }}>Excellent choice! How much experience do you have?</span>
                            </span>
                        </Grid>
                        <Grid item sm={12} style={{ padding: "14px 25px 0px 25px", display: "flex", justifyContent: "start", flexWrap: "wrap" }}>
                            {
                                [
                                    { value: "4-6 Years", text: "4-6 Years" },
                                    { value: "2-4 Years", text: "2-4 Years" },
                                    { value: "0-2 Years", text: "0-2 Years" },
                                    { value: "8-10 Years", text: "8-10 Years" },
                                    { value: "6-8 Years", text: "6-8 Years" },
                                    { value: "10+ Years", text: "10+ Years" }
                                ].map(value => <Box id="experience-btn" className={experience === value.value ? styles.experienceBtnContainerSelected : styles.experienceBtnContainer} onClick={() => handleChangeChatBotField(value.value, "experience")}>
                                    <Typography>{value.value}</Typography>
                                </Box>)
                            }
                        </Grid>
                    </>,
                        "Excellent choice! How much experience do you have?",
                        experience
                    )
                }
                {
                    component(!!experience, !((current_salary !== "") && (showcurrent_company)), <>
                        <Grid item sm={12} style={{ padding: "14px 25px 0px 25px", display: "flex" }}>
                            <span style={{ display: "flex", alignItems: "center", textAlign: "start", background: "#F8FAFC", padding: "18px", borderRadius: "12px 12px 12px 0px", width: "auto" }}>
                                <span style={{ fontFamily: "Arial", fontSize: '14px', fontWeight: "lighter", color: "#17365D" }}>What's your current salary?</span>
                            </span>
                        </Grid>
                        <Grid item sm={12} style={{ padding: "14px 25px 0px 25px", display: "flex", justifyContent: "space-between", flexWrap: "wrap" }}>
                            <CurrenyFieldWithCurrency
                                name="currency"
                                value={current_salary}
                                placeHolder="25,00,000"
                                title=""
                                error=""
                                helperText=""
                                handleChange={(value: string) => handleChangeChatBotField(value, "current_salary")}
                                setCuurency={(value: string) => handleChangeChatBotField(value, "currency_value")}
                                currency_value={currency_value}
                                onKeyDown={(event) => {
                                    if (event.key === 'Enter') {
                                        handleKeyDown("showskills", true)
                                        setTimeout(() => {
                                            document.getElementById("combo-box-demo-skill")!.focus();
                                        }, 1000);
                                    }
                                }}
                            />
                        </Grid>
                    </>,
                        "What's your current salary?",
                        current_company
                    )
                }
                {
                    component((showskills && !!current_salary), !((skills.length > 0) && (showcurrent_company)), <>
                        <Grid item sm={12} style={{ padding: "14px 25px 0px 25px", display: "flex" }}>
                            <span style={{ display: "flex", alignItems: "center", textAlign: "start", background: "#F8FAFC", padding: "18px", borderRadius: "12px 12px 12px 0px", width: "auto" }}>
                                <span style={{ fontFamily: "Arial", fontSize: '14px', fontWeight: "lighter", color: "#17365D" }}>Great! List a few key skills or certifications relevant to your field. This will help match you with suitable job opportunities.</span>
                            </span>
                        </Grid>
                        <Grid item sm={12} style={{ padding: "14px 25px 0px 25px" }}>
                            <Autocomplete
                                id="combo-box-demo-skill"
                                options={skillsList}
                                getOptionLabel={(option) => option ? option : ""}
                                value={tempSkill}
                                onChange={(event, value) => {
                                    if (value) {
                                        handleChangeChatBotField(value, "skills")
                                    }
                                    setTimeout(() => document.getElementById("combo-box-demo-skill")?.blur())
                                }}
                                renderInput={(params) => <AutoCompleteInputText
                                    errors={{}}
                                    title=""
                                    name="city_field"
                                    placeHolder="Select skills"
                                    params={params}
                                    touched={false}
                                    borderRadius={"12px"}
                                />
                                }
                            />
                        </Grid>
                        <Grid item sm={12} style={{ padding: "14px 25px 0px 25px", display: "flex", justifyContent: "start", flexWrap: "wrap" }}>
                            {
                                skills.map((skill) => <Box className={styles.skillButton}>
                                    <Typography style={{ display: "flex", alignItems: "center" }}>
                                        {skill}
                                        &nbsp;
                                        <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg" id="skill-remove" onClick={() => handleChangeChatBotField(skill, "skills")}>
                                            <path d="M9.66683 1.27594L8.72683 0.335938L5.00016 4.0626L1.2735 0.335938L0.333496 1.27594L4.06016 5.0026L0.333496 8.72927L1.2735 9.66927L5.00016 5.9426L8.72683 9.66927L9.66683 8.72927L5.94016 5.0026L9.66683 1.27594Z" fill="white" />
                                        </svg>
                                    </Typography>
                                </Box>)
                            }
                        </Grid>
                    </>,
                        "Great! List a few key skills or certifications relevant to your field. This will help match you with suitable job opportunities.",
                        "",
                        skills
                    )
                }
                {
                    component(currentCompanyConditionOne(), currentCompanyConditiontwo(),
                        <>
                            <Grid item sm={12} style={{ padding: "14px 25px 0px 25px", display: "flex" }}>
                                <span style={{ display: "flex", alignItems: "center", textAlign: "start", background: "#F8FAFC", padding: "18px", borderRadius: "12px 12px 12px 0px", width: "auto" }}>
                                    <span style={{ fontFamily: "Arial", fontSize: '14px', fontWeight: "lighter", color: "#17365D" }}>Could you please tell us what is the current company?</span>
                                </span>
                            </Grid>
                            <Grid item sm={12} style={{ padding: "14px 25px 0px 25px" }}>
                                <Autocomplete
                                    id="combo-box-demo"
                                    options={companyList}
                                    getOptionLabel={(option) => option ? option : ""}
                                    value={current_company}
                                    onChange={(event, value) => {
                                        if (value) {
                                            handleChangeChatBotField(value, "current_company")
                                            handleKeyDown("showcurrent_company", true)
                                        }
                                    }}
                                    onKeyDown={(event) => {
                                        if (event.key === 'Enter') {
                                            handleKeyDown("showcurrent_company", true)
                                        }
                                    }}
                                    renderInput={(params) => <AutoCompleteInputText
                                        errors={{}}
                                        title=""
                                        name="city_field"
                                        placeHolder="Current company"
                                        params={params}
                                        touched={false}
                                        borderRadius={"12px"}
                                        onChange={(event) =>{
                                            handleChangeChatBotField(event.target.value, "current_company")
                                            companyListHandler(event)
                                        }}
                                    />
                                    }
                                />
                            </Grid>
                        </>,
                        "Could you please tell us what is the current company?",
                        current_company
                    )
                }
            </div>
            <div>
                <Divider style={{ width: "100%", height: "1px", background: "#E2E8F0", marginTop: "35px" }} />
                <Grid container sm={12} style={{ padding: "15px 25px", justifyContent: "end", display: "flex" }}>
                    <Button
                        id="target-button"
                        disabled={loaderButton || !showcurrent_company}
                        onClick={() => {
                            updateUserDetailsFromChat();
                        }}
                        style={{
                            color: "#FFF",
                            background: "#1F497D",
                            padding: "12px 28px",
                            borderRadius: "8px",
                            textTransform: "none",
                            opacity: !showcurrent_company ? 0.6 : 1,
                        }}>
                        Save
                    </Button>
                </Grid>
            </div>
        </div >
    )
}

const PhoneInputStyledOne = styled(PhoneInput)({
    '& .form-control': {
        "&:focus" : {
            border: "2px solid #9c9c9c"
        },
        "&:hover" :{
            border: "2px solid #9c9c9c"
        },
        "&::placeholder": {
            "color": "#64748B",
            "fontSize": "14px",
            "fontFamily": "Arial",
            "fontStyle": "normal",
            "fontWeight": "lighter",
            "lineHeight": "22px"
        },        
    },
    '& .flag-dropdown': {
        "&:focus" : {
            borderRight: "0px !important",
            border: "2px solid #9c9c9c",
        },
        background: "white",
        borderBottomLeftRadius: "8px !important",
        borderTopLeftRadius: "8px !important",
        "&:hover" : {
            borderRight: "0px !important",
            border: "2px solid #9c9c9c",
        },
        borderRight: "0px !important"
    },
    "& .open": {
        background: "white",
        borderBottomLeftRadius: "8px !important",
        borderTopLeftRadius: "8px !important",
    },
    '& .form-control:focus + div': {
        border: "2px solid #9c9c9c"
    },
    "& .selected-flag": {
        borderTopLeftRadius: "8px !important",
        background: "white",
        borderBottomLeftRadius: "8px !important",
    },
    '& .form-control:hover + div': {
        border: "2px solid #9c9c9c"
    },
});

export default ChatBotFormOne;