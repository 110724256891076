import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import { Typography } from "@material-ui/core";
import Select, { StylesConfig } from 'react-select';
import { debounce, getAllCountriesFromApi, getCitiesByName } from "./Utils.web";

type OptionType = {
    label: string;
    value: string;
};

const useStyles = makeStyles({
    root: {
        "&:hover": {
            backgroundColor: "transparent",
        },
    },
    icon: {
        borderRadius: "50%",
        width: 16,
        height: 16,
        boxShadow:
            "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
        backgroundColor: "white",
        backgroundImage:
            "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
        "$root.Mui-focusVisible &": {
            outline: "2px auto #64748B",
            outlineOffset: 2,
        },
        "input:hover ~ &": {
            backgroundColor: "#EEECE1",
        },
        "input:disabled ~ &": {
            boxShadow: "none",
            background: "rgba(206,217,224,.5)",
        },
    },
    checkedIcon: {
        backgroundColor: "#137cbd",
        backgroundImage: "linear-gradient(180deg,#1F497D,#1F497D)",
        "&:before": {
            display: "block",
            width: 16,
            height: 16,
            backgroundImage: "radial-gradient(#fff,#fff 28%,transparent 32%)",
            content: '""',
        },
        "input:hover ~ &": {
            backgroundColor: "#9C9C9C",
        },
    },
    headerTitle: {
        color: "#1F497D",
        fontFamily: "Arial",
        fontSize: "12px",
        fontStyle: "normal",
        fontWeight: "bold",
        lineHeight: "18px",
        marginBottom: "8px",
    },
    titleOfRadio: {
        fontFamily: "Arial",
        fontWeight: "lighter",
        color: "#1F497D",
        fontSize: "12px",
    },
    selectContainer: {
        position: 'relative',
    },
    selectField: {
        borderRadius: "8px",
        padding: "5px 10px",
        fontFamily: "Arial",
        fontSize: "12px",
        borderColor:'#EEECE1',
        color: "#EEECE1",
        "&:focus": {
            borderColor: "#EEECE1",
            boxShadow: "0 0 5px rgba(31, 73, 125, 0.5)",
        },
    },
    selectMenu: {
        position: 'absolute',
        zIndex: 9999,
        backgroundColor: "white",
        borderRadius: "8px",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
        marginTop: "2px",
    },
    placeholder: {
        color: "#1F497D",
        fontSize: "12px",
        fontWeight: "lighter",
    },
    select: {
        "& .react-select-2-input" :{
            "&::placeholder": {
                "color": "#64748B",
        "fontSize": "14px",
        "fontFamily": "Arial",
        "fontWeight": "lighter",
        "lineHeight": "22px",
        "fontStyle": "normal",
            }
        }
    }
});

const customStyles: StylesConfig<OptionType, false> = {
    control: (provided, state) => ({
        ...provided,
        borderRadius: '8px',
        width:'200px',
        height:'29px',
        minHeight:'none',
        justifyContent:'center',
        alignContent:'center',
        boxShadow: 'none',
        border: '1px solid #EEECE1',
     padding:'10px, 8px, 10px, 8px',
     '&:hover': {
      border: '1px solid rgba(156, 156, 156, 1)',
  
     }
      }),
      option: (provided, state) => ({
        color :'#1F497D',
        fontFamily:'Arial',
        fontWeight:400,
        fontSize:'12px',
        borderRadius: '8px',
        padding: "5px",
        '&:hover': {
            background: '#C7D9F0',
            borderRadius: "5px"
        }      

      }),
      singleValue: (provided) => ({
        ...provided,
        color: 'rgba(23, 54, 93, 1)',
        fontSize: '14px'
      }),
      valueContainer: (provided) => ({
        ...provided,
        padding: '0px 12px',
  
      }),
      indicatorsContainer: (provided) => ({
        ...provided,
        border: 'none',
        
      }),
      dropdownIndicator: (provided) => ({
        ...provided,
        padding: '8px'
      }),
      indicatorSeparator: () => ({
        display: 'none' 
      }),
      placeholder: (provided)=>({
        ...provided,
        "color": "#64748B",
        "fontSize": "14px",
        "fontFamily": "Arial",
        "fontWeight": "lighter",
        "lineHeight": "22px",
        "fontStyle": "normal",

      }),
      menu: (provided) => ({
        ...provided,
        position: 'absolute', 
        zIndex: 9999, 
        marginTop: 0, 
        borderRadius: '8px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        overflow: 'visible', 
    }),
    menuList: (provided) => ({
        ...provided,
        padding: 0,
        overflowY: 'visible', 
        overflowX: 'hidden',
    }),
      
};

type Props = {
    handleCountryChange: (value: string) => void,
    handleCityChange: (value: string) => void,
    selectedCity: string,
    selectedCountry: string,
    isoCode: string,
    setISOCode: (value: string) => void 
}

export default function CustomizedRadios(props: Props) {
    const classes = useStyles();
    const { handleCityChange, handleCountryChange, isoCode, setISOCode, selectedCity, selectedCountry } = props;
    const [countries, setCountries] = React.useState<{isoCode: number, name: string}[]>([]);
    const [cities, setCities] = React.useState<{value: string, label: string}[]>([]);

    
    React.useEffect(() => {
        let getData = async () => {
            let  data = await getAllCountriesFromApi();
            setCountries(data)
        };

        getData();
    }, [])

    const debouncedGetCities = React.useCallback(
        debounce(async (event) => {
          const data = await getCitiesByName(event);
          setCities(data);
        }, 1000),
        []
      );
    
      const handleInputChange = (event: string) => {
        if(!event) {
            return
        }
        const searchQuery = event;
        debouncedGetCities(searchQuery);
      };

    return (
        <FormControl component="fieldset">
            <label className={classes.headerTitle}>Location Preferences</label>
            <RadioGroup
                defaultValue="Country"
                aria-label="Country"
                name="customized-radios"
            >
                <FormControlLabel
                    value="Country"
                    control={<></>}
                    label={<Typography className={classes.titleOfRadio}>&nbsp;&nbsp;&nbsp;Country</Typography>}
                />
                <div className={classes.selectContainer} style={{paddingBottom: "10px"}}>
                    <Select
                    className={classes.select}
                        options={countries.map((country: {isoCode: number, name: string}) => ({
                            label: country.name,
                            value: country.name,
                            isoCode: country.isoCode
                        }))}
                        value={selectedCountry ? { value: selectedCountry, label: selectedCountry } : null}
                        styles={customStyles}
                        classNamePrefix="react-select"
                        placeholder="Select"
                        onChange={(selectedOption, value) => {
                            if (selectedOption && 'value' in selectedOption) {
                                if('isoCode' in selectedOption) {            
                                    setISOCode((selectedOption as {value: string, label: string, isoCode: string})!.isoCode)
                                }
                                handleCountryChange(selectedOption.value);
                            }
                        }}
                    />
                </div>
                {
                    <>
                        <FormControlLabel
                            value="City"
                            control={<></>}
                            label={<Typography className={classes.titleOfRadio}>&nbsp;&nbsp;&nbsp;City</Typography>}
                        />
                        <div className={classes.selectContainer}>
                            <Select
                                value={selectedCity ? { value: selectedCity, label: selectedCity } : null}
                                options={cities}
                                styles={customStyles}
                                classNamePrefix="react-select"
                                placeholder="Select"
                                onInputChange={handleInputChange}
                                onChange={(selectedOption) => {
                                    if (selectedOption && 'value' in selectedOption) {
                                        handleCityChange(selectedOption.value);
                                    }
                                }}
                            />
                        </div>
                    </>
                }
            </RadioGroup>
        </FormControl>
    );
}
