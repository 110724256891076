import { BlockComponent } from "../../../framework/src/BlockComponent";
// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
export const configJSON = require("./config.js");
import { Job } from "./JobListingController";
import { debounce } from "lodash";
import { getStorageData } from "../../../../packages/framework/src/Utilities";
import { handleCondition, isSubscribedToPlan } from "../../../../packages/components/src/Utils.web";
import { toast } from "react-toastify";

export interface DealExperienceAttributes {
    deal_type: string;
    transaction_side: string;
    involvement_type: string;
    buyer_investor_name: string;
    target_name: string;
    enterprise_value: string;
    transaction_summary: string;
    valuation_method: string;
    percentage_stake: string;
    debt_type: string;
    valuation_multiple: string;
    debt_size: string;
    borrower_name: string;
    lender_name: string;
    interest_rate: string;
    leverage_level: string;
    transaction_merits: string;
    main_contributions: string[];
    created_at: string;
    potential_risks: string;
}

export interface DealExperienceResponse {
    id: string;
    type: string;
    attributes: DealExperienceAttributes;
}

export interface AppliedJob {
    id: string;
    type: string;
    attributes: {
        contact: string;
        email: string;
        status: string;
        created_at: string;
        resume: {
            url: string;
            size: number;
            name: string;
        };
        cover_letter: {
            url: string;
            size: number;
            name: string;
        };
        job: {
            data: {
                id: string;
                type: string;
                attributes: DataAttributes;
            };
        };
        added_to_pipeline: boolean;
        answers: Answer[];
        pipeline_id: number | string;
        candidate_profile: {
            data: {
                id: string;
                type: string;
                attributes: {
                    total_potential_score: number;
                    id: number;
                    full_name: string;
                    location: string | null;
                    current_title: string;
                    full_phone_number: string;
                    email: string;
                    experience: string;
                    salary: string;
                    future_self: string;
                    current_company: string;
                    connection_request_details: {
                        id?: number,
                        sender_id?: number,
                        receiver_id?: number,
                        status?: string | "accepted" | "pending",
                    } | null,
                    company_indusry: string;
                    bio: string;
                    work_email_id: string | null;
                    personal_email_id: string | null;
                    city: string;
                    country: string;
                    skills: string[];
                    selected_group_context: string;
                    open_for_direct_approach: boolean | null;
                    potential_score: number | null;
                    relevance_score: number | null;
                    overall_rank: number | null;
                    photo: string;
                    account_details: {
                        id: number;
                        full_name: string | null;
                        email: string;
                        activated: boolean;
                        full_phone_number: string;
                        type: string;
                        role: string;
                    };
                    work_experiences: {
                        data: WorkExperience[];
                    };
                    deal_experiences: {
                        data: DealExperienceResponse[];
                    };
                    resume: {
                        url: string;
                        size: number;
                        name: string;
                    } | null;
                    cover_letter: {
                        url: string;
                        size: number;
                        name: string;
                    } | null;
                };
            };
        };
    };
}

export interface QuestionFormate {
    question_text: string,
    question_type: string,
    options: Option[],
    required: boolean,
    id: string
}

type Question = {
    id: number;
    question_type: "Short Answer" | "Multiple Choice" | "Dropdown";
    job_id: number;
    question_text: string;
    answer_text: string | null;
    options: string[];
    created_at: string;
    required: boolean;
    drop_downs: string[];
    updated_at: string;
};

type Answer = {
    id: number;
    question: Question;
    answer: string;
};


export type Option = { title: string, id: string }
interface JobDetailsType {
    id: string;
    type: string;
    attributes: Attributes;
}

interface DataAttributes extends CommonAttributes {
    employment_type_id: string | null;
    total_interview_rounds: number | null;
    draft_page: number;
    created_at: string;
    questions: {
        id: number;
        job_id: string;
        question_type: string;
        question_text: string;
        options: string[];
        answer_text: string | null;
        drop_downs: string[];
    }[];
}

export type WorkExperience = {
    id: string;
    type: string;
    attributes: {
        position: string;
        specialization: string;
        started_time: string;
        ended_time: string;
        company_name: string;
        currently_working: boolean;
        end_current_position: boolean;
        created_at: string;
        company_icon_url: string
    };
};

export type JobStatusCount = {
    invited: number;
    rejected: number;
    applied: number;
    'applied+':number;
    review: number;
    interviewing: number;
    messaged: number
}

interface CommonAttributes {
    job_title: string;
    job_description: string;
    remote_job: boolean;
    company_name: string;
    requirements: string | null;
    salary: string | null;
    experience_required: string;
    skills_required: string[];
    application_deadline: string | null;
    company_benefits: string[];
    responsibilities: string[];
    job_categories: string;
    country: string;
    city: string;
    post_expire_after: string;
    salary_from: string;
    salary_to: string;
    disclose: boolean;
    education: string;
    industry_type: string;
    department: string;
    status: string;
}
export interface TalentPoolCandidate {
    id: string;
    type: string;
    attributes: {
        id: number;
        created_at: string;
        recruiter_detail: {
            data: {
                id: string;
                type: string;
                attributes: {
                    id: number;
                    full_name: string;
                    location: string | null;
                    current_title: string | null;
                    full_phone_number: string | null;
                    email: string;
                    experience: string | null;
                    salary: string | null;
                    future_self: string | null;
                    current_company: string | null;
                    company_industry: string | null;
                    bio: string | null;
                    work_email_id: string | null;
                    personal_email_id: string | null;
                    city: string | null;
                    country: string | null;
                    skills: string[];
                    selected_group_context: string | null;
                    open_for_direct_approach: boolean | null;
                    potential_score: number | null;
                    relevance_score: number | null;
                    overall_rank: number | null;
                    company_name: string | null;
                    website: string | null;
                    company_size: string | null;
                    photo: string | null;
                    account_details: {
                        id: number;
                        full_name: string | null;
                        email: string;
                        activated: boolean;
                        full_phone_number: string;
                        type: string;
                        role: string;
                    };
                    work_experiences: {
                        data:[];
                    };
                    resume: string | null;
                    cover_letter: string | null;
                };
            };
        };
        candidate_detail: {
            data: {
                id: string;
                type: string;
                attributes: {
                    id: number;
                    total_potential_score: number;
                    full_name: string;
                    location: string | null;
                    current_title: string | null;
                    full_phone_number: string;
                    email: string;
                    experience: string;
                    salary: string;
                    future_self: string;
                    current_company: string;
                    company_industry: string;
                    bio: string;
                    work_email_id: string | null;
                    personal_email_id: string | null;
                    city: string;
                    country: string;
                    skills: string[];
                    selected_group_context: string;
                    open_for_direct_approach: boolean | null;
                    potential_score: number | null;
                    relevance_score: number | null;
                    overall_rank: number | null;
                    company_name: string | null;
                    website: string | null;
                    company_size: string | null;
                    photo: string;
                    account_details: {
                        id: number;
                        full_name: string | null;
                        email: string;
                        activated: boolean;
                        full_phone_number: string;
                        type: string;
                        role: string;
                    };
                    work_experiences: {
                        data: [];
                    };
                    resume: string | null;
                    cover_letter: string | null;
                    applied_job_details: {
                        applied_time_ago: string
                    }
                };
            };
        };
    };
};


interface Attributes extends CommonAttributes {
    employment_type_id: string | null;
    total_interview_rounds: number | null;
    draft_page: string;
    candidatesCount: number;
    questions: QuestionFormate[];
}
// Customizable Area End

export interface Props {
    navigation: any;
    // Customizable Area Start
    jobId: number | string;
    handleJobDetailListingClose: () => void;
    handleViewClick: (id: number | string) => void;
    handleEditClick: (id: number | string) => void;
    handleDelete: (id: number | string) => void;
    allCreatedJobs: Job[];
    refreshPage: boolean;
    setRefreshPage: (value: boolean) => void;
    jobDetailsFromProps: Job;
    countries: { value: string; label: string, isoCode: number }[];
    cities: { value: string, label: string }[];
    handleCountryChange: (value: { value: string; label: string; }[]) => void;
    // Customizable Area End
}

interface State {
    // Customizable Area Start
    currentTab: string;
    currentJobtab: string;
    sortBy: string;
    selectedJobs: (string | number)[];
    jobs: AppliedJob[],
    filterModal: boolean;
    jobsPage: number;
    currentPage: number;
    totalApplication: number;
    jobDetails: JobDetailsType;
    selectedJobApplicantDetails: AppliedJob;
    job_counts_as_per_statuses: JobStatusCount;
    currenrPageOtherJob: number;
    allCreatedJobs: Job[];
    totalPage: number;
    tempCountry: { label: string, value: string, cId?: string };
    tempCity: { label: string, value: string };
    tempSkill: { label: string, value: string };
    selectedCountry: string;
    selectedCity: string;
    selectedSkills: string[];
    selectedExperience: string[];
    skillList: { label: string, value: string }[];
    filteredSlillsOption: { label: string, value: string }[];
    talentPoolCandidates: TalentPoolCandidate[],
    job_applicants_count: number,
    showSubscriptionDialog: boolean,
    applicantLimit: number,
    blurredIds: string[],
    selectedStatus: string
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    // Customizable Area End
}


export default class JobDetailsListingController extends BlockComponent<Props, State, SS> {

    // Customizable Area Start
    getAllJobDetailsApiId: Message | string = "";
    getJobDetailsApiId: Message | string = "";
    observer: IntersectionObserver | null = null;
    observerOne: IntersectionObserver | null = null;
    getJobApplicantDetailsApiId: Message | string = "";
    updateStatusofapplication: Message | string = "";
    getAllCreatedJobsApiId: Message | string = "";
    skillsListApiId: Message | string = "";
    getTalentPoolcandidatesApiID: Message | string = "";
    addCandidateIntotalentPool: Message | string = "";
    deleteCandidateFromTalentPoolApiID: Message | string = "";
    getSubscriptionApiCallId: Message | string = "";
    jobStatusChangeApiId: string | Message = "";
    otherJobStatusChangeApiId: string = ""
    // Customizable Area End
    constructor(props: Props) {
        super(props);
        this.state = {
            // Customizable Area Start
            currentTab: "All",
            sortBy: "Newest",
            selectedJobs: [],
            currentJobtab: "Application",
            jobs: [],
            filterModal: false,
            jobsPage: 1,
            currentPage: 1,
            totalApplication: 0,
            jobDetails: {
                id: "", type: "", attributes: {
                    job_title: "",
                    status: "open",
                    department: ""
                }
            } as JobDetailsType,
            selectedJobApplicantDetails: {} as AppliedJob,
            job_counts_as_per_statuses: {
                applied: 0,
                interviewing: 0,
                invited: 0,
                messaged: 0,
                rejected: 0,
                review: 0,
                "applied+": 0,
            },
            currenrPageOtherJob: 1,
            allCreatedJobs: [] as Job[],
            totalPage: 0,
            tempCountry: { label: "", value: "" },
            tempCity: { label: "", value: "" },
            tempSkill: { label: "", value: "" },
            selectedCountry: "",
            selectedCity: "",
            selectedSkills: [],
            selectedExperience: [],
            skillList: [],
            filteredSlillsOption: [],
            talentPoolCandidates: [],
            job_applicants_count: 0,
            showSubscriptionDialog: false,
            applicantLimit: configJSON.freePlanLimit,
            blurredIds: [],
            selectedStatus: ""
            // Customizable Area End
        };

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionRequestMessage),
            getName(MessageEnum.SessionResponseMessage)
        ];
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area End
    }

    // Customizable Area Start

    async componentDidMount(): Promise<void> {
        this.checkForSubscription()
        this.getJobdetailsById(this.props.jobId);
        this.getAllJobsForDetails();
        this.getAllJobs();
        this.createObserver();
        this.createObserverOne();
        this.getSkillsData();
        this.getAllCandidatesTalenPool();
        window.addEventListener("POST_UPDATE", this.editPostHandler as EventListener);
    }

    async componentWillUnmount() {
        if (this.observer) {
            this.observer.disconnect();
        }
        if (this.observerOne) {
            this.observerOne.disconnect();
        }
    }

    editPostHandler = () => {
        this.getJobdetailsById(this.props.jobId);
    }

    async componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: SS | undefined) {
        if ((prevState.sortBy !== this.state.sortBy) || ((prevState.currentTab !== this.state.currentTab))) {
            this.getAllJobsForDetails(1)
        }

        if (prevState.currentPage !== this.state.currentPage) {
            this.getAllJobsForDetails();
        }
        if (this.observer) {
            const elements = document.querySelectorAll('.list-item-0705');
            if (elements.length > 0) {
                const lastElement = elements[elements.length - 1];
                this.observer.observe(lastElement);
            }
        }

        if (this.observerOne) {
            const elements = document.querySelectorAll('.list-item-569');
            if (elements.length > 0) {
                const lastElement = elements[elements.length - 1];
                this.observerOne.observe(lastElement);
            }
        }
        if (prevState.jobs !== this.state.jobs) {
            this.setState({
                totalApplication: this.state.jobs.length
            })
        }
        if (prevState.currenrPageOtherJob !== this.state.currenrPageOtherJob && this.state.currenrPageOtherJob <= this.state.totalPage) {
            this.getAllJobs(this.state.currenrPageOtherJob);
        }

        if (this.props.refreshPage) {
            this.props.setRefreshPage(false);
            this.getAllJobs(1);
            this.setState({
                currenrPageOtherJob: 1
            })
        }
    }

    createObserver() {
        this.observer = new IntersectionObserver(entries => {
            const entry = entries[0];
            if (entry.isIntersecting) {
                this.handleScroll();
            }
        }, {
            root: null,
            rootMargin: '0px',
            threshold: 1.0
        });
    }

    handleScroll() {
        this.setState((prevState) => ({
            currentPage: prevState.currentPage + 1
        }))
    }

    createObserverOne() {
        this.observerOne = new IntersectionObserver(entries => {
            const entry = entries[0];
            if (entry.isIntersecting) {
                this.handleScrollOne();
            }
        }, {
            root: null,
            rootMargin: '0px',
            threshold: 1.0
        });
    }

    handleScrollOne() {
        this.setState((prevState) => ({
            currenrPageOtherJob: prevState.currenrPageOtherJob + 1
        }))
    }

    onChangeFilterModalsValue = (value: { value: string, label: string }, filterName: "Country" | "City" | "Skills" | "Experience" | "removeSkills", remove: boolean = false) => {
        switch (filterName) {
            case "Country":
                this.setState({
                    tempCountry: { value: "", label: "" },
                    selectedCountry: remove ? "" : value.value
                })
                break;

            case "City":
                this.setState({
                    tempCity: { value: "", label: "" },
                    selectedCity: remove ? "" : value.value
                })
                break;

            case "Skills":
                this.setState({
                    tempSkill: { value: "", label: "" },
                    selectedSkills: [...new Set([...this.state.selectedSkills, value.value])]
                })
                break;

            case "removeSkills":
                this.setState({
                    tempSkill: { value: "", label: "" },
                    selectedSkills: this.state.selectedSkills.filter((item) => item !== value.value)
                })
                break;

            case "Experience":
                this.setState({
                    selectedExperience: this.state.selectedExperience.includes(value.value) ? this.state.selectedExperience.filter((item) => item !== value.value) : [...this.state.selectedExperience, value.value]
                })
                break;
        }
    };

    skillsListHandler = debounce((event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            filteredSlillsOption: this.state.skillList.filter((skill) => skill.value.toLocaleLowerCase().includes(event.target.value))
        })
    }, 1000);

    handleClearFiler = () => {
        this.setState({
            selectedCity: "",
            selectedCountry: "",
            selectedExperience: [],
            selectedSkills: []
        })
    }

    getAllJobs = async (page?: number) => {
        let token = await getStorageData("authToken");

        const header = {
            'Content-Type': configJSON.ApiContentType,
            token: token
        };
        const getAllJobs = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getAllCreatedJobsApiId = getAllJobs.messageId;
        getAllJobs.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.getAllCreatedJobs}?page=${page ?? this.state.currenrPageOtherJob}`
        );

        getAllJobs.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        getAllJobs.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.httpGetMethod
        );

        runEngine.sendMessage(getAllJobs.id, getAllJobs);
    };

    handeleTalentpoolAdd = (canID: number | string, deletable: boolean) => {
        if(!this.isAccessible()) { return }
        if (deletable) {
            this.deleteCandidateFromTalentpool(canID)
        } else {
            this.handleAddCandidateIntoTalentPool(canID);
        }
    }

    deleteCandidateFromTalentpool = async (cadID: number | string) => {
        let token = await getStorageData("authToken");

        const header = {
            'Content-Type': configJSON.ApiContentType,
            token: token
        };
        const deleteCandidateFromTalentPool = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.deleteCandidateFromTalentPoolApiID = deleteCandidateFromTalentPool.messageId;
        deleteCandidateFromTalentPool.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.removeCandidateFromTalentPool}${cadID}`
        );

        deleteCandidateFromTalentPool.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        deleteCandidateFromTalentPool.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.httpDeleteMethod
        );

        runEngine.sendMessage(deleteCandidateFromTalentPool.id, deleteCandidateFromTalentPool);
    };

    getAllCandidatesTalenPool = async () => {
        const headers = {
            "Content-Type": configJSON.getcompanyApiContentType,
            "token": await getStorageData("authToken")
        };

        let URL = configJSON.getTalentPoolCandidates


        if (this.state.selectedCountry.trim()) {
            URL += `&country=${this.state.selectedCountry.toLowerCase()}`
        }

        if (this.state.selectedCity.trim()) {
            URL += `&city=${this.state.selectedCity.toLowerCase()}`
        }

        if (this.state.selectedSkills.length > 0) {
            for (let skill of this.state.selectedSkills) {
                URL += `&skills[]=${skill.toLowerCase()}`
            }
        }

        if (this.state.selectedCountry) {
            for (let experience of this.state.selectedCity) {
                URL += `&experience[]=${experience.toLowerCase()}`
            }
        }

        const getTalentPoolCandidates = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getTalentPoolcandidatesApiID = getTalentPoolCandidates.messageId;

        getTalentPoolCandidates.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            URL
        );

        getTalentPoolCandidates.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );

        getTalentPoolCandidates.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.httpGetMethod
        );
        runEngine.sendMessage(getTalentPoolCandidates.id, getTalentPoolCandidates);
    }

    handleSelectApplication = (jobId: number | string, event: React.ChangeEvent<HTMLInputElement>) => {
        event.stopPropagation()
        if (this.state.selectedJobs.find(item => item === jobId)) {
            this.setState({
                selectedJobs: this.state.selectedJobs.filter(item => item !== jobId)
            })
        } else {
            this.setState({
                selectedJobs: [...this.state.selectedJobs, jobId]
            })
        }
    }

    handleSelectAll = () => {
        this.setState({
            selectedJobs: (this.state.selectedJobs.length > 0 && (this.state.selectedJobs.length === this.state.totalApplication)) ? [] : this.state.jobs.map(item => item.id)
        })
    }

    convertToLowerCaseAndReplaceSpaces = (input: string): string => {
        const lowerCaseString = input.toLowerCase();
        const replacedString = lowerCaseString.replace(/\s+/g, '_');
        return replacedString;
    }

    handleTwoCondition = (condition: string | boolean, truePart: JSX.Element) =>{
        return condition && truePart
    }

    handleStatusUpdate = async (status: string) => {
        const headers = {
            "token": await getStorageData("authToken")
        };

        let formData = new FormData();
        formData.append("job_applicant_ids", "[" + this.state.selectedJobs.join(", ") + "]");
        formData.append("status", status.toLowerCase());


        const updateUserDetails = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.updateStatusofapplication = updateUserDetails.messageId;

        updateUserDetails.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.jobStatusUpdate}`
        );

        updateUserDetails.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );

        updateUserDetails.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            formData
        );

        updateUserDetails.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.httpPutMethod
        );
        runEngine.sendMessage(updateUserDetails.id, updateUserDetails);
    }

    handleAddCandidateIntoTalentPool = async (cadID: string | number) => {
        const headers = {
            "token": await getStorageData("authToken")
        };

        let formData = new FormData();
        formData.append("candidate_id", `${cadID}`);

        const addCandidateinTalentPool = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.addCandidateIntotalentPool = addCandidateinTalentPool.messageId;

        addCandidateinTalentPool.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.addCandidateIntoTalentPool}`
        );

        addCandidateinTalentPool.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );

        addCandidateinTalentPool.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            formData
        );

        addCandidateinTalentPool.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.exampleAPiMethod
        );
        runEngine.sendMessage(addCandidateinTalentPool.id, addCandidateinTalentPool);
    }

    handleSingleStatusUpdate = async (status: string, job: string[], event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        this.setState({
            selectedStatus: status
        })
       
        if (
            status === "messaged" &&
            (!this.state.selectedJobApplicantDetails.attributes.candidate_profile.data.attributes.connection_request_details || this.state.selectedJobApplicantDetails.attributes.candidate_profile.data.attributes.connection_request_details.status !== "accepted") &&
            !this.state.selectedJobApplicantDetails.attributes.candidate_profile.data.attributes.open_for_direct_approach
        ) {
            toast.error("You can't send message outside of your network")
            return;
        }

        const headers = {
            "token": await getStorageData("authToken")
        };

        let formData = new FormData();
        formData.append("job_applicant_ids", "[" + job.join(", ") + "]");
        formData.append("status", status.toLowerCase());


        const updateUserDetailsSingleUser = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.updateStatusofapplication = updateUserDetailsSingleUser.messageId;

        updateUserDetailsSingleUser.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.jobStatusUpdate}`
        );

        updateUserDetailsSingleUser.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );

        updateUserDetailsSingleUser.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            formData
        );

        updateUserDetailsSingleUser.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.httpPutMethod
        );
        runEngine.sendMessage(updateUserDetailsSingleUser.id, updateUserDetailsSingleUser);
        event.stopPropagation()
    }

    getAllJobsForDetails = async (page?: number) => {
        const headers = {
            "Content-Type": configJSON.validationApiContentType,
            "token": await getStorageData("authToken")
        };

        let URL = `/bx_block_joblisting/applied_jobs/${this.props.jobId}/all_job_applicants?page=${page ? page : this.state.currentPage}&per_page=15&sort_by=${this.convertToLowerCaseAndReplaceSpaces(this.state.sortBy)}`;
        if (this.state.currentTab !== "All" && this.state.currentTab !== "TalentPool") {
            let tempParam = encodeURIComponent(this.state.currentTab.toLocaleLowerCase())
            URL += `&status=${tempParam}`
        }

        if (this.state.selectedCountry.trim()) {
            URL += `&country=${this.state.selectedCountry}`
        }

        if (this.state.selectedCity.trim()) {
            URL += `&city=${this.state.selectedCity}`
        }

        if (this.state.selectedSkills.length > 0) {
            for (let skill of this.state.selectedSkills) {
                URL += `&skills[]=${skill}`
            }
        }

        if (this.state.selectedExperience.length > 0) {
            for (let experience of this.state.selectedExperience) {
                URL += `&experience[]=${experience}`
            }
        }
        if (page === 1) {
            this.setState({
                currentPage: 1
            })
        }
        const getJobDetails = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getAllJobDetailsApiId = getJobDetails.messageId;

        getJobDetails.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            URL
        );

        getJobDetails.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );

        getJobDetails.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.httpGetMethod
        );
        runEngine.sendMessage(getJobDetails.id, getJobDetails);
    }

    getSkillsData = async () => {
        const headers = {
            "Content-Type": configJSON.getcompanyApiContentType,
            "token": await getStorageData("authToken")
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.skillsListApiId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.skillsListingApi}`
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.httpGetMethod
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    getJobdetailsById = async (id: string | number) => {
        let token = await getStorageData("authToken");

        const header = {
            'Content-Type': configJSON.ApiContentType,
            token: token
        };
        const getJobDetailsByID = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getJobDetailsApiId = getJobDetailsByID.messageId;
        getJobDetailsByID.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.createJobApiEndPoint}/${id}`
        );

        getJobDetailsByID.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        getJobDetailsByID.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.httpGetMethod
        );

        runEngine.sendMessage(getJobDetailsByID.id, getJobDetailsByID);
        return true;
    };

    getStatus = (value: string): "approve" | "decline" | "open" | "close" | "applied" | "draft" | "applied2" | "invited" | "interviewing" | "rejected" | "review" => {
        switch (value) {
            case "applied":
                return "applied2";

            case "rejected":
                return "rejected";

            case "review":
                return "review";

            case "messaged":
                return "invited";

            case "interviewing":
                return "interviewing";

            default:
                return "applied2";

        }
    }

    renderAppliedTime = (candidateDetails: {applied_job_details: {applied_time_ago: string}}) => {
        if(candidateDetails.applied_job_details) { return candidateDetails.applied_job_details.applied_time_ago } 
        else { return "" }
    }

    handleChangeTab = (_event: React.ChangeEvent<{}>, value: string) => {
        this.setState({
            currentTab: value
        })
    }

    handleTalentPool = (tabValue: string) => {
        if(this.state.applicantLimit <= configJSON.freePlanLimit) { this.setState({ showSubscriptionDialog: true }) } 
        else {

            this.setState({
                currentTab: tabValue,
            });
            this.handleClearFiler();
        }
    }

    handleApplicationChangeTab = (_event: React.ChangeEvent<{}>, value: string) => {
        this.setState({
            currentJobtab: value
        })
    }

    handleFilterModal = (): void => {
        this.setState({
            filterModal: !this.state.filterModal
        })
    }

    isAccessible = () => {
        if(this.state.applicantLimit <= configJSON.freePlanLimit) {
            this.setState({
                showSubscriptionDialog: true
            })
            return false
        }
        return true
    }

    handleSortChange = (value: string) => {
        if(!this.isAccessible()) {
            return
        }
        this.setState({
            sortBy: value
        })
    }


    handleSelectionChange = (value: string) => {
        this.handleStatusUpdate(value)
    }

    a11yProps = (index: string) => {
        return {
            "id": `scrollable-auto-tab-${index}`,
            "aria-controls": `scrollable-auto-tabpanel-${index}`,
        };
    }

    setCurrentSelectedApplicant = (applicant: AppliedJob, event: React.MouseEvent<HTMLDivElement, MouseEvent>, index: number) => {
        event.stopPropagation()
        if(!this.checkForBlur(applicant.id)) {
            this.setState({
                selectedJobApplicantDetails: applicant
            });
        } else {
            this.setState({
                showSubscriptionDialog: true
            })
        }
    }

    closeSubscriptionDialog = () => {
        this.setState({
            showSubscriptionDialog: false
        })
    }

    navigationToSubscription = () => {
        const message = new Message(getName(MessageEnum.NavigationMessage));
        message.addData(getName(MessageEnum.NavigationTargetMessage), "Customisableusersubscriptions");
        message.addData(getName(MessageEnum.NavigationPropsMessage),this.props);
        this.send(message);
    }

    handleSubscriptionResponse = (responseJson: { user_subscription: { data: { attributes: { status: string, subscription_details: { name: string, chat_limits: string } } } } }) => {
        if (responseJson.user_subscription) {
            let isFreePlan = responseJson.user_subscription.data.attributes.subscription_details.name.toLowerCase().includes("free");
            let applicantLimitForFreePlan = isFreePlan ? configJSON.freePlanLimit : configJSON.maxLimit
            let isSubscribedUser = isSubscribedToPlan(responseJson.user_subscription.data.attributes.status)

            this.setState({
                applicantLimit: handleCondition<number>(
                    isSubscribedUser,
                    applicantLimitForFreePlan,
                    0
                )
            })
        }
    }

    checkForBlur = (applicantId: string) => {
        return this.state.blurredIds.includes(applicantId)
    }

    async receive(from: string, message: Message) {

        const webApiRequestCallId = message.getData(
            getName(MessageEnum.RestAPIResponceDataMessage)
        );

        const responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );

        if (!responseJson) return;

        switch (webApiRequestCallId) {
            case this.getAllJobDetailsApiId:
                this.handleAllJobDetailsResponse(responseJson);
                break;
            case this.getJobDetailsApiId:
                this.handleJobDetailsResponse(responseJson);
                break;
            case this.updateStatusofapplication:
                this.handleUpdateStatusResponse();
                break;
            case this.getAllCreatedJobsApiId:
                this.handleAllCreatedJobsResponse(responseJson);
                break;
            case this.skillsListApiId:
                this.setState({
                    skillList: responseJson.skills.map((skill: string) => ({ label: skill, value: skill }))
                })
                break;
            case this.getTalentPoolcandidatesApiID:
                if (responseJson.pipeline_candidates) {
                    this.setState({
                        talentPoolCandidates: responseJson.pipeline_candidates.data
                    })
                }
                break;
            case this.addCandidateIntotalentPool:
                toast.success("Candidate added to talent pipeline successfully");
                this.getAllJobsForDetails(1);
                this.getAllCandidatesTalenPool();
                this.setState({ selectedJobs: [] });
                break;
            case this.deleteCandidateFromTalentPoolApiID:
                toast.success("Candidate removed from talent pipeline successfully");
                this.getAllJobsForDetails(1);
                this.setState({ selectedJobs: [] });
                this.getAllCandidatesTalenPool();
                break;
            case this.getSubscriptionApiCallId: 
                this.handleSubscriptionResponse(responseJson)
                break;
            case this.jobStatusChangeApiId:
                this.handleJobStatusChangeApiResponse(responseJson);
                break;
            case this.otherJobStatusChangeApiId:
                this.handleOtherJobStatusChangeApiResponse(responseJson);
                break;    
                default:
                break;
        }
    }

    handleJobStatusChangeApiResponse = (responseJson: {job: {data: JobDetailsType}}) => {
        this.setState({
            jobDetails: responseJson.job.data
        })
    };

    handleOtherJobStatusChangeApiResponse = (responseJson: {job: {data: Job}}) => {
        this.setState({
            allCreatedJobs: this.state.allCreatedJobs.map((job) => {return job.id === responseJson.job.data.id ? {...job, attributes: {...job.attributes, status: responseJson.job.data.attributes.status}} : job} )
        })
    };

    private handleAllJobDetailsResponse(responseJson: { job_applicants: { data: AppliedJob[] }, job_counts_as_per_statuses: JobStatusCount , job_applicants_count: number}) {
        const { job_applicants, job_counts_as_per_statuses, job_applicants_count } = responseJson;
        if (!job_applicants) return;
        const newData = job_applicants.data || [];
        let blurredIds = [...this.state.blurredIds]

        if(this.state.currentTab === "All") {
            newData.forEach((applicant, index) => {
            if((index + 1) > this.state.applicantLimit) {
                blurredIds = [...this.state.blurredIds, applicant.id]
                this.setState({
                    blurredIds: [...blurredIds]
                })
            }});
        }
        const newSelectedJobApplicantDetails = newData[0] && !blurredIds.includes(newData[0].id) ? newData[0] : {} as AppliedJob;

        this.setState(prevState => ({
            jobs: prevState.currentPage === 1 ? newData : [...prevState.jobs, ...newData],
            selectedJobApplicantDetails: prevState.currentPage === 1 ? newSelectedJobApplicantDetails : prevState.selectedJobApplicantDetails,
            job_counts_as_per_statuses,
            job_applicants_count: Object.values(job_counts_as_per_statuses).reduce((total, num) => total + num, 0)
        }));
    }

    private handleJobDetailsResponse(responseJson: { data: JobDetailsType }) {
        const jobDetails = responseJson.data;
        this.setState({ jobDetails });
    }

    private handleUpdateStatusResponse() {
        this.getAllJobsForDetails(1);
        if(this.state.selectedStatus === "messaged") {
            this.goToMessage()
        }
        this.setState({ selectedJobs: [], selectedStatus: "" });
    }

    goToMessage = () => {
        const reqMessage: Message = new Message(getName(MessageEnum.NavigationMessage));
        reqMessage.addData(getName(MessageEnum.NavigationTargetMessage), 'Messaging');
        reqMessage.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        const raiseMessage: Message = new Message(getName(MessageEnum.NavigationPayLoadMessage));
        raiseMessage.addData(getName(MessageEnum.SessionResponseData),
            { from: "Profile", accountId: { data: this.state.selectedJobApplicantDetails.attributes.candidate_profile.data } }
        )
        reqMessage.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
        this.send(reqMessage);
    }

    private handleAllCreatedJobsResponse(responseJson: { total_page: number, recruiter_job_listing: { data: Job[] } }) {
        const { total_page, recruiter_job_listing } = responseJson;
        if (!recruiter_job_listing) return;

        const newData = recruiter_job_listing.data || [];
        this.setState(prevState => ({
            totalPage: total_page,
            allCreatedJobs: prevState.currentPage === 1 ? newData : [...prevState.allCreatedJobs, ...newData] as Job[]
        }));
    }


      checkForSubscription = async () => {
        const token = await getStorageData("authToken")
    
        const headers = {
          "Content-Type": configJSON.validationApiContentType,
          token,
        };
    
        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
    
        this.getSubscriptionApiCallId = requestMessage.messageId;
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          `${configJSON.checkForSubscriptionAPI}`
        );
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(headers)
        );
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.validationApiMethodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
      }

      handleChangeJobStatus = async (jobId: string | number, status: string) => {
        const headers = { 
          "token": await getStorageData("authToken")
        };
    
        const updateApplicationStatus = new Message(getName(MessageEnum.RestAPIRequestMessage));
        if(jobId === this.state.jobDetails.id) {
            this.jobStatusChangeApiId = updateApplicationStatus.messageId;
        } else {
            this.otherJobStatusChangeApiId = updateApplicationStatus.messageId;
        }
    
        updateApplicationStatus.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          `bx_block_joblisting/jobs/${jobId}/manage_job_status?status=${status}`
        );
    
        updateApplicationStatus.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(headers)
        );
    
        updateApplicationStatus.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          "PUT"
        );
    
        runEngine.sendMessage(updateApplicationStatus.id, updateApplicationStatus);
      }
      
    // Customizable Area End
}
