import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { debounce } from 'lodash';
import { getStorageData } from "../../../../packages/framework/src/Utilities";
import { getAllCountriesFromApi, getCitiesByName, getIdFromParams, getLocation, isAnyFilterSelected, isSubscribedToPlan, META_TAGS } from "../../../components/src/Utils.web";

// Customizable Area Start
import axios, { AxiosProgressEvent, AxiosRequestConfig } from 'axios';
import { toast } from "react-toastify";
import { toast as hotToast } from "react-hot-toast";
import CustomisableSubscriptionAdapter from "../../adapters/src/CustomisableUserSubscriptionAdapter";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: {
    getParam: (value: string) => string
  };
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export interface Job {
  id: string;
  type: string;
  attributes: {
    job_title: string;
    company_name: string;
    city: string;
    country: string;
    disclose: boolean;
    status: string;
    created_at: string;
  };
}

export interface UserDetailsProfile {
  id: string;
  type: string;
  attributes: {
    id: number;
    full_name: string;
    location: string;
    current_title: string;
    full_phone_number: string;
    email: string;
    experience: string;
    salary: string;
    future_self: string;
    current_company: string;
    company_indusry: string;
    bio: string;
    work_email_id: string | null;
    personal_email_id: string | null;
    city: string;
    country: string;
    skills: string[][];
    selected_group_context: string;
    open_for_direct_approach: boolean | null;
    potential_score: number | null;
    relevance_score: number | null;
    overall_rank: number | null;
    company_name: string | null;
    website: string | null;
    company_size: string | null;
    added_to_pipeline: boolean;
    pipeline_id: string | null;
    photo: string;
    account_details: {
      id: number;
      email: string;
      activated: boolean;
      full_phone_number: string;
      type: string;
      role: string;
    };
    work_experiences: {
      data: []
    };
    resume: {
      url: string;
      size: number;
      name: string;
    };
    cover_letter: {
      url: string;
      size: number;
      name: string;
    };
  };
}


export interface JobDetails {
  id: string | number;
  type: string;
  attributes: {
    job_title: string;
    currency: string;
    job_description: string;
    remote_job: boolean;
    location: string;
    employment_type_id: string | null;
    total_interview_rounds: number | null;
    company_name: string;
    requirements: string | null;
    salary: string | null;
    experience_required: string;
    skills_required: string[];
    application_deadline: string | null;
    company_benefits: string[];
    responsibilities: string[];
    job_categories: string;
    country: string;
    city: string;
    post_expire_after: string;
    salary_from: string;
    salary_to: string;
    disclose: boolean;
    education: string;
    industry_type: string;
    department: string;
    status: string;
    draft_page: string | null;
    created_at: string;
    created_by: ProfileDetails;
    is_disliked: boolean;
    is_saved: boolean;
    is_applied: boolean;
    dislikes_count: number;
    questions: Question[];
    external_platform_url: string;
    job_type: string;
  };
}

type UploadFile = { url: string | File, name: string, size: number, selectId: string | number }

type Document = {
  url: string;
  size: number;
  name: string;
};

export type UserApplicationData = {
  email: string;
  full_phone_number: string;
  resumes: Document[];
  cover_letters: Document[];
};

export interface Question {
  required: boolean;
  id: number | string;
  job_id: string | number;
  question_type: "Short Answer" | "Multiple Choice" | "Dropdown";
  question_text: string;
  options: string[];
  answer_text: string | null;
  drop_downs: string[];
}

export interface JobDetailLoader {
  resume: boolean;
  cover_letter: boolean;
}

export interface  ProfileDetails {
  data: {
    attributes: {
      open_for_direct_approach: boolean,
      connection_request_details: {
        id?: number,
        sender_id?: number,
        receiver_id?: number,
        status?: string | "accepted" | "pending",
    } | null,
      photo: string, 
      full_name: string, 
      current_title: string, 
      current_company: string,
      account_details: {
        id: number
      }
    }
  }
}

export interface StateOfCatelogue {
  // Customizable Area Start
  skillCount: number,
  loaderJobDetail: JobDetailLoader;
  progressJobDetail: number;
  isModalOpen:boolean;
  remainingTimeJobDetail: number;
  searchInputValue: { jobTitle: string, location: string }
  jobFeedList: Job[],
  getJobDetails: any,
  tabIndex: number,
  jobDeatilsId: string,
  applyJob: boolean,
  pageCount: number
  jobStatus: boolean,
  experienceList: { id: number | string, title: string }[],
  skillsList: string[],
  postedDate: string,
  postedtBy: string[],
  selectedSkills: string[],
  experience: string,
  selectedCity: string,
  selectedCountry: string,
  cities: { label: string, value: string }[],
  countries: { label: string, value: string, isoCode: string }[],
  locationType: string,
  applyJobModal: boolean,
  showMore: boolean,
  currentPage: number,
  allJobs: Job[],
  totalAllJobsPages: number,
  jobDetails: JobDetails,
  selectedExperiences: string[],
  isDirty: boolean,
  selectedCountryRadio: string,
  selectedCityRadio: string,
  applyJobCurrent: JobDetails,
  userDetails: UserDetailsProfile,
  jobType: string,
  isUserloogedIn: string | undefined,
  coords : {
    latitude: number,
    longitude: number,
  },
  jobIdFromQuery: string | number,
  isEditedId: number | string,
  draftedData: UserApplicationData,
  redirectChatUserInfo: ProfileDetails | null,
  seachLoactionOption: {name: string, value: string, type: string, label: string}[],
  isOnMount: boolean,
  prevJobType: string
  // Customizable Area End
}

interface SS {
  id: string;
}

export type FormValues = {
  full_phone_number: string,
  email: string,
  resume: string | File,
  cover_letter: string | File,
  [key: string]: string | string[] | File
}

export default class CatalogueController1 extends BlockComponent<Props, StateOfCatelogue, SS> {
  getProductApiCallId: Message | string = "";
  findJobsApiId: Message | string = ""
  getJobDetailsApiId: Message | string = ""
  jobFeedApiId: Message | string = ""
  savedJobListApiId: Message | string = ""
  appliedJobListApiId: Message | string = ""
  saveJobDetailsApiCallId: Message | string = ""
  applyJobsApiId: Message | string = ""
  jobStatusApiId: Message | string = ""
  jobExperienceApiId: Message | string = ""
  jobSkillsApiId: Message | string = ""
  getAllJobsApiCallId: Message | string = ""
  getJobDetailssApiCallId: Message | string = ""
  getSubscriptionApiCallId: Message | string = ""
  saveUnSaveApiId: Message | string = ""
  unlikejobapiId: Message | string = ""
  userProfileGetApiCallId: Message | string = ""
  saveDraftJobApplyId: Message | string = ""
  getFilledDataOfJobs: Message | string = ""
  searchLocationValuesApiId: Message | string = ""


  constructor(props: Props) {
    super(props);

    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.NavigationPayLoadMessage)
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      skillCount: 6,
      loaderJobDetail: {
        resume: false,
        cover_letter: false,
      },
      isModalOpen:false,
      progressJobDetail: 0,
      remainingTimeJobDetail: 0,
      searchInputValue: { jobTitle: '', location: '' },
      jobFeedList: [],
      getJobDetails: {},
      tabIndex: 0,
      jobDeatilsId: '',
      applyJob: false,
      pageCount: 1,
      jobStatus: false,
      experienceList: [],
      skillsList: [],
      postedDate: "",
      postedtBy: [],
      selectedSkills: [],
      experience: '',
      selectedCity: '',
      selectedCountry: '',
      cities: [],
      countries: [],
      locationType: 'Country',
      applyJobModal: false,
      showMore: false,
      currentPage: 1,
      allJobs: [],
      totalAllJobsPages: 0,
      jobDetails: {} as JobDetails,
      selectedExperiences: [],
      isDirty: false,
      selectedCountryRadio: "",
      selectedCityRadio: "",
      applyJobCurrent: { id: "" } as JobDetails,
      userDetails: {} as UserDetailsProfile,
      jobType: "",
      isUserloogedIn: undefined,
      coords : {
        latitude: 0,
        longitude: 0,
      },
      jobIdFromQuery: '',
      isEditedId: "",
      draftedData: {} as UserApplicationData,
      redirectChatUserInfo: null,
      seachLoactionOption: [],
      isOnMount: true,
      prevJobType: ""
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    // Customizable Area End
  }
  prevJobId: null | undefined | string | number = null;

  async componentDidMount() {
    let paramID = getIdFromParams(this.props.navigation.getParam("jobId"));
    this.prevJobId = paramID;
    let data = await getAllCountriesFromApi()
    this.setState({ countries: data.map(country => ({
      label: country.name,
      isoCode: `${country.isoCode}`,
      value: country.name
    }))})
    this.getCurrentLocation().finally(() => {
      this.getAllJobs()
    })
    this.jobExperience()
    this.jobSkills()
    this.getUserProfile()
      getStorageData("authToken").then((result) => this.setState({
        isUserloogedIn: result
      }));
  }

  componentDidUpdate(_prevProps: Readonly<Props>, prevState: Readonly<StateOfCatelogue>) {
    if (prevState.currentPage !== this.state.currentPage) {
      this.getAllJobs()
    }
    if ((prevState.postedDate !== this.state.postedDate) ||
      (prevState.selectedSkills.length !== this.state.selectedSkills.length) ||
      ((prevState.postedtBy || this.state.postedtBy) && prevState.postedtBy[0] !== this.state.postedtBy[0]) ||
      (prevState.selectedExperiences.length !== this.state.selectedExperiences.length) ||
      (prevState.isDirty !== this.state.isDirty && this.state.isDirty) ||
      (prevState.selectedCityRadio !== this.state.selectedCityRadio) ||
      (prevState.selectedCountryRadio !== this.state.selectedCountryRadio) || prevState.jobType !== this.state.jobType) {
        this.setState({
          jobIdFromQuery: '',
          prevJobType: prevState.jobType
        })
      this.findJobsHandler()
    }
    let paramID = getIdFromParams(this.props.navigation.getParam("jobId"));
    const prevJobId = this.prevJobId;
    const currentJobId = paramID;
    if ((prevJobId !== currentJobId) && currentJobId) {
      this.prevJobId = currentJobId;
    }
  }

  setSkillToShow = () => {
    this.setState({
      skillCount : this.state.skillCount + 3
    })
  }

  loadMoreFunction = () => {
    this.setState({
      currentPage: this.state.currentPage + 1
    })
  }

  getCurrentLocation = async (): Promise<void> => {
    return getLocation()
    .then((position) => {
      this.setState(
        {coords: {
          latitude: position.coords.latitude,
          longitude: position.coords.longitude
        }}
        );
    }).catch(() => {
      this.setState({
        coords: { latitude: 0, longitude: 0 },
      });
    })
  };

  handleSearchLocationChangeField = async (value: string) => {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      "token": await getStorageData("authToken")
    };

    const getSearchValues = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.searchLocationValuesApiId = getSearchValues.messageId;

    getSearchValues.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_joblisting/jobs/get_cities_countries?filter=${value}`
    );

    getSearchValues.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getSearchValues.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );

    runEngine.sendMessage(getSearchValues.id, getSearchValues);
  }

  handleResponseForSubscriptionRequest = async (
    from: string,
    message: Message
  ) => {
    if ( this.getSubscriptionApiCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiResponse.errors || apiResponse.error) {
        this.handleApiResponse(apiResponse)
      } else {
        if(isSubscribedToPlan(apiResponse.user_subscription.data.attributes.status)){
          return this.gotoMessage(this.state.redirectChatUserInfo)
        }
        toast.error("You must have an active subscription to access chat section")
      }
    }
  };

  handleApiResponse = (response: {error?: string, errors?: {"token": string}[]}) => {
    if(response.error) {
        toast.error(response.error);
    }else if(response.errors) {
        Object.values(response.errors[0]).forEach((item: string) => toast.error(item))
        if (Object.keys(response.errors[0]).includes("token")) {
            this.goToLoginPage()
        }
    }
  }

  gotoMessage=(data: ProfileDetails | null)=>{
    const message: Message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), 'Messaging');
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    const raiseMessage: Message = new Message(getName(MessageEnum.NavigationPayLoadMessage) );
    raiseMessage.addData(getName(MessageEnum.SessionResponseData), {from: "Profile", accountId:data
         }) 
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(message);
  }

  onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    if ((value.trim() === "") && this.state.isDirty) {
      this.findJobsHandler()
    }

    this.setState(prevState => ({
      ...prevState,
      searchInputValue: {
        ...prevState.searchInputValue,
        [name]: value
      },
    }), () => {
      if (this.state.searchInputValue.jobTitle.trim() === "" && this.state.searchInputValue.location.trim() === "") {
        this.setState({
          isDirty: true,
          currentPage: 1
        })
      }
    });
  }

  closeModal = () => {
    this.setState({ applyJobModal: false })
  }

  handleMenuItemClick = (_index: number, opt: {
    id: number | string;
    title: string;
  }) => {
    this.setState({ postedDate: this.state.postedDate === opt.title ? "" : opt.title });

  };

  postedByHandleClick = (_index: number, opt: { id: number | string, title: string }) => {
    this.setState({ postedtBy: this.state.postedtBy.includes(opt.title) ? this.state.postedtBy.filter(item => item !== opt.title) : [opt.title] })
  }

  experienceHandleClick = (_index: number, opt: { id: number | string, title: string }) => {
    this.setState({ selectedExperiences: this.state.selectedExperiences.includes(opt.title) ? this.state.selectedExperiences.filter(item => item !== opt.title) : [...this.state.selectedExperiences, opt.title] })
  }

  skillsByHandleClick = (opt: { id: number | string, title: string }) => {
    this.setState({ selectedSkills: this.state.selectedSkills.includes(opt.title) ? this.state.selectedSkills.filter(item => item !== opt.title) : [...this.state.selectedSkills, opt.title] })
  }

  getJobStatusApiHandler = (id: string) => {
    this.setState({ jobDeatilsId: id })
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      "token": localStorage.getItem("authToken")
    };

    const getJobStatus = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.jobStatusApiId = getJobStatus.messageId;

    getJobStatus.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.jobStatusApiEndPoint}/${id}/check_apply_job_status`
    );

    getJobStatus.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getJobStatus.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );

    runEngine.sendMessage(getJobStatus.id, getJobStatus);
  }

  jobExperience = () => {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      "token": localStorage.getItem("authToken")
    };

    const getJobExperience = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.jobExperienceApiId = getJobExperience.messageId;

    getJobExperience.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.experinceOptions}`
    );

    getJobExperience.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getJobExperience.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );

    runEngine.sendMessage(getJobExperience.id, getJobExperience);
  }

  jobSkills = () => {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      "token": localStorage.getItem("authToken")
    };

    const getJobSkills = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.jobSkillsApiId = getJobSkills.messageId;

    getJobSkills.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.skillsOptions}`
    );

    getJobSkills.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getJobSkills.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );

    runEngine.sendMessage(getJobSkills.id, getJobSkills);
  }

  findJobsHandler = () => {
    if (this.state.currentPage === 1) {
      this.getAllJobs()
    } else {
      this.setState({
        currentPage: 1
      })
    }
  }
  findJobsHandlerButton = () => {
    if((this.state.searchInputValue.jobTitle + this.state.searchInputValue.location).trim() === "") {
      return;
    }
    if (this.state.currentPage === 1) {
      this.setState({
        isDirty: true
      })
    } else {
      this.setState({
        currentPage: 1,
        isDirty: true
      })
    }
  }


  goToSignUpPage = () => {
    const msg: Message = new Message(
      getName(MessageEnum.NavigateEmailSignUpMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  handleNavigationToMessage = () => {
    if (
      this.state.jobDetails.attributes.created_by.data.attributes.open_for_direct_approach ||
      (this.state.jobDetails.attributes.created_by.data.attributes.connection_request_details?.status === "accepted")
    ) {
      this.setState({
        redirectChatUserInfo: this.state.jobDetails.attributes.created_by
      });
      this.gotoMessage(this.state.jobDetails.attributes.created_by)
    } else {
      toast.error("User is not open for direct approach")
    }
  }

  goToLoginPage = () => {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationEmailLogInMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  handleSaveAndUnSaveJob = (saveJob: boolean) => {
    if(!this.state.isUserloogedIn) {
      this.goToLoginPage();
    }
    if (saveJob) {
      this.unSaveJobHandler(this.state.jobDetails.id)
    } else {
      this.saveJobsHandler(this.state.jobDetails.id)
    }
  }

  handleLikeAndDislikeJob = (liked: boolean) => {
    if(!this.state.isUserloogedIn) {
      this.goToLoginPage();
    }
    if (liked) {
      this.likeJobHandler(this.state.jobDetails.id)
    } else {
      this.dislikeJobHandler(this.state.jobDetails.id)
    }
  }

  unSaveJobHandler = async (jobId: number | string) => {
    const headers = {
      "Content-Type": configJSON.apiContentType,
      "token": await getStorageData("authToken")
    };

    const unSaveJobDetails = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.saveUnSaveApiId = unSaveJobDetails.messageId;

    unSaveJobDetails.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.savedJobApiEndPoint}/${jobId}/unsave_job`
    );

    unSaveJobDetails.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    unSaveJobDetails.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteType
    );
    runEngine.sendMessage(unSaveJobDetails.id, unSaveJobDetails);
  }


  saveJobsHandler = async (jobId: number | string) => {
    const headers = {
      "Content-Type": configJSON.apiContentType,
      "token": await getStorageData("authToken")
    };

    const saveJobDetails = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.saveUnSaveApiId = saveJobDetails.messageId;

    saveJobDetails.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.savedJobApiEndPoint}/${jobId}/save_job`
    );

    saveJobDetails.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    saveJobDetails.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypePost
    );
    runEngine.sendMessage(saveJobDetails.id, saveJobDetails);
  }

  dislikeJobHandler = async (jobId: number | string) => {
    const headers = {
      "token": await getStorageData("authToken")
    };

    const dislikeJobApicall = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.unlikejobapiId = dislikeJobApicall.messageId;

    let formData = new FormData();

    formData.append("dislikeable_id", `${jobId}`)
    formData.append("dislikeable_type", `BxBlockJoblisting::Job`)


    dislikeJobApicall.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_joblisting/jobs/${jobId}/dislike_job`
    );

    dislikeJobApicall.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    dislikeJobApicall.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    dislikeJobApicall.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypePost
    );
    runEngine.sendMessage(dislikeJobApicall.id, dislikeJobApicall);
  }


  likeJobHandler = async (jobId: number | string) => {
    const headers = {
      "token": await getStorageData("authToken")
    };

    const likejobApiCall = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    let formData = new FormData();

    formData.append("dislikeable_type", `BxBlockJoblisting::Job`);
    formData.append("dislikeable_id", `${jobId}`);

    this.unlikejobapiId = likejobApiCall.messageId;

    likejobApiCall.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_joblisting/jobs/${jobId}/remove_dislike_job`
    );

    likejobApiCall.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );


    likejobApiCall.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    likejobApiCall.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteType
    );
    runEngine.sendMessage(likejobApiCall.id, likejobApiCall);
  }

  submitJobDetailHandler = (data: FormValues) => {
    const authToken = localStorage.getItem("authToken");
    const startTime = Date.now();
    
    if (data.resume instanceof File) {
      this.setState({ 
        loaderJobDetail: {
          ...this.state.loaderJobDetail,
          resume: true,
        } 
      })
    }
    
    if (data.cover_letter instanceof File  ) {
      this.setState({ 
        loaderJobDetail: {
          ...this.state.loaderJobDetail,
          cover_letter: true
        } 
      })
    }

    const formData = new FormData();

    formData.append("job[id]", `${this.state.applyJobCurrent.id}`);
    formData.append("job[contact]", data.full_phone_number);
    formData.append("job[email]", data.email);
    formData.append("job[answers][][question_id]", "");
    formData.append("job[answers][][answer]", "");
    formData.append("job[resume]", data.resume);
    formData.append("job[cover_letter]", data.cover_letter);

    for (let field in data) {
      if (field.includes("Question")) {
        formData.append("job[answers][][question_id]", field.substring(8));
        formData.append("job[answers][][answer]", `${data[field]}`)
      }
    }

    const config: AxiosRequestConfig = {
      headers: { token: authToken ?? '' },
      onUploadProgress: (progressEvent: AxiosProgressEvent) => {
        const { loaded, total } = progressEvent;
        if (total) {
          const percentCompleted = Math.round((loaded * 100) / total);

          const currentTime = Date.now();
          const elapsedTime = (currentTime - startTime) / 1000;

          const uploadSpeed = loaded / elapsedTime;

          const remainingTime = (total - loaded) / uploadSpeed;

          this.setState({ progressJobDetail: percentCompleted, remainingTimeJobDetail: remainingTime })
        }
      }
    };

    axios.post(`${configJSON.baseURL.baseURL}/${configJSON.ApplyJobApiEndPointEndPoint}`, formData, config)
      .then(() => {
        hotToast.success('Your application has been submitted successfully.')
        this.setState({ applyJob: false, jobDetails: {...this.state.jobDetails, attributes: {...this.state.jobDetails.attributes, is_applied	: true}}, allJobs: this.state.allJobs.map(job => job.id === this.state.jobDetails.id ? {...job, attributes: {...job.attributes, is_applied: true}} : job) });
      })
      .catch((error : {response: {data: {error : string}}}) => {
        if(error.response.data.error){
          this.setState({
            isModalOpen:true
          })
        }

      }).finally(() => {
        this.setState({ 
          loaderJobDetail: {
            resume: false,
            cover_letter: false
          } 
         })
      });
  }

  clearAllFilters = () => {
    this.setState({
      searchInputValue: {
        jobTitle: "",
        location: ""
      },
      selectedCityRadio: "",
      selectedCountryRadio: "",
      postedtBy: [],
      postedDate: "",
      selectedSkills: [],
      selectedExperiences: [],
      seachLoactionOption: []
    })
    this.onChangeHandler({target : {name: "location", value: ""}}as unknown as React.ChangeEvent<HTMLInputElement>);
    this.onChangeHandler({target : {name: "jobTitle", value: ""}} as unknown as React.ChangeEvent<HTMLInputElement>);
  }



  handleCityInputChangeDebounced = debounce(async (value: string) => {

    const cities = await getCitiesByName(value)

    this.setState({ cities: cities });
  }, 2000);

  handleCountryInputChangeDebounced = debounce((value: string) => {
  }, 2000)

  countryListHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    if (value) {
      this.handleCountryInputChangeDebounced(value);
    }
  };

  cityListHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    this.handleCityInputChangeDebounced(value);
  };


  locationRadioButtonHandler = (event: React.ChangeEvent<HTMLInputElement>, _value: string) => {
    this.setState({ locationType: event.target.value })
  }

  applyJobHandler = (status: boolean) => {
    this.setState({ applyJob: status, applyJobCurrent: this.state.jobDetails })
  }

  getJobTags = () => {
    if(!this.state.jobDetails.attributes) {
      return META_TAGS
    }
    let skills = this.state.jobDetails.attributes.skills_required.join(", ");
    let jobTitle = this.state.jobDetails.attributes.job_title;
    let jobCompany = this.state.jobDetails.attributes.company_name;
    let jobDepartment = this.state.jobDetails.attributes.department;
    let jobEducation = this.state.jobDetails.attributes.education;
    let jobLocation = this.state.jobDetails.attributes.location;
    let jobIndustryType = this.state.jobDetails.attributes.industry_type;
    let jobExperience = this.state.jobDetails.attributes.experience_required;

    return jobTitle + ", " + skills + ", " + jobCompany + ", " + jobDepartment + ", " + jobEducation + ", " + jobLocation + ", " + jobIndustryType + ", " + jobExperience
  }

  getJobDescription = () => {
    if (this.state.jobDetails.attributes) {
      return this.state.jobDetails.attributes.job_description
    }
    return "Explore private equity, venture capital, investment banking, and more. Join our platform for exclusive career opportunities and connect with top firms." 
  }

  resumeRadioButtonSelected = () => {

  }

  savedJobList = (page: string) => {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      "token": localStorage.getItem("authToken")
    };

    const savedJobList = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.savedJobListApiId = savedJobList.messageId;

    savedJobList.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.savedJobListingEndPoint}`
    );

    savedJobList.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    savedJobList.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );
    runEngine.sendMessage(savedJobList.id, savedJobList);
  }

  appliedJobList = (page: string, postedDate?: string, experience?: string, selectedSkills?: string, selectedCity?: string, selectedCountry?: string) => {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      "token": localStorage.getItem("authToken")
    };

    const params = {
      page: page
    }

    const appliedJobList = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.appliedJobListApiId = appliedJobList.messageId;

    appliedJobList.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.appliedJobListingEndPoint}?${new URLSearchParams(params)}`
    );

    appliedJobList.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    appliedJobList.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );
    runEngine.sendMessage(appliedJobList.id, appliedJobList);
  }

  handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    this.setState({ tabIndex: newValue, isDirty: true });
    if (newValue === 0) {
      this.setState({
        jobType: ""
      })
    } else if (newValue === 1) {
      this.setState({
        jobType: "saved"
      })
    } else if (newValue === 2) {
      this.setState({
        jobType: "applied"
      })
    }
  };

  handleShowMore = () => {
    this.setState({ showMore: true });
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
    this.handleResponseForSubscriptionRequest(from, message);

    if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      const queryParams = message.getData(
        getName(MessageEnum.NavigationPayLoadMessage)
      );
      if(queryParams) {
        this.setState({
          jobIdFromQuery : queryParams.query 
        })
      }
    }
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const webApiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson) {
        switch (webApiRequestCallId) {
          case this.jobExperienceApiId:
            this.handleJobExperienceResponse(responseJson);
            break;
          case this.jobSkillsApiId:
            this.handleJobSkillsResponse(responseJson);
            break;
          case this.applyJobsApiId:
            this.handleApplyJobsResponse(responseJson);
            break;
          case this.getAllJobsApiCallId:
            this.handleAllJobsResponse(responseJson);
            break;
          case this.getJobDetailssApiCallId:
            this.handleJobDetailsResponse(responseJson);
            break;
          case this.saveUnSaveApiId:
          case this.unlikejobapiId:
            this.getJobDetails(this.state.jobDetails.id);
            break;
          case this.userProfileGetApiCallId:
            this.handleProfileData(responseJson.data)
            break;
          case this.getFilledDataOfJobs:
            this.applyJobHandler(true)
            this.handleDraftData(responseJson?.data?.attributes);
            break;
          case this.searchLocationValuesApiId:
            this.handleResponseSearchLocation(responseJson?.data || [])
            break;

        }
      }
    }
    // Customizable Area End
  }

  handleResponseSearchLocation = (value: {name: string, type: string}[]) => {
    this.setState({
      seachLoactionOption: value.map((item) => ({name: item.name, type: item.type, value: item.name, label: item.name}))
    })
  }
  handleProfileData = (responseJson: UserDetailsProfile) => {
    this.setState({
      userDetails: responseJson
    })
  }

  handleJobExperienceResponse = (responseJson: { experience_required: [] }) => {
    const options = responseJson.experience_required.map((item, index) => ({
      id: index,
      title: item
    }));
    this.setState({ experienceList: options });
  }

  handleJobSkillsResponse = (responseJson: { skills: [] }) => {
    this.setState({ skillsList: responseJson.skills });
  }

  handleApplyJobsResponse = (responseJson: { message?: string }) => {
    if (responseJson?.message) {
      this.setState({ applyJobModal: true, tabIndex: 2, applyJob: false });
      this.appliedJobList('1');
    }
  }

  handleGetJobDetails = (paramID: string | undefined, isFilterSelectedId: boolean, responseJson: { recruiter_job_listing: { data: Job[] }, total_page: number } | {message: string} ) => {
    let response = responseJson as { recruiter_job_listing: { data: Job[] }, total_page: number }
    if (!paramID || !isFilterSelectedId) {
      if(paramID && !isFilterSelectedId && this.state.prevJobType === "") {
        this.getJobDetails(paramID);
        this.handleCatalogueNavigation(paramID)
      } else {
        let finalId = this.state.isOnMount ? paramID : response.recruiter_job_listing.data[0].id
        this.getJobDetails(finalId as string);
        this.handleCatalogueNavigation(finalId as string )
      }
    } else {
      if (response.recruiter_job_listing.data.length > 0)
        if (!this.state.isOnMount) {
          this.getJobDetails(response.recruiter_job_listing.data[0].id);
          this.handleCatalogueNavigation(response.recruiter_job_listing.data[0].id)
        } else {
          this.getJobDetails(paramID);
        }
    }
  }

  handleAllJobsResponse = (responseJson: { recruiter_job_listing: { data: Job[] }, total_page: number } | {message: string}) => {
    this.setState({
      isDirty: false,
      applyJob: false,
      isOnMount: false
    })
    const { currentPage, allJobs } = this.state;

    if ('recruiter_job_listing' in responseJson) {
      const newAllJobs = currentPage === 1 ? responseJson.recruiter_job_listing.data : [...allJobs, ...responseJson.recruiter_job_listing.data];
      this.setState({
        allJobs: newAllJobs,
        totalAllJobsPages: responseJson.total_page
      });
      let paramID = getIdFromParams(this.props.navigation.getParam("jobId"));

      let isFilterSelectedId = isAnyFilterSelected(
        this.state.searchInputValue.jobTitle,
        this.state.searchInputValue.location,
        this.state.selectedCityRadio,
        this.state.selectedCountryRadio,
        this.state.postedtBy,
        this.state.selectedSkills,
        this.state.selectedExperiences,
        this.state.jobType,
        this.state.postedDate,
      )
      this.handleGetJobDetails(paramID, isFilterSelectedId, responseJson)
    }
    else if ('message' in responseJson) {
      toast.info(responseJson.message)
    }
  }

  handleCatalogueNavigation = (jobId: string | number) => {
    //@ts-ignore
    this.props.navigation.navigate(`Catalogue`, { jobId: jobId })
  }

  handleJobDetailsResponse = (responseJson: { data: JobDetails }) => {
      const hasInList = this.state.allJobs.find((job) => job.id === responseJson.data.id)
      this.setState({ 
        jobDetails: responseJson.data,
        allJobs: hasInList ? this.state.allJobs :  [responseJson.data as Job, ...this.state.allJobs],
        jobIdFromQuery: ''
      });
  }

  // Customizable Area Start

  handleCountryChange = (value: string, isoCode: string | null | undefined) => {
    this.setState({
      selectedCountryRadio: value
    })
  }
  handleCityChange = (value: string) => {
    this.setState({
      selectedCityRadio: value
    })
  }

  handleModalClose=()=>{
    this.setState({
      isModalOpen:false
    })
  }
  handleShowPlans = () => {
    const hamburgerToFeedbackObj = new CustomisableSubscriptionAdapter()
    const msg = new Message(getName(MessageEnum.CustomSubscriptionDataMessage))
     msg.addData(getName(MessageEnum.NavigationPropsMessage),this.props);
     hamburgerToFeedbackObj.send(msg)
  };

  getAllJobs = async (): Promise<void> => {
    const headers: { [key: string]: string } = {
      "Content-Type": configJSON.validationApiContentType,
    };

    let authToken = await getStorageData("authToken");

    if(authToken) {
      headers["token"] = authToken
    }
  
    const URL: string = this.constructURL();
  
    this.sendAPIRequest(URL, headers);
  }
  
  constructURL = (): string => {
    const { jobTitle, location } = this.state.searchInputValue;
    let URL: string = `${configJSON.getAllJobs}?page=${this.state.currentPage}&per_page=${10}`;
  
    let appendArrayParams = (paramName: string, array: string[]): void => {
      if (array.length > 0) {
        for (let item of array) {
          URL += `&${paramName}[]=${item}`
        }
      }
    }

    if ((jobTitle.trim() !== "") && this.state.isDirty) {
      URL += `&search_key=${jobTitle}`
    }
  
    if ((location.trim() !== "") && this.state.isDirty) {
      URL += `&location=${location}`
    }
  
    if ((this.state.selectedCityRadio.trim() !== "")) {
      URL += `&city=${this.state.selectedCityRadio}`
    }
  
    if ((this.state.selectedCountryRadio.trim() !== "")) {
      URL += `&country=${this.state.selectedCountryRadio}`
    }
    if (this.state.postedtBy.length) {
      URL += `&posted_by=${this.state.postedtBy[0]}`
    }

    if (!!this.state.postedDate) {
      URL += `&date_posted[]=${this.state.postedDate}`
    }
    
    appendArrayParams("skills", this.state.selectedSkills);
    appendArrayParams("experience", this.state.selectedExperiences);
  
    if (this.state.jobType !== "") {
      URL += `&type=${this.state.jobType}`
    }

    if(this.state.coords.latitude && this.state.coords.longitude ) {
      URL += `&latitude=${this.state.coords.latitude}&longitude=${this.state.coords.longitude}`
    }
  
    return URL;
  }
  
  appendArrayParams = (URL: string, paramName: string, array: string[]): void => {
    if (array.length > 0) {
      for (let item of array) {
        URL += `&${paramName}[]=${item}`
      }
    }
  }
  
  sendAPIRequest = (URL: string, headers: { [key: string]: string }): void => {
    const getAllJobsApiCall = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getAllJobsApiCallId = getAllJobsApiCall.messageId;

    getAllJobsApiCall.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      URL
    );

    getAllJobsApiCall.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getAllJobsApiCall.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );
    runEngine.sendMessage(getAllJobsApiCall.id, getAllJobsApiCall);
  }

  checkForSubscription = async () => {
    let authToken = await getStorageData("authToken");

    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: authToken,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getSubscriptionApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.checkForSubscriptionAPI}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getJobDetails = async (jobId: number | string) => {
    const headers:  { [key: string]: string }  = {
      "Content-Type": configJSON.validationApiContentType,
    };
    this.prevJobId = jobId
    let authToken = await getStorageData("authToken");

    if(authToken) {
      headers["token"] = authToken
    }

    const getAllJobsApiCall = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getJobDetailssApiCallId = getAllJobsApiCall.messageId;

    getAllJobsApiCall.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.landingPageJobDetailsApiEndPoint}/${jobId}/landing_page_job_details`
    );

    getAllJobsApiCall.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getAllJobsApiCall.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );
    runEngine.sendMessage(getAllJobsApiCall.id, getAllJobsApiCall);
  }

  handleApplyJob = (type: string) => {
    if(type === "Platform") {
      this.getJobFilledDetails();
    } else {
      window.open(this.state.jobDetails.attributes.external_platform_url, '_blank')
    }
  }
  getTitleOfApplyButton = (type:string): string => {
    return type === "Platform" ? "Easy Apply" : "Apply"
  }

  getJobFilledDetails = async () => {
    const headers:  { [key: string]: string }  = {
      "Content-Type": configJSON.validationApiContentType,
    };

    let authToken = await getStorageData("authToken");

    if(authToken) {
      headers["token"] = authToken
    }

    const getFilledJobDetail = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getFilledDataOfJobs = getFilledJobDetail.messageId;

    getFilledJobDetail.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_profile/draft_profile_details/get_draft_profile`
    );

    getFilledJobDetail.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getFilledJobDetail.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );
    runEngine.sendMessage(getFilledJobDetail.id, getFilledJobDetail);
  }

  saveDraftJobApplyData = async (data: {email: string, full_phone_number: string, resume: UploadFile[], cover_letter: UploadFile[]}) => {
    const headers = {
      "token": await getStorageData("authToken")
    };

    let { email, full_phone_number, resume, cover_letter } = data;
    
    const postDraftDataApi = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    let formData = new FormData();

    formData.append("draft_profile_detail[email]", email);
    formData.append("draft_profile_detail[full_phone_number]", full_phone_number);
    let resumes = !!this.state.userDetails.attributes.resume?.url ? resume.filter(cover_letter => (cover_letter.url !== this.state.userDetails.attributes.resume.url)) : resume;
    let coverLetters = !!this.state.userDetails.attributes.cover_letter?.url ?  cover_letter.filter(cover_letter => cover_letter.url !== this.state.userDetails.attributes.cover_letter.url) : cover_letter;

    for (let file of resumes ) {
      formData.append("draft_profile_detail[resumes][]", file.url);
    }
    for (let file of coverLetters) {
      formData.append("draft_profile_detail[cover_letters][]", file.url);
    }

    this.saveDraftJobApplyId = postDraftDataApi.messageId;

    postDraftDataApi.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_profile/draft_profile_details`
    );

    postDraftDataApi.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    postDraftDataApi.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    postDraftDataApi.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    runEngine.sendMessage(postDraftDataApi.id, postDraftDataApi);
  }

  handleDraftData = (data: UserApplicationData) => {
    if(data) {
      this.setState({draftedData: data})
    }
  };

  openUserDetailsHandler = (id: string) => {
      this.getJobDetails(id);
      this.applyJobHandler(false)
  }

  getUserProfile = async () => {
    const authToken = await getStorageData("authToken")
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.userProfileGetApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getUserProfileDetailsEndpoint
    );

    const header = {
      "Content-Type": configJSON.contentTypeApiGetUserProfile,
      token: authToken
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  // Customizable Area End
}



