import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { AllCategory } from "./FilteroptionsController";
import { City, Country } from 'country-state-city'
import { debounce, getAllCountriesFromApi, getLoginToken, getUserRole} from "../../../components/src/Utils.web";
import React from "react";
import { SyntheticEvent } from 'react';

export type tempData = temp[]

export interface temp {
  name?: string
  menuName: string[]
}

interface Option {
  value: string;
  label: string;
}

interface OptionSkill {
  label: string;
}

export interface Root {
  data: Daum[]
}

export interface Daum {
  id: string
  type: string
  attributes: Attributes
}

export interface Attributes {
  id: number
  full_name: string
  location?: string
  current_title?: string
  full_phone_number?: string
  email: string
  experience: string | null
  salary: string | null
  future_self: string | null
  current_company: string | null
  company_indusry: string | null
  bio?: string
  work_email_id?: string
  personal_email_id?: string
  city?: string
  country?: string
  skills: string[][]
  selected_group_context: string | null
  open_for_direct_approach: boolean
  potential_score: string | null
  relevance_score: string | null
  overall_rank: string | null
  photo?: string
  account_details: AccountDetails
  work_experiences: WorkExperiences
  resume: string | null
  cover_letter: string | null
}

export interface AccountDetails {
  id: number
  full_name?: string
  email: string
  activated: boolean
  full_phone_number: string
  type: string
  role: string
}

export interface WorkExperiences {
  data: any[]
}


type ReactSyntheticEvent<T = Element, E = Event> = SyntheticEvent<T, E>;

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  country?: (selectedCountry: string) => void;
  city?: (selectedCity: string) => void;
  date?: (selectedDate: string) => void;
  skills?: (selectedSkill: string[]) => void;
  postBy?: (selectedPostedBy: string) => void;
  experience?: (selectedExperiance: string[]) => void;
  disableButton?: (disableButton: boolean) => void;
  experienceSelect?: (selectedExperianceCheck:string[]) => void
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string;
  data: AllCategory[];
  arrayHolder: AllCategory[];
  countryArray: {value: string, label: string, isoCode: string}[]
  cityArray: Option[]
  tempData: tempData
  optionCountry: {value: string, label: string, isoCode: string}[]
  optionCity: Option[]
  optionSkills: OptionSkill[]
  selectedCountry: string
  selectedCity: string
  selectedDate: string
  selectedSkill: string[]
  selectedPostedBy: string
  selectedExperiance: string[]
  selectedExperianceCheck: string[]
  disableButton: boolean
  skillList: string[],
  userRole: string | null,
  countriesList: {value: string, label: string, isoCode: string}[]
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class FilteritemsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getProductApiCallId: string = "";
  getSkillListApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];

    this.state = {
      token: "",
      data: [],
      arrayHolder: [],
      countryArray: [],
      cityArray: [],
      tempData: [],
      optionCountry: [],
      optionCity: [],
      optionSkills: [],
      selectedCountry: '',
      selectedDate: '',
      selectedExperiance: [],
      selectedExperianceCheck: [],
      selectedPostedBy: '',
      selectedSkill: [],
      selectedCity: '',
      disableButton: true,
      skillList: [],
      userRole: '',
      countriesList: []
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  async componentDidMount() {
    // Customizable Area Start
    this.setState({
      countriesList: (await getAllCountriesFromApi()).map(country => ({name: country.name, value: country.name, isoCode: `${country.isoCode}`, label: country.name}))
    })
    const userrole = getUserRole()
    this.setState({ userRole: userrole }, () => {
      this.setCountryCity()
      this.skillList()
    })
    super.componentDidMount();
    this.getToken();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
    // Customizable Area End
  }

  getToken = () => {
    const messageValue: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(messageValue);
  };

  navigateToFilter = () => {
    let arrayHolder = this.state.arrayHolder;
    const priceRange = arrayHolder.map(element => element.attributes.price);
    var minimumValue = 0;
    var maximumValue = 0;
    if (
      (typeof priceRange === "number" || priceRange?.length >= 1) &&
      !priceRange
    ) {
      minimumValue = Math.min.apply(null, priceRange);
      maximumValue = Math.max.apply(null, priceRange);
    }
    let params = this.props.navigation.state?.params;
    if (params != undefined) {
      if (params.priceSelectedMin && params.priceSelectedMax) {
        this.props.navigation.push("Filteroptions", {
          min: minimumValue,
          max: maximumValue,
          priceSelectedMin: params.priceSelectedMin,
          priceSelectedMax: params.priceSelectedMax
        });
      }
    } else {
      this.props.navigation.push("Filteroptions", { min: minimumValue, max: maximumValue });
    }
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
    // if (getName(MessageEnum.SessionResponseMessage) === message.id) {
    //   let token = message.getData(getName(MessageEnum.SessionResponseToken));
    //   this.setState({ token: token });
    //   this.getListRequest(token);
    // }
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getProductApiCallId != null &&
      this.getProductApiCallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson && !responseJson.errors && responseJson.data) {
        this.setState({ arrayHolder: responseJson.data });
        let params = this.props.navigation.state.params;
        if (params?.data) {
          if (params.data.length > 0) {
            this.setState({ data: params.data });
          } else {
            this.showAlert("Error", "No data Found");
          }
        } else {
          this.setState({
            data: responseJson.data
          });
        }
      } else {
        var errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
        this.showAlert("Error", errorReponse);
        this.parseApiCatchErrorResponse(errorReponse);
      }
    }

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId === this.getSkillListApiCallId) {
        if (responseJson.skills && responseJson.skills.length) {
          this.setState({
            skillList: responseJson.skills
          })
        }
      }
    }
    // Customizable Area End
  }
  getListRequest = (token: string) => {
    const header = {
      "Content-Type": configJSON.productApiContentType,
      token: token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getProductApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.productAPiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  // Customizable Area Start
  setCountryCity = () => {
    const countries = Country.getAllCountries().map((country) => ({
      value: country.name,
      label: country.name,
      isoCode: country.isoCode
    }));
    const countryArray = countries.map(item => item.value);

    const cities = City?.getAllCities().map(city => ({
      value: city.name,
      label: city.name
    }));
    const cityArray = cities.map(item => item.value);

    const tempDataCandidate = [
      {
        name: 'Date Posted',
        menuName: ['Last 24 hrs', 'Last 3 days', 'Last 7 days', 'Last 14 days', 'Last 30 days']
      },
      {
        name: 'Location',
        menuName: []
      },
      {
        menuName: []
      },
      {
        name: 'Posted by',
        menuName: ['Work Centivo Platform', 'External']
      },
      {
        name: 'Skills',
        menuName: []
      },
      {
        name: 'Experience',
        menuName: ['0-2 Years', '2-4 Years', '4-6 Years', '6-8 Years', '8-10 Years', '10+ Years']
      },
    ]

    const tempDataRecruiter = [
      {
        name: '',
        menuName: []
      },
      {
        name: 'Country',
        menuName: []
      },
      {
        name: 'City',
        menuName: []
      },
      {
        name: '',
        menuName: []
      },
      {
        name: 'Skills',
        menuName: []
      },
      {
        name: 'Experience',
        menuName: ['0-2 Years', '2-4 Years', '4-6 Years', '6-8 Years', '8-10 Years', '10+ Years']
      },
    ]

    this.setState({
      countryArray: countries,
      cityArray: cities,
    })
    if (this.state.userRole === 'recruiter'){
      this.setState({tempData: tempDataRecruiter})
    } else {
      this.setState({tempData: tempDataCandidate})
    }
  }

  autoCompleteListHandler = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const { value } = event.target;
    if (value) {
      if (index === 1) {
        this.handleCountryInputChangeDebounced(value);
      } else if (index === 2) {
        this.handleCityInputChangeDebounced(value);
      } else if (index === 4) {
        this.handleSkillInputChangeDebounced(value);
      }
    }
  };
  
  initialRenderState = (index: number) => {
    if (index === 1) {
      const tempDataCandidate = [
        {
          name: 'Date Posted',
          menuName: ['Last 24 hrs', 'Last 3 days', 'Last 7 days', 'Last 14 days', 'Last 30 days']
        },
        {
          name: 'Country',
          menuName: []
        },
        {
          name: 'City',
          menuName: []
        },
        {
          name: 'Posted by',
          menuName: ['Work Centivo Platform', 'External']
        },
        {
          name: 'Skills',
          menuName: []
        },
        {
          name: 'Experience',
          menuName: ['0-2 Years', '2-4 Years', '4-6 Years', '6-8 Years', '8-10 Years', '10+ Years']
        },
      ]
      const tempDataRecruiter = [
        {
          name: '',
          menuName: []
        },
        {
          name: 'Country',
          menuName: []
        },
        {
          name: 'City',
          menuName: []
        },
        {
          name: '',
          menuName: []
        },
        {
          name: 'Skills',
          menuName: []
        },
        {
          name: 'Experience',
          menuName: ['0-2 Years', '2-4 Years', '4-6 Years', '6-8 Years', '8-10 Years', '10+ Years']
        },
      ]
      if (this.state.userRole === 'recruiter'){
        this.setState({tempData: tempDataRecruiter})
      } else {
        this.setState({tempData: tempDataCandidate})
      }
      this.setState({ optionCountry: this.state.countryArray });
    } else if (index === 4) {
      const skills = this.state.skillList
      const finalSkill = skills.map((item: string) => ({label: item }))
      this.setState({ optionSkills: finalSkill });
    }
  }

  handleCountryInputChangeDebounced = (value: string) => {
   
  };

  handleCityInputChangeDebounced = debounce((value: string) => {

  }, 2000);

  handleSkillInputChangeDebounced = (value: string) => {
    const skills = this.state.skillList
    const finalSkill = skills.filter((item: string) => item.toLocaleLowerCase().includes(value.toLocaleLowerCase())).map((item: string) => ({label: item }))
    this.setState({ optionSkills: finalSkill });
  };

  selectSkill = (val: string) => {
    if (val != undefined) {
      const value = val;
      const updatedSkills = this.state.selectedSkill.includes(value);
      const prevState = [...this.state.selectedSkill]
      const result = updatedSkills ? prevState.filter(skill => skill !== value) : [...prevState, value]
      this.setState({ selectedSkill: result }, () => { 
        this.props.skills?.(this.state.selectedSkill);
        this.checkForResetFilter()
      })
    }
  }

  selectExperience = (val: string) => {
    let value = val;
    const updatedExperienceCheck = this.state.selectedExperianceCheck.includes(value);
    const prevStateCheck = [...this.state.selectedExperianceCheck]
    const resultCheck = updatedExperienceCheck ? prevStateCheck.filter(experience => experience !== value) : [...prevStateCheck, value]
    this.setState({ selectedExperianceCheck: resultCheck}, () => { this.props.experienceSelect?.(this.state.selectedExperianceCheck) })
    if (val === "10+ Years") {
      value = "10plus Years"
    }
    const updatedExperience = this.state.selectedExperiance.includes(value);
    const prevState = [...this.state.selectedExperiance]
    const result = updatedExperience ? prevState.filter(experience => experience !== value) : [...prevState, value]
    this.setState({ selectedExperiance: result}, () => { 
      this.props.experience?.(this.state.selectedExperiance);
      this.checkForResetFilter();
    })
  }

  autoCompleteChange = (val: any, key: string) => {
    this.setState({ disableButton: false }, () => { this.props.disableButton?.(this.state.disableButton) })
    switch (key) {
      case 'Country':
        this.setState({ selectedCountry: val?.label }, () => { this.props.country?.(this.state.selectedCountry); this.checkIndexAutoComplete(2) })
        break;
      case 'City':
        this.setState({ selectedCity: val?.label }, () => { this.props.city?.(this.state.selectedCity) })
        break;
      case 'Date Posted':       
        this.setState({ selectedDate: val.target.value }, () => { 
          this.props.date?.(this.state.selectedDate); 
        })
        break;
      case 'Skills':
        this.selectSkill(val?.label);
        break;
      case 'Posted by':
        this.setState({ selectedPostedBy: val.target.value }, () => { this.props.postBy?.(this.state.selectedPostedBy) })
        break;
      case 'Experience':
        this.selectExperience(val.target.value)
        break;
      default:
        break;
    }
  }

  checkIndexAutoComplete = (index: number) => {
    switch (index) {
      case 1:
        return this.state.selectedCountry;
      case 2:
        return this.state.selectedCity;
      case 4:
        return this.state.selectedSkill;
    }
  }

  checkForResetFilter = () => {
    if (this.state.selectedSkill.length === 0 &&
      this.state.selectedExperiance.length === 0 &&
      this.state.selectedCountry === '' &&
      this.state.selectedCity === '' &&
      this.state.selectedDate === '' &&
      this.state.selectedPostedBy === '') 
    {
      this.setState({ disableButton: true }, () => { this.props.disableButton?.(this.state.disableButton) });
    }
  }

  clearAutoCompleteCheckBox = (index: number) => {
    switch (index) {
      case 1:
        this.setState({ selectedCountry: '', optionCity: [], selectedCity: "" }, () => this.checkForResetFilter());
        this.props.country?.('')
        break;
      case 2:
        this.setState({ selectedCity: '' }, () => this.checkForResetFilter());
        this.props.city?.('')
        break;
    }
  }

  clearState = () => {
    this.setState({
      selectedCountry: '',
      selectedCity: '',
      selectedDate: '',
      selectedExperiance: [],
      selectedExperianceCheck: [],
      selectedSkill: [],
      selectedPostedBy: '',
      disableButton: true
    }, () => { this.props.disableButton?.(this.state.disableButton) })
  }

  skillList = () => {
    const token = getLoginToken()
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      "token": token
    };
    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getSkillListApiCallId = getValidationsMsg.messageId;
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.skillsListApiEndPoint
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);

  }

  setStateFromProps = (selectedCountry: string, selectedCity: string, selectedDate: string, selectedExperiance: string[], selectedPostedBy: string, selectedSkill: string[], disableButton: boolean, selectedExperianceCheck: string[]) => {
    this.setState({
      selectedCountry,
      selectedCity,
      selectedDate,
      selectedExperiance,
      selectedPostedBy,
      selectedSkill,
      disableButton,
      selectedExperianceCheck
    })
  }
  
  // Customizable Area End
}
