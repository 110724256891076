import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import React from "react";
import { debounce } from "lodash";
import { toast } from "react-toastify";
import axios, { AxiosProgressEvent, AxiosRequestConfig } from 'axios';
import { buySideIcon, careerDevelopmentIcon, sellSideIcon } from "./assets";
import { getStorageData } from "../../../framework/src/Utilities";
import { ApexOptions } from "apexcharts";
import { ICountry, Country } from 'country-state-city';
import { downloadFile, getAllCountriesFromApi, getLocation, getUserRole, handleConditionFunction, handleScroll, isPhoneValidOrNot } from "../../../components/src/Utils.web";
import { CountryData } from "react-phone-input-2";

export interface ImageOrVideo {
    id: number;
    url: string;
}

type ScoreCategory = {
    user_absolute_score: number;
    user_percentile_score: number;
  };
  
  type ScoreAnalysis = {
    PersonalCredentialsScore: ScoreCategory;
    DealExperienceAndComplexityScore: ScoreCategory;
    MarketKnowledgeScore: ScoreCategory;
    NetworkingAndEndorsementScore: ScoreCategory;
    ProfessionalDevelopmentScore: ScoreCategory;
    CommunityEngagementScore: ScoreCategory;
  };

export interface UserDetails {
    id?: string,
    bio?: string,
    city?: string,
    email?: string,
    total_progress_percentage?: string,
    work_email_id?: string,
    personal_email_id?: string,
    experience?: string,
    full_name?: string,
    resume?: {
        url: string,
        name: string,
        size: number
    } | null,
    photo?: string,
    skills: [string][],
    cover_letter?: {
        url: string,
        name: string,
        size: number,
    } | null,
    full_phone_number?: string,
    open_for_direct_approach: boolean,
    work_experiences: {
        data: []
    },
    account_details?: {
        id?: number
    },
    current_title?: string
}

export interface WorkExperience {
    work_experience: {
        id: number,
        position: string,
        specialization: string,
        started_time: string,
        ended_time: string,
        company_name: string,
        account_id: number,
        created_at: string,
        updated_at: string,
        currently_working: boolean,
        end_current_position: boolean,
        profile_id: number
    },
    company_icon_url: string
}

export interface PostCommentState {
    open: boolean;
    images: File[];
    comment: string;
}

export interface Account {
    account_name: string;
    account_profile: string;
    account_id: string | number;
}

export interface ManagevalueDropdown {
    workExperience: string;
    dealExperience: string
}

export interface SelectedFile {
    file: File | null,
    sizeExceed: boolean,
    validFileType: boolean,
    fileUrl: null
}

export interface Activity {
    posts: [],
    comments: [],
    reactions: [],
}

export interface Reply {
    commentable_type: string;
    id: number;
    parent_id: number | null;
    commentable_id: number;
    created_at: string;
    comment: string;
    likes_count: number;
    updated_at: string;
    isliked: boolean;
    dislikes_count: number;
    isdisliked: boolean;
    comment_images: { id: number | string, url: string }[];
    account: Account;
    reply_to: number | null;
    reply_count: number;
    replies: Reply[];
}

export interface Comment {
    account_id: number;
    id: number;
    parent_id: number | null;
    commentable_id: number;
    commentable_type: string;
    created_at: string;
    comment: string;
    likes_count: number;
    updated_at: string;
    dislikes_count: number;
    isdisliked: boolean;
    isliked: boolean;
    comment_images: { id: number | string, url: string }[];
    reply_count: number;
    account: Account;
    replies: Reply[];
    reply_to: number | null;
}

export interface State {
    commentData: any;
    token: string;
    errorMsg: string;
    loading: boolean;
    comment: string;
    commentsArray: any;
    replyCommentId: any;
    currentPage: number;
    isInfiniteLoading: boolean;
    allPosts: Post[];
    showComment: boolean;
    postShowID: number | string;
}


export interface Post {
    id: string;
    type: string;
    attributes: {
        isdisliked: boolean;
        body: string;
        name: string;
        location: string;
        description: string;
        account_id: number;
        sub_topic: string;
        category_id: number;
        sub_category_id: string;
        updated_at: string;
        model_name: string;
        created_at: string;
        images: ImageOrVideo[];
        likes_count: number;
        isliked: boolean;
        id: number;
        dislikes_count: number;
        comment: Comment[];
        account: Account;
        comment_count: number;
    };
}

 export interface CurrentPost {
    id: string | number;
    description: string;
    images: any[];
    selectedTopicId: string
}

export interface DealExperienceAttributes {
    deal_type: string;
    involvement_type: string;
    transaction_side: string;
    target_name: string;
    buyer_investor_name: string;
    transaction_summary: string;
    enterprise_value: string;
    percentage_stake: string;
    valuation_method: string;
    valuation_multiple: string;
    debt_type: string;
    borrower_name: string;
    lender_name: string;
    debt_size: string;
    leverage_level: string;
    interest_rate: string;
    main_contributions: string[];
    transaction_merits: string;
    potential_risks: string;
    created_at: string;
}

export interface DealExperienceResponse {
    id: string;
    type: string;
    attributes: DealExperienceAttributes;
}

export interface ConfirmDialog {
    open: boolean,
    onConfirm?: () => void,
    title1: string,
    title2: string
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
}

export interface S {
    // Customizable Area Start
    dealExperienceEquityModal: boolean,
    chatBotDealFields: {
        type: string,
        involved: string,
        buyOrSell: string,
        targetName: string,
        buyerORInvestorName: string,
        briefSummory: string,
        enterPriceValue: string,
        stackPercentage: string,
        valuationMethod: string,
        valuationMultiple: string,
        mainContribution: string[],
        persPerspectiveMarite: string,
        riskPerspective: string,
        debtBorrowing: string,
        debtBorrowsideORLendingSide: string,
        debtTargetName: string,
        debtLenderName: string,
        debtSummory: string,
        debtUSD: string,
        debtGross: string,
        debtInterest: string
    },
    chatBotDealFieldsToShow: {
        showtype: boolean,
        showinvolved: boolean,
        showbuyOrSell: boolean,
        showtargetName: boolean,
        showbuyerORInvestorName: boolean,
        showbriefSummory: boolean,
        showenterPriceValue: boolean,
        showstackPercentage: boolean,
        showvaluationMethod: boolean,
        showvaluationMultiple: boolean,
        showmainContribution: boolean,
        showpersPerspectiveMarite: boolean,
        showriskPerspective: boolean,
        showdebtBorrowing: boolean,
        showdebtBorrowsideORLendingSide: boolean,
        showdebtTargetName: boolean,
        showdebtLenderName: boolean,
        showdebtSummory: boolean,
        showdebtUSD: boolean,
        showdebtGross: boolean,
        showdebtInterest: boolean
    },
    completeProfileModal: boolean,
    progressResume:number,
    remainingTimeResume:number,
    loaderResume:boolean,
    progressCover:number,
    remainingTimeCover:number,
    loaderCover:boolean,
    directApproachChecked: boolean,
    selectedFileResume: SelectedFile,
    selectedFileCoverLetter: SelectedFile,
    resumeFileInputRef: React.RefObject<HTMLInputElement>,
    coverLetterFileInputRef: React.RefObject<HTMLInputElement>,
    skills: string[],
    isResumeUploaded: boolean,
    isCoverLetterUploaded: boolean,
    activityType: string,
    openConfirmDialogNew: ConfirmDialog,
    userDetails: UserDetails & {
        total_candidates_count: number,
        total_potential_score: number,
        overall_rank: number,
        current_title: string
    },
    manageValue: ManagevalueDropdown,
    activity: Activity,
    updateActivityType: string,
    commentData: any;
    token: string;
    errorMsg: string;
    loading: boolean;
    comment: string;
    commentsArray: any;
    replyCommentId: any;
    currentPage: number;
    isInfiniteLoading: boolean;
    allPosts: Post[];
    showComment: boolean,
    postShowID: number | string;
    openEditFeedPost: boolean;
    currentPost: CurrentPost;
    imageList: []
    selectTopicsList: {
        [category: string]: {
          icon: string,
          topics: string[]
        };
    },
    showAllPosts: boolean
    skillsList: string[]
    selectedSkill: string
    postToDelete: string | number
    workExperienceData: WorkExperience[]
    isPostImageUploaded: boolean
    selectedSkillToRemove: string
    progressComment:number
    remainingTimeComment:number
    cities: {value: string, label: string}[],
    chatBotAnswers: {
        country: { value: string, label: string, cId: string } | null,
        city: { value: string, label: string } | null,
        contact: string,
        job_title: string,
        experience: string,
        current_salary: string,
        skills: string[],
        current_company: string,
        currency_value: string,
        tempSkill: string,
    },
    chatBotAnswersToShow: {
        showcountry: boolean,
        showcity: boolean,
        showcontact: boolean,
        showjob_title: boolean,
        showexperience: boolean,
        showcurrent_salary: boolean,
        showskills: boolean,
        showcurrent_company: boolean,
        showcurrency_value: boolean,
        showtempSkill: boolean,
        showContactNumberErrorOne: boolean
    },
    loaderButton: boolean,
    companyList: string[]
    loaderComment:boolean  
    dealExperienceTable: DealExperienceResponse[],
    isLastQuestionEntered: boolean,
    series: ApexOptions["series"],
    options:ApexOptions,
    series1: ApexOptions["series"],
    options1:ApexOptions,
    countries: string[],
    selectedCountry: string,
    expandedPosts: string[],
    countriesList: { name: string, isoCode: number }[]
    // Customizable Area End
}

export interface SS {
    // Customizable Area Start
    id: any;
    rows: { name: string, company: string, buyer: string, price: string }[];
    // Customizable Area End
}

export default class RecruiterProfileController extends BlockComponent<
    Props,
    S,
    SS
>{
    // Customizable Area Start
    userProfileGetApiCallId: string = ""
    addResumeApiCallId: string = ""
    addCoverLetterApiCallId: string = ""
    deleteResumeApiCallId: string = ""
    deleteCoverLetterApiCallId: string = ""
    updateDirectApproachApiCallId: string = ""
    deleteWorkExperienceApiCallId: string = ""
    getPostsApiCallId: string = ""
    getReactionPostsApiCallId: string = ""
    getCommentsPostsApiCallId: string = ""
    upvotePostApiCallId: string = ""
    downvotePostApiCallId: string = ""
    getPostDetailsApiId: string = ""
    getAllCommentsForApiId: string = ""
    createCommentApiCallID: string = ""
    upvotePostCommentApiCallId: string = ""
    downvotePostCommentApiCallId: string = ""
    deletePostApiId: string = ""
    savePostApiId: string = ""
    selectTopicsId: string = ""
    deleteSkillApiId: string = ""
    skillsListApiId: string = ""
    addSkillApiCallId: string = ""
    getWorkExperienceApiCallId: string = ""
    deleteCommentApiId: string = ""
    getCompaniesListApiId: Message | string = ""
    getDealExperienceApiId: string = ""
    deleteDealExperienceApiCallId: string = ""
    chatUserCandidateProfileUpdatApiID: string | Message = ""
    chatDebtUpdatApiID: string | Message = ""
    getGraphdataApiId: string = ""
    getGraphdataByCountryApiId: string = ""
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.state = {
            // Customizable Area Start
            dealExperienceEquityModal: false,
            chatBotDealFields: {
                type: "",
                involved: "",
                buyOrSell: "",
                targetName: "",
                buyerORInvestorName: "",
                briefSummory: "",
                enterPriceValue: "",
                stackPercentage: "",
                valuationMethod: "",
                valuationMultiple: "",
                mainContribution: [],
                persPerspectiveMarite: "",
                riskPerspective: "",
                debtBorrowing: "",
                debtBorrowsideORLendingSide: "",
                debtTargetName: "",
                debtLenderName: "",
                debtSummory: "",
                debtUSD: "",
                debtGross: "",
                debtInterest: ""
            },
            chatBotDealFieldsToShow: {
                showtype: false,
                showinvolved: false,
                showbuyOrSell: false,
                showtargetName: false,
                showbuyerORInvestorName: false,
                showbriefSummory: false,
                showenterPriceValue: false,
                showstackPercentage: false,
                showvaluationMethod: false,
                showvaluationMultiple: false,
                showmainContribution: false,
                showpersPerspectiveMarite: false,
                showriskPerspective: false,
                showdebtBorrowing: false,
                showdebtBorrowsideORLendingSide: false,
                showdebtTargetName: false,
                showdebtLenderName: false,
                showdebtSummory: false,
                showdebtUSD: false,
                showdebtGross: false,
                showdebtInterest: false
            },
            loaderButton: false,
            completeProfileModal: false,
            progressResume:0,
            remainingTimeResume:0,
            loaderResume:false,
            progressCover:0,
            remainingTimeCover:0,
            loaderCover:false,
            directApproachChecked: true,
            selectedFileResume: { file: null, sizeExceed: false, validFileType: true, fileUrl: null },
            selectedFileCoverLetter: { file: null, sizeExceed: false, validFileType: true, fileUrl: null },
            resumeFileInputRef: React.createRef(),
            coverLetterFileInputRef: React.createRef(),
            skills: ["Finance", "Economics", "Taxation"],
            activityType: "posts",
            openConfirmDialogNew: {
                open: false,
                title1: "",
                title2: ""
            },
            userDetails: {
                open_for_direct_approach: false,
                total_progress_percentage:"0",
                work_experiences: { data: [] },
                skills: [],
                total_candidates_count: 0,
                overall_rank: 0,
                total_potential_score: 0,
                current_title: ""
            },
            manageValue: {
                workExperience: "",
                dealExperience: ""
            },
            activity: {
                posts: [],
                comments: [],
                reactions: [],
            },
            updateActivityType: "posts",
            commentData: [],
            errorMsg: "",
            token: "",
            comment: "",
            commentsArray: [],
            replyCommentId: null,
            currentPage: 1,
            isInfiniteLoading: false,
            allPosts: [] as Post[],
            showComment: false,
            postShowID: Infinity,
            openEditFeedPost: false,
            currentPost: {
                id: '',
                description: '',
                images: [],
                selectedTopicId: ''
            },
            loading: false,
            imageList: [],
            selectTopicsList: this.topicsList,
            showAllPosts: false,
            skillsList: [],
            selectedSkill: "",
            postToDelete: "",
            workExperienceData: [],
            isPostImageUploaded: false,
            isResumeUploaded: false,
            isCoverLetterUploaded: false,
            selectedSkillToRemove: '',
            progressComment: 0,
            remainingTimeComment: 0,
            loaderComment: false,  
            cities: [],
            chatBotAnswers: {
                city: null,
                country: null,
                skills: [],
                current_company: "",
                current_salary: "",
                experience: "",
                contact: "",
                job_title: "",
                currency_value: "$",
                tempSkill: ""
            },
            chatBotAnswersToShow: {
                showcountry: false,
                showcity: false,
                showcontact: false,
                showjob_title: false,
                showexperience: false,
                showcurrent_salary: false,
                showskills: false,
                showcurrent_company: false,
                showcurrency_value: false,
                showtempSkill: false,
                showContactNumberErrorOne: false
            },
            companyList: [],
            dealExperienceTable: [],
            isLastQuestionEntered: false,
            series: configJSON.chartSeries,
            options: configJSON.chartOptions,
            series1: configJSON.chartSeries,
            options1: configJSON.chartOptions,
            countries: [],
            selectedCountry: "Compare me with users by",
            expandedPosts: [],
            countriesList: []
            // Customizable Area End
        };

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage)
        ];
        // Customizable Area End

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        const apiRequestCallId = message.getData(
            getName(MessageEnum.RestAPIResponceDataMessage)
        );
        let responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        if (apiRequestCallId && responseJson) {
            switch (apiRequestCallId) {
                case this.userProfileGetApiCallId:
                    window.dispatchEvent(new CustomEvent("USER_PROFILE_DETAILS", { detail: responseJson.data }))
                    return this.handleGetUserData(responseJson);
                case this.addCoverLetterApiCallId:
                    return this.handleAddCoverLetterData(responseJson)
                case this.addResumeApiCallId:
                    return this.handleAddResumeData(responseJson)
                case this.deleteResumeApiCallId:
                    return this.handleDeleteResumeResponse(responseJson)
                case this.deleteCoverLetterApiCallId:
                    return this.handleDeleteCoverLetterResponse(responseJson)
                case this.updateDirectApproachApiCallId:
                    return this.handleUpdateDirectApproachResponse(responseJson)
                case this.deleteWorkExperienceApiCallId:
                    return this.handleDeleteWorkExperienceResponse(responseJson)
                case this.getPostsApiCallId:
                    return this.handleGetPostsResponse(responseJson, "posts")
                case this.getReactionPostsApiCallId:
                    return this.handleGetPostsResponse(responseJson, "reactions")
                case this.getCommentsPostsApiCallId:
                    return this.handleGetPostsResponse(responseJson, "comments")
                case this.upvotePostApiCallId:
                    return this.handleLikePostResponse(responseJson)
                case this.downvotePostApiCallId:
                    return this.handleLikePostResponse(responseJson)
                case this.getPostDetailsApiId:
                    return this.handleLikePostResponse(responseJson)
                case this.savePostApiId:
                    return this.getAllUpdatedPosts()
                case this.upvotePostCommentApiCallId:
                    return this.handleUpvoteComment(responseJson)
                case this.downvotePostCommentApiCallId:
                    return this.handleUpvoteComment(responseJson)
                case this.getAllCommentsForApiId:
                    return this.handleAllComments(responseJson)
                case this.createCommentApiCallID:
                    return this.handleCreateComment(responseJson)
                case this.deletePostApiId:
                    return this.handleDeletePostResponse(responseJson)
                case this.skillsListApiId:
                    return this.handleSkillsResponse(responseJson)
                case this.addSkillApiCallId:
                    return this.handleSkillsUpdateResponse(responseJson)
                case this.deleteSkillApiId: 
                    return this.handleRemoveSkillResponse(responseJson)
                case this.getWorkExperienceApiCallId:
                    return this.handleGetWorkExperienceResponse(responseJson)
                case this.deleteCommentApiId: 
                    return this.getCommentsOfPost(this.state.postShowID)
                case this.getCompaniesListApiId:
                    this.setState({
                        companyList: responseJson.company_listing.map(((item: {name: string}) => item.name))
                    });
                return;
                case this.getDealExperienceApiId: 
                    return this.handleDealExperienceResponse(responseJson)
                case this.deleteDealExperienceApiCallId: 
                    return this.handleDeleteDealExperienceResponse(responseJson)
                case this.chatUserCandidateProfileUpdatApiID:
                    this.setState({
                        loaderButton: false,
                        chatBotAnswers: {
                            city: null,
                            country: null,
                            skills: [],
                            current_company: "",
                            current_salary: "",
                            experience: "",
                            contact: "",
                            job_title: "",
                            currency_value: "$",
                            tempSkill: ""
                        },
                        chatBotAnswersToShow: {
                            showcountry: false,
                            showcity: false,
                            showcontact: false,
                            showjob_title: false,
                            showexperience: false,
                            showcurrent_salary: false,
                            showskills: false,
                            showcurrent_company: false,
                            showcurrency_value: false,
                            showtempSkill: false,
                            showContactNumberErrorOne: false
                        },
                        completeProfileModal: false
                    });
                    this.getUserProfile();
                    return;
                case this.chatDebtUpdatApiID:
                    this.setState({
                        loaderButton: false
                    });
                    this.getDealExperience();
                    this.toggleDrawerEquity(false);
                    this.getUserProfile();
                    return;
                case this.getGraphdataApiId:
                    this.handleGraphData(responseJson.data)
                    return;
                case this.getGraphdataByCountryApiId:
                    this.handleGraphDataCountry(responseJson);
                    return;
                default:
                    return null
            }
        }
        // Customizable Area End
    }

    // Customizable Area Start

    handleGraphDataCountry = (response: {data? : {score_analysis: ScoreAnalysis}}) => {
        if(response.data){
            let xAxisData = [];
            let yAxisData = [];

            for(let data in response.data.score_analysis){
                xAxisData.push(data);
                yAxisData.push(response.data.score_analysis[data as keyof ScoreAnalysis].user_percentile_score)
            }
            this.setState({
                series1: [{data: yAxisData, name: ""}],
                options1: {
                    ...this.state.options1,
                    xaxis: {
                        ...this.state.options1.xaxis,
                        categories: xAxisData
                    }
                },
            })
        } else {
            this.setState({
                series1: configJSON.chartSeries,
                options1: configJSON.chartOptions,
            })
        }
    }

    handleGraphData = (data: {attributes: {percentile_score: number, name: string, absolute_score: string}}[]) => {
        let xAxisData = [];
        let yAxisData = [];
        for(let cat of data) {
            if(cat.attributes.name !== "Professional Development Score")
            yAxisData.push(cat.attributes.percentile_score);
            xAxisData.push(cat.attributes.name);
        }
        this.setState({
            series: [{data: yAxisData, name: ""}],
            options: {
                ...this.state.options,
                xaxis: {
                    ...this.state.options.xaxis,
                    categories: xAxisData
                }
            },
        })
    }

    handleSelectCountry = (country: string) => {
        this.setState({
            selectedCountry: country
        })
    }


    getGraphData = async() => {
        const authToken = await getStorageData("authToken");
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getGraphdataApiId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.getgraphDataApiEndPoint
        );

        const header = {
            "Content-Type": configJSON.contentTypeApiGetUserProfile,
            token: authToken
        };

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.methodTypeApiGetUserProfile
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }
    
    getGraphDataByCountry = async(country?: string) => {
        const authToken = await getStorageData("authToken");

        const getGraphDataByCountry = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getGraphdataByCountryApiId = getGraphDataByCountry.messageId;

        getGraphDataByCountry.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.getgraphDataByCountryApiEndPoint}?country=${country ?? this.state.selectedCountry}`
        );

        const header = {
            "Content-Type": configJSON.contentTypeApiGetUserProfile,
            token: authToken
        };

        getGraphDataByCountry.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        getGraphDataByCountry.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.methodTypeApiGetUserProfile
        );

        runEngine.sendMessage(getGraphDataByCountry.id, getGraphDataByCountry);
    }

    goToScoreCalculation = () => {
        const msg = new Message(getName(MessageEnum.NavigationMessage));
        msg.addData(getName(MessageEnum.NavigationTargetMessage), "Cfscorecalculations");
        msg.addData(getName(MessageEnum.NavigationPropsMessage),this.props);
        this.send(msg);
    }

    handleLeaderBoardNavigation = () => {
        const msg = new Message(getName(MessageEnum.NavigationMessage));
        msg.addData(getName(MessageEnum.NavigationTargetMessage), "LeaderBoard");
        msg.addData(getName(MessageEnum.NavigationPropsMessage),this.props);
        this.send(msg);
    }

    companyListHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        if (value) {
            this.getCompaniesList(value);
        }
    };

    getCompaniesList = debounce(async (value: string) => {
        const headers = {
            "Content-Type": configJSON.validationApiContentType,
            "token": await getStorageData("authToken")
        };
        let userData = { search_name: value || '' };



        const updateUserDetails = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getCompaniesListApiId = updateUserDetails.messageId;

        updateUserDetails.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.CompanyListApiEndPoint}`
        );

        updateUserDetails.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );

        updateUserDetails.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(userData)
        );

        updateUserDetails.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.postMethod
        );
        runEngine.sendMessage(updateUserDetails.id, updateUserDetails);
    }, 300)

    handleKeyDownFormOne = (name: string, value: boolean) => {
        this.setState({
            chatBotAnswersToShow: {
                ...this.state.chatBotAnswersToShow,
                [name]: value
            }
        });
        handleScroll()
    }

    handleKeyDownFormDeal = (name: string, value: boolean) => {
        this.setState({
            chatBotDealFieldsToShow: {
                ...this.state.chatBotDealFieldsToShow,
                [name]: value
            }
        });
        handleScroll()
    } 

    handleChangeChatBotField = (value: string, fieldName: string, cId: string = "", countryData?: CountryData | {} | undefined) => {
        switch (fieldName) {
            case "city":
                this.setState({
                    chatBotAnswers: {
                        ...this.state.chatBotAnswers,
                        city: { value: value, label: value }
                    },
                })
                handleScroll();
                return;
            case "country":
                this.setState({
                    chatBotAnswers: {
                        ...this.state.chatBotAnswers,
                        country: { value: value, label: value, cId: cId }
                    },
                })
                handleScroll();
                return;
            case "skills":
                this.setState({
                    chatBotAnswers: {
                        ...this.state.chatBotAnswers,
                        tempSkill: "",
                        skills: this.state.chatBotAnswers.skills.includes(value) ? [...this.state.chatBotAnswers.skills.filter(skill => skill !== value)] : [...this.state.chatBotAnswers.skills, value]
                    },
                })
                handleScroll()
                return;
            case "current_company":
                this.setState({
                    chatBotAnswers: {
                        ...this.state.chatBotAnswers,
                        current_company: value
                    },
                })
                return;
            case "current_salary":
                this.setState({
                    chatBotAnswers: {
                        ...this.state.chatBotAnswers,
                        current_salary: value
                    },
                })
                return;
            case "experience":
                this.setState({
                    chatBotAnswers: {
                        ...this.state.chatBotAnswers,
                        experience: value
                    },
                })
                handleScroll()
                return;
            case "contact":
                if (countryData && !isPhoneValidOrNot({ phone: value, country: countryData })) {
                    this.setState({
                        chatBotAnswersToShow: {
                            ...this.state.chatBotAnswersToShow,
                            showContactNumberErrorOne: true
                        }
                    })
                } else {
                    this.setState({
                        chatBotAnswersToShow: {
                            ...this.state.chatBotAnswersToShow,
                            showContactNumberErrorOne: false
                        }
                    }) 
                }
                this.setState({
                    chatBotAnswers: {
                        ...this.state.chatBotAnswers,
                        contact: value
                    },
                })
                return;
            case "job_title":
                this.setState({
                    chatBotAnswers: {
                        ...this.state.chatBotAnswers,
                        job_title: value
                    },
                })
                return;
            case "currency_value":
                this.setState({
                    chatBotAnswers: {
                        ...this.state.chatBotAnswers,
                        currency_value: value
                    },
                })
                return;
        }
    }

    handleTypeChange = (value: string) => {
        if(value === "Equity") {
            this.setState({
                chatBotDealFields: {
                    ...this.state.chatBotDealFields,
                    type: value,
                    debtBorrowing: "",
                    involved: "",
                    debtBorrowsideORLendingSide: "",
                    debtTargetName: "",
                    debtLenderName: "",
                    debtSummory: "",
                    debtUSD: "",
                    debtGross: "",
                    debtInterest: ""
                },
                isLastQuestionEntered: false
            })
        } else {
            this.setState({
                chatBotDealFields: {
                    ...this.state.chatBotDealFields,
                    type: value,
                    involved: "",
                    buyOrSell: "",
                    targetName: "",
                    buyerORInvestorName: "",
                    briefSummory: "",
                    enterPriceValue: "",
                    stackPercentage: "",
                    valuationMethod: "",
                    valuationMultiple: "",
                    mainContribution: [],
                    persPerspectiveMarite: "",
                    riskPerspective: "",
                },
                isLastQuestionEntered: false
            })
        }
    }

    handleChangeEquityModalField = (value: string, fieldName: string, cId: string = "") => {
        switch (fieldName) {
            case "type":
              this.handleTypeChange(value);
              handleScroll()
            return;
            case "involved":
                this.setState({
                    chatBotDealFields: {
                        ...this.state.chatBotDealFields,
                        involved: value
                    }
                })
                handleScroll()
            return;
            case "buyOrSell":
                this.setState({
                    chatBotDealFields: {
                        ...this.state.chatBotDealFields,
                        buyOrSell: value
                    }
                })
                handleScroll()
            return;
            case "targetName":
                this.setState({
                    chatBotDealFields: {
                        ...this.state.chatBotDealFields,
                        targetName: value
                    }
                })
            return;
            case "buyerORInvestorName":
                this.setState({
                    chatBotDealFields: {
                        ...this.state.chatBotDealFields,
                        buyerORInvestorName: value
                    }
                })
            return;
            case "briefSummory":
                this.setState({
                    chatBotDealFields: {
                        ...this.state.chatBotDealFields,
                        briefSummory: value
                    }
                })
                handleScroll()
            return;
            case "enterPriceValue":
                if(Number.isNaN(+value.replace(/,/g, ''))) {
                    return
                }
                this.setState({
                    chatBotDealFields: {
                        ...this.state.chatBotDealFields,
                        enterPriceValue: value.replace(/,/g, '')
                    }
                })
                handleScroll()
            return;
            case "stackPercentage":
                this.handleChangeStackPercentage(value)
            return;
            case "valuationMethod":
                this.setState({
                    chatBotDealFields: {
                        ...this.state.chatBotDealFields,
                        valuationMethod: value
                    }
                })
            return;
            case "valuationMultiple":
                if(Number.isNaN(+value)) {
                    return
                }
                this.setState({
                    chatBotDealFields: {
                        ...this.state.chatBotDealFields,
                        valuationMultiple: value
                    }
                })
            return;
            case "mainContribution":
                this.setState({
                    chatBotDealFields: {
                        ...this.state.chatBotDealFields,
                        mainContribution: this.state.chatBotDealFields.mainContribution.includes(value) ? [...this.state.chatBotDealFields.mainContribution.filter(skill => skill !== value)] 
                        : [...this.state.chatBotDealFields.mainContribution, value]
                    }
                })
            return;
            case "persPerspectiveMarite":
                this.setState({
                    chatBotDealFields: {
                        ...this.state.chatBotDealFields,
                        persPerspectiveMarite: value
                    }
                })
            return;
            case "riskPerspective":
                this.setState({
                    chatBotDealFields: {
                        ...this.state.chatBotDealFields,
                        riskPerspective: value
                    }
                })
            return;
            case "debtBorrowing":
                this.setState({
                    chatBotDealFields: {
                        ...this.state.chatBotDealFields,
                        debtBorrowing: value
                    }
                })
                handleScroll()
            return;
            case "debtBorrowsideORLendingSide":
                this.setState({
                    chatBotDealFields: {
                        ...this.state.chatBotDealFields,
                        debtBorrowsideORLendingSide: value
                    }
                })
            return;
            case "debtTargetName":
                this.setState({
                    chatBotDealFields: {
                        ...this.state.chatBotDealFields,
                        debtTargetName: value
                    }
                })
            return;
            case "debtLenderName":
                this.setState({
                    chatBotDealFields: {
                        ...this.state.chatBotDealFields,
                        debtLenderName: value
                    }
                })
            return;
            case "debtSummory":
                this.setState({
                    chatBotDealFields: {
                        ...this.state.chatBotDealFields,
                        debtSummory: value
                    }
                })
                handleScroll()
            return;
            case "debtUSD":
                this.handleDebtUSD(value);
            return;
            case "debtGross":
                this.handleChangedebtGross(value)
            return;
            case "debtInterest":
               this.handleChangeDebtInterest(value)
            return;
        }
    }

    handleDebtUSD = (value: string) => {
        if(Number.isNaN(+value.replace(/,/g, ''))) {
            return
        }
        this.setState({
            chatBotDealFields: {
                ...this.state.chatBotDealFields,
                debtUSD: value.replace(/,/g, '')
            }
        })
    }
    
    handleChangedebtGross = (value: string) => {
        if(Number.isNaN(+value)) {
            return
        }
        this.setState({
            chatBotDealFields: {
                ...this.state.chatBotDealFields,
                debtGross: value
            }
        })
    }

    handleChangeDebtInterest = (value: string) => {
        if(Number.isNaN(+value) || +value > 100 || +value < 0) {
            return
        }

        this.setState({
            chatBotDealFields: {
                ...this.state.chatBotDealFields,
                debtInterest: value
            }
        })
    }

    handleChangeStackPercentage = (value: string) => {
        if(Number.isNaN(+value) || +value > 100 || +value < 0) {
            return
        }
        this.setState({
            chatBotDealFields: {
                ...this.state.chatBotDealFields,
                stackPercentage: value
            }
        })
    }

    handleCitiesList = (value: { value: string, label: string }[]) => {
        this.setState({
            cities: value
        })
    }

    topicsList = {
        "Buy-Side" : {icon: buySideIcon, topics: ["Private Equity", "Growth Equity", "Venture Capital", "Infrastructure Investment", "Real Estate Investment", "Investor Relations and Capital Raising", "Corporate Development" ]},
        "Sell-Side" : {icon: sellSideIcon, topics: ["Investment Banking", "Equity Research", "Sales and Trading", "Market Analysis"]},
        "Career Development" : {icon: careerDevelopmentIcon, topics:["Breaking into Buy-side", "Breaking into Sell Side", "Career Advancement", "Work-Life Balance", "Networking and Mentorship"]}
      } 

    getAllUpdatedPosts = () => {
        this.getPosts("posts")
        this.getPosts("reactions")
        this.getPosts("comments")
    }

    toggleDrawer = (open: boolean) => {
        this.setState({
            completeProfileModal: open,
            chatBotAnswers: {
                city: null,
                country: null,
                skills: [],
                current_company: "",
                current_salary: "",
                experience: "",
                contact: "",
                job_title: "",
                currency_value: "$",
                tempSkill: ""
            },
            chatBotAnswersToShow: {
                showcountry: false,
                showcity: false,
                showcontact: false,
                showjob_title: false,
                showexperience: false,
                showcurrent_salary: false,
                showskills: false,
                showcurrent_company: false,
                showcurrency_value: false,
                showtempSkill: false,
                showContactNumberErrorOne: false
            }
        });
    };

    toggleDrawerEquity = (open: boolean) => {
        this.setState({ 
            dealExperienceEquityModal: open,
            chatBotDealFields: {
                type: "",
                involved: "",
                buyOrSell: "",
                targetName: "",
                buyerORInvestorName: "",
                briefSummory: "",
                enterPriceValue: "",
                stackPercentage: "",
                valuationMethod: "",
                valuationMultiple: "",
                mainContribution: [],
                persPerspectiveMarite: "",
                riskPerspective: "",
                debtBorrowing: "",
                debtBorrowsideORLendingSide: "",
                debtTargetName: "",
                debtLenderName: "",
                debtSummory: "",
                debtUSD: "",
                debtGross: "",
                debtInterest: ""
            },
            chatBotDealFieldsToShow: {
                showtype: false,
                showinvolved: false,
                showbuyOrSell: false,
                showtargetName: false,
                showbuyerORInvestorName: false,
                showbriefSummory: false,
                showenterPriceValue: false,
                showstackPercentage: false,
                showvaluationMethod: false,
                showvaluationMultiple: false,
                showmainContribution: false,
                showpersPerspectiveMarite: false,
                showriskPerspective: false,
                showdebtBorrowing: false,
                showdebtBorrowsideORLendingSide: false,
                showdebtTargetName: false,
                showdebtLenderName: false,
                showdebtSummory: false,
                showdebtUSD: false,
                showdebtGross: false,
                showdebtInterest: false
            }
         });
    };

    handleLastQuestion = (value: boolean) => {
        this.setState({
            isLastQuestionEntered: value
        })
    }

    shouldRender = (condition: boolean) => !!(condition && this.state.chatBotDealFields.riskPerspective && this.state.isLastQuestionEntered)

    componentDidMount = async () => {
        let data = await getAllCountriesFromApi()
        const containerDiv = document.getElementById('pageContainer')
        if (containerDiv) {
            containerDiv.scrollIntoView();
        }
        this.setState({
            countriesList: data,
            countries: data.map((country: {name: string, isoCode: number}) => country.name)
        })
        this.getWorkExperience()
        this.getUserProfile()
        this.getAllUpdatedPosts()
        this.selectTopicsHandler()
        this.handleInputChangeDebounced()
        this.getDealExperience()
        this.getGraphData()
    }

    componentDidUpdate = async (prevProps: Readonly<Props>, prevState: Readonly<S>) => {
         if(prevState.selectedCountry !== this.state.selectedCountry) {
            this.getGraphDataByCountry();
         }
    }

    handleDealExperienceResponse = (responseJson: { deal_experiences: {data: DealExperienceResponse[]} }) => {
        if(responseJson.deal_experiences.data) {
            this.setState({
                dealExperienceTable : responseJson.deal_experiences.data
            })
        }
    }

    formatDate = (dateStringValue: string) => {
        const dateValue = new Date(dateStringValue);
        const monthValue = dateValue.toLocaleString('default', { month: 'short' });
        const yearValue = dateValue.getFullYear();
        return `${monthValue} ${yearValue}`;
    }

    handleCondition = (condition: boolean, truePart: number, falsePart: number) => {
        return condition ? truePart : falsePart
    }

    handleShowAllPostsCall = () => {
        const pageContainerDiv = document.getElementById("pageContainer")
        if (pageContainerDiv) {
            pageContainerDiv.scrollIntoView();
        }

        this.setState({
            showAllPosts: !this.state.showAllPosts
        })
    }

    handleGetUserData = (response: any) => {
        if (!response.errors) {
            this.setState({
                userDetails: response.data.attributes,
                selectedCountry: response.data.attributes.country
            })
            this.getGraphDataByCountry(response.data.attributes.country)
        }
        else if (response.errors && response.errors[0]?.token === "Token has Expired" || response.errors[0]?.token === "Invalid token") {
            toast.error(response.errors[0]?.token)
            localStorage.removeItem("authToken");
            localStorage.removeItem("userRole");
            this.props.navigation.navigate("/")
        }
    }

    getInitials = (input?: string) => {
        if (input) {
            const words = input.trim().split(' ');
            const initials = words.map(word => word[0].toUpperCase()).join('');
            return initials;
        } else return ''
    }

    openResumeDeleteDialog = () => {
        this.setState({
            openConfirmDialogNew: {
                open: true,
                onConfirm: this.handleDeleteResume,
                title1: "Are you sure you want to delete this",
                title2: "resume?"
            }
        })
    }

    openCoverLetterDeleteDialog = () => {
        this.setState({
            openConfirmDialogNew: {
                open: true,
                onConfirm: this.handleDeleteCoverLetter,
                title1: "Are you sure you want to delete this",
                title2: "cover letter?"
            }
        })
    }

    openWorkExperienceDeleteDialog = (workExperienceId: number) => {
        this.setState({
            openConfirmDialogNew: {
                open: true,
                onConfirm: () => this.handleDeleteWorkExperience(workExperienceId),
                title1: "Are you sure you want to delete this",
                title2: "work experience?"
            }
        })
    }

    openDealExperienceDeleteDialog = (workExperienceId: number) => {
        this.setState({
            openConfirmDialogNew: {
                open: true,
                onConfirm: () => this.handleDeleteDealExperience(workExperienceId),
                title1: "Are you sure you want to delete this",
                title2: "deal experience?"
            }
        })
    }

    postContentHandler = (event: any) => {
        this.setState({
            currentPost: {
                ...this.state.currentPost,
                description: event.target.value
            }
        })
    }

    handleDeleteImage = (index: number) => {
        const updatedImages: any = this.state.currentPost.images ?
            this.state.currentPost?.images.filter((postImage, imageIndex) => imageIndex !== index) :
            [];

        this.setState({
            currentPost: {
                ...this.state.currentPost,
                images: updatedImages,
            }
        });
    };

    handleStateUpdate = ()  => {}

    selectTopicsValue = (event: any) => {
        const { value } = event.target
        this.setState({
            currentPost: {
                ...this.state.currentPost,
                selectedTopicId: value
            }
        })
    }


    handleFeedImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files) {
            const files: any = [
                ...this.state.currentPost.images,
                ...Array.from(event.target.files)
            ];
            const imageList: any = [...this.state.imageList, ...Array.from(event.target.files)];
            this.setState({
                currentPost: {
                    ...this.state.currentPost,
                    images: files,
                },
                imageList: imageList
            });
        }
        this.setState({ isPostImageUploaded: true })
    };

    handleFeedModalOpen = (post?: Post) => {
        if (this.state.openEditFeedPost) {
            this.setState({
                openEditFeedPost: !this.state.openEditFeedPost,
                currentPost: {
                    id: '',
                    description: '',
                    images: [],
                    selectedTopicId: ''
                }
            })
        } else if (post) {
            this.setState({
                openEditFeedPost: !this.state.openEditFeedPost,
                currentPost: {
                    description: post.attributes.body ?? '',
                    images: post.attributes.images ?? '',
                    id: post.attributes.id ?? '',
                    selectedTopicId: post.attributes.sub_topic ?? ''
                }
            })
        }
    }

    openPostDeleteDialog = (postId: number | string) => {
        this.setState({
            openConfirmDialogNew: {
                open: true,
                onConfirm: () => this.handleDeletePostApi(postId),
                title1: "Are you sure you want to delete this",
                title2: "post?"
            }
        })
    }

    handleAddResumeData = (response: any) => {
        if (!response.errors) {
            const resumeUrl = response?.profile?.data?.attributes?.resume?.url
            this.setState({
                selectedFileResume: { ...this.state.selectedFileResume, fileUrl: resumeUrl }
            })
        }
    }

    handleAddCoverLetterData = (response: any) => {
        if (!response.errors) {
            const resumeUrl = response?.profile?.data?.attributes?.cover_letter?.url
            this.setState({
                selectedFileCoverLetter: { ...this.state.selectedFileCoverLetter, fileUrl: resumeUrl }
            })
        }
    }

    handleDeleteResumeResponse = (response: any) => {
        if (!response.errors) {
            toast.success("Resume deleted successfully")
            this.setState({
                openConfirmDialogNew: {
                    open: false,
                    title1: "",
                    title2: "",
                },
                selectedFileResume: { file: null, sizeExceed: false, validFileType: true, fileUrl: null },
                userDetails: { ...this.state.userDetails, resume: null },
            })
        }
    }

    handleDeleteCoverLetterResponse = (response: any) => {
        if (!response.errors) {
            toast.success("Cover letter deleted successfully")
            this.setState({
                openConfirmDialogNew: {
                    open: false,
                    title1: "",
                    title2: "",
                },
                selectedFileCoverLetter: { file: null, sizeExceed: false, validFileType: true, fileUrl: null },
                userDetails: { ...this.state.userDetails, cover_letter: null },
            })
        }
    }

    handleUpdateDirectApproachResponse = (response: any) => {
        if (!response.errors) {
            this.getUserProfile()
        }
    }

    handleDeleteWorkExperienceResponse = (response: any) => {
        if(!response.error) {
            toast.success(response.message)
            this.getWorkExperience()
            this.getUserProfile()
        } else {
            toast.error(response.error)
        }
    }

    handleDeleteDealExperienceResponse = (response: {error: string} | {message: string}) => {
        if("message" in response) {
            toast.success(response.message)
            this.getDealExperience()
            this.getUserProfile()
        } else {
            toast.error(response.error)
        }
    }

    handleSkillsResponse = (responseJson: {skills: [{name: string}] }) => {
        if (responseJson.skills) {
            this.setState(() => ({ skillsList: responseJson.skills.map((value) => value.name ) }));
        }
    }

    handleSkillsUpdateResponse = (responseJson: any) => {
        if (!responseJson.errors) {
            this.getGraphData()
            this.getUserProfile()
            this.setState({
                userDetails: {
                    ...this.state.userDetails,
                    skills: [...this.state.userDetails.skills, [this.state.selectedSkill]],
                },
                skillsList: this.state.skillsList.filter((value: string) => !this.isSkillsExist(value)),
                selectedSkill: ''
            })
        } else {
            toast.error(responseJson.errors);
        }
    }

    handleRemoveSkillResponse = (responseJson: {errors: string}) => {
        if (!responseJson.errors) {
            this.getGraphData()
            this.getUserProfile()
            let skills = [...this.state.userDetails.skills]
            skills = skills.filter((value: string[] | string) => value[0] !== this.state.selectedSkill && value !== this.state.selectedSkill)
            

            this.setState({
                userDetails: {
                    ...this.state.userDetails,
                    skills: [...skills],
                },
                skillsList: [...this.state.skillsList, this.state.selectedSkill],
                selectedSkill: ''
            })
        } else {
            toast.error(responseJson.errors);
        }
    }

    handleGetWorkExperienceResponse = (responseJson: any) => {
        if (!responseJson.errors) {
            this.setState({
                workExperienceData: responseJson?.work_experiences,
            })
        }
    }

    isSkillsExist = (value: string) => {
        if (this.state.userDetails?.skills.length > 0) {
            const flattedArray = this.state.userDetails?.skills.flatMap(skills => Array.isArray(skills) ? skills.map(item => item.toString()) : skills);
            return flattedArray.includes(value)
        } else 
            return false
    }


    handleGetPostsResponse = (response: any, activityType: string) => {
        if (!response.errors) {
            this.setState({
                activity:
                {
                    ...this.state.activity,
                    [activityType]: response?.data
                }
            })
        }
    }

    handleDeletePostResponse = (response: any) => {
        if (!response.errors) {
            this.setState({
                openConfirmDialogNew: {
                    open: false,
                    title1: "",
                    title2: "",
                },
            })
            let allActivity = this.state.activity;
            let key: "posts" | "comments" | "reactions"
            for (key in allActivity) {
                if (allActivity.hasOwnProperty(key)) {
                    const arr: any = allActivity[key];
                    for (const item of arr) {
                        if (item.attributes.id === this.state.postToDelete) {
                            item.attributes.deleted = true;
                        }
                    }
                }
            }
            this.setState({
                activity: { ...allActivity }
            })
        }
    }

    handleLikePostResponse = (response: any) => {
        if (!response.errors) {
            this.setState({
                openConfirmDialogNew: {
                    open: false,
                    title1: "",
                    title2: "",
                },
            })
            this.getAllUpdatedPosts()
        }
    }

    handleCreateComment = (response: any) => {
        if (!response.errors) {
            this.getCommentsOfPost(this.state.postShowID);
            this.getAllUpdatedPosts()
        }
    }

    toggleComments = (post: any) => {
        this.getCommentsOfPost(post.id);
        this.setState({ showComment: !this.state.showComment, postShowID: post.id !== this.state.postShowID ? post.id : "" });
    };

    handleUpvoteComment = (post: any) => {
        this.getCommentsOfPost(this.state.postShowID);
    };


    handleAllComments = (responseJson: any) => {
        const activityType = this.state.activityType as "posts" | "comments" | "reactions"
        if(responseJson?.data) {
            this.setState({
                activity: {
                    ...this.state.activity,
                    [activityType] : this.state.activity[activityType].map((post: Post) => {
                        return post.attributes.id === parseInt(this.state.postShowID as string) ? { ...post, attributes: { ...post.attributes, comment: responseJson.data, comment_count: responseJson.data.length }, } : post
                    })
                }
              })
          }
    }

    onFileChange = (value: string, event: React.ChangeEvent<HTMLInputElement> | null) => {
        const file = event?.target?.files ? event.target.files[0] : null
        const allowedTypes = [
            "application/pdf", 
            "application/msword", 
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
        ];

        if (value === "selectedFileResume") {
            if (file && !allowedTypes.includes(file.type)) {
                this.setState({
                    selectedFileResume: { ...this.state.selectedFileResume, validFileType: false, },
                });
            }
            else if ((file!.size / 1000) <= 5120) {
                this.setState({
                    isResumeUploaded: true
                });
                this.setState({
                    selectedFileResume: { ...this.state.selectedFileResume, file: event?.target?.files ? event.target.files[0] : null },
                }, this.addResume);

            } else {
                this.setState({
                    selectedFileResume: { ...this.state.selectedFileResume, sizeExceed: true },
                });
            }
        } else if (file && !allowedTypes.includes(file.type)) {
            this.setState({
                selectedFileCoverLetter: { ...this.state.selectedFileCoverLetter, validFileType: false },
            });
        }
        else if (file && ((file.size / 1000) <= 5120)) {
            this.setState({
                isCoverLetterUploaded: true
            });
            this.setState({
                selectedFileCoverLetter: { ...this.state.selectedFileCoverLetter, file: event?.target?.files ? event.target.files[0] : null },
            }, this.addCoverLetter);
        } else {
            this.setState({
                selectedFileCoverLetter: { ...this.state.selectedFileCoverLetter, sizeExceed: true },
            });
        }
    };

    handleDownloadResume = () => {
        const resume = this.state.selectedFileResume ?? this.state.userDetails.resume
        const resumeUrl = this.state.selectedFileResume.fileUrl ?? this.state.userDetails.resume?.url
        const fileName = this.state.selectedFileResume.file?.name ?? this.state.userDetails?.resume?.name

        if (resumeUrl) {
            downloadFile(resume, fileName || "resume", resumeUrl)
        } else {
            toast.error("File url not found")
        }

    }

    handleDownloadCoverLetter = () => {
        const coverLetter = this.state.selectedFileCoverLetter ?? this.state.userDetails.cover_letter
        const coverLetterUrl = this.state.selectedFileCoverLetter.fileUrl ?? this.state.userDetails.cover_letter?.url
        const fileName = this.state.selectedFileCoverLetter.file?.name ?? this.state.userDetails?.cover_letter?.name

        if (coverLetterUrl) {
            downloadFile(coverLetter, fileName || "coverLetter", coverLetterUrl)
        } else {
            toast.error("File url not found")
        }
    }

    handleOnResumeClick = () => {
        if (this.state.resumeFileInputRef)
            this.state.resumeFileInputRef?.current?.click();
    }

    handleOnCoverLetterClick = () => {
        if (this.state.coverLetterFileInputRef)
            this.state.coverLetterFileInputRef?.current?.click();
    }

    formatBytesToKB = (value?: number) => {
        return value ? (value / 1000).toFixed(2) : 0;
    };

    handleNavigateEditWorkExperience = (experienceId: string | number) => {
        this.props.navigation.navigate("EditWorkExperience", { workExperienceId: experienceId }, scrollTo(0, 0))
    }

    handleUpdateProfile = () => {
        const msg = new Message(getName(MessageEnum.NavigationMessage));
        msg.addData(getName(MessageEnum.NavigationTargetMessage), "EditProfile");
        msg.addData(getName(MessageEnum.NavigationPropsMessage),this.props);
        this.send(msg);
    }

    handleNavigateEditDealExperience = (experienceId: string | number) => {
        const message: Message = new Message(getName(MessageEnum.NavigationMessage));
        message.addData(getName(MessageEnum.NavigationTargetMessage), 'EditDealExperience');
        message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        message.addData(getName(MessageEnum.NavigationScreenNameMessage), experienceId);
        this.send(message);
    } 

    handleClose = () => {
        this.setState({
            openConfirmDialogNew: {
                open: false,
                title1: "",
                title2: "",
            },
        })
    }

    handleUpdateActivityType = (type: string) => {
        this.setState({
            showComment: !this.state.showComment,
            postShowID: "",
            activityType: type
        })
    }

    handleDeleteCoverLetter = () => {
        this.deleteFile("cover_letter")
    }

    handleDeleteResume = () => {
        this.deleteFile("resume")
    }

    handleDirectApproachChange = () => {
        this.updateDirectApproach(!this.state.userDetails.open_for_direct_approach)
    }

    handleDeleteWorkExperience = (experienceId: number) => {
        this.deleteWorkExperience(experienceId)
        this.setState({
            openConfirmDialogNew: {
                open: false,
                title1: "",
                title2: "",
            },
        })
    }

    handleDeleteDealExperience = (experienceId: number) => {
        this.deleteDealExperience(experienceId)
        this.setState({
            openConfirmDialogNew: {
                open: false,
                title1: "",
                title2: "",
            },
        })
    }

    manageDealExperience = (value: string) => {
        this.setState({
            manageValue: {
                ...this.state.manageValue,
                dealExperience: value
            }
        })
        if (value === "Add") {
            const msg = new Message(getName(MessageEnum.NavigationMessage));
            msg.addData(getName(MessageEnum.NavigationTargetMessage), "JobApplicantProfile");
            msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
            this.send(msg);
        }
    }

    addSkillApi(skill: string) {
        this.setState({
            selectedSkill: skill
        })
        const skillObject = {
            "skill_name": skill
        }

        const authToken = localStorage.getItem("authToken")
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.addSkillApiCallId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.addNewSkillApi
        );

        const header = {
            token: authToken,
            "Content-Type": configJSON.validationApiContentType,
        };

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.postMethod
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(skillObject)
        )

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    manageWorkExperience = (value: string) => {
        this.setState({
            manageValue: {
                ...this.state.manageValue,
                workExperience: value
            }
        })
        if (value === "Add") {
            const message: Message = new Message(getName(MessageEnum.NavigationMessage));
            message.addData(getName(MessageEnum.NavigationTargetMessage), `AddWorkExperience`);
            message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
            this.send(message);
        } else if (value === "addWithBackRedirection") {
            const message: Message = new Message(getName(MessageEnum.NavigationMessage));
            message.addData(getName(MessageEnum.NavigationTargetMessage), `AddWorkExperience`);
            message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);

            const payloadMessage: Message = new Message(getName(MessageEnum.NavigationPayLoadMessage))
            payloadMessage.addData(getName(MessageEnum.NavigationPayLoadMessage), { backRedirection: true })
            message.addData(getName(MessageEnum.NavigationRaiseMessage), payloadMessage);

            this.send(message);
        }
    }

    skillsListHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        if (value) {
            this.handleInputChangeDebounced();
        }
    };

    toggleReadMore = (postId: string) => {
        this.setState((prevState) => {
          const { expandedPosts } = prevState;
          const isPostExpanded = expandedPosts.includes(postId);
    
          return {
            expandedPosts: isPostExpanded
              ? expandedPosts.filter(id => id !== postId)
              : [...expandedPosts, postId],
          };
        });
      };

    handleUserNavigation = (accountId: number | string) => {
        const message: Message = new Message(getName(MessageEnum.NavigationMessage));
        message.addData(getName(MessageEnum.NavigationTargetMessage), accountId === this.state.userDetails?.account_details?.id ? this.getProfileUrl() : 'Profile');
        message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        message.addData(getName(MessageEnum.NavigationScreenNameMessage), accountId);
        this.send(message);
    }

    getProfileUrl = () => {
        const userRole = getUserRole()
        return handleConditionFunction(userRole === "recruiter", "RecruiterProfile", 'JobApplicantProfile')
    }

    createComment = async (comment: string = "", files: File[] = [], nested: boolean = false, commentId: number | string = "", postID: number | string = "", activityType: string = "posts") => {
        const authToken = await getStorageData("authToken")
        const startTime = Date.now();
        if (files.length > 0) {
          this.setState({ 
            loaderComment: true,
            updateActivityType: activityType 
        })
        }

        let commentData = new FormData();
        commentData.append("comment[commentable_id]", `${commentId}`);
        commentData.append("comment[commentable_type]", !nested ? "BxBlockPosts::Post" : "BxBlockComments::Comment");
        commentData.append("comment[comment]", `${comment}`);
        commentData.append("comment[post_id]", `${postID}`);
        files.forEach((file, index) => {
            commentData.append(`comment[comment_images][]`, file);
        });

        const config: AxiosRequestConfig = {
            headers: { token: authToken ?? '' },
            onUploadProgress: (progressEvent: AxiosProgressEvent) => {
              const { loaded, total } = progressEvent;
              if (total) {
                const percentCompleted = Math.round((loaded * 100) / total);
        
                const currentTime = Date.now();
                const elapsedTime = (currentTime - startTime) / 1000;
        
                const uploadSpeed = loaded / elapsedTime;
        
                const remainingTime = (total - loaded) / uploadSpeed;
        
                this.setState({ progressComment: percentCompleted, remainingTimeComment: remainingTime })
              }
            }
          };
        
          axios.post(`${configJSON.baseURL.baseURL}/${configJSON.getCommentsEndPoint}`, commentData, config)
            .then(() => {
              this.getCommentsOfPost(this.state.postShowID);
            })
            .catch(() => {
            }).finally(() => {
              this.setState({
                loaderComment: false, 
                progressComment: 0, 
                remainingTimeComment: 0
              })
        
            });
    }



    handleSavePostApi = (postId: number | string, isSaved?: boolean) => {
        const authToken = localStorage.getItem("authToken")

        const header = {
            "Content-Type": configJSON.validationApiContentType,
            "token": authToken
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.savePostApiId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            isSaved ? `${configJSON.savePostApi}/${postId}/unsave_post` : `${configJSON.savePostApi}/${postId}/save_post`
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            isSaved ? configJSON.deleteMethod : configJSON.postMethod
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    createFeedPostApi = async () => {
        const startTime = Date.now();
        const authToken = await getStorageData("authToken")
        let URL = '';

        await getLocation()
        .then((position) => {
            URL += `?latitude=${position.coords.latitude}&longitude=${position.coords.longitude}`;
        }).catch(() => {})

        this.setState({ loading: true });
        let formData = new FormData();
        formData.append("data[attributes][name]", '');
        formData.append("data[attributes][description]", "");
        formData.append("data[attributes][location]", "");
        formData.append("data[attributes][name]", "");
        formData.append("data[attributes][body]", this.state.currentPost.description);
        formData.append("data[attributes][sub_topic]", this.state.currentPost.selectedTopicId);
        this.state.currentPost.images.forEach((file: File | { id: number | string, url: string }) => {
            if ('url' in file) {
                formData.append(`data[attributes][images][]`, file.url);
            } else {
                formData.append(`data[attributes][images][]`, file);
                    this.setState({ loaderComment: true })
            }
        });

        const config1: AxiosRequestConfig = {
            headers: { token: authToken ?? '' },
            onUploadProgress: (progressEvent: AxiosProgressEvent) => {
                const { loaded, total } = progressEvent;
                if (total) {
                    const percentCompleted1 = Math.round((loaded * 100) / total);

                    const currentTime1 = Date.now();
                    const elapsedTime = (currentTime1 - startTime) / 1000;

                    const uploadSpeed1 = loaded / elapsedTime;

                    const remainingTime1 = (total - loaded) / uploadSpeed1;

                    this.setState({ progressComment: percentCompleted1, remainingTimeComment: remainingTime1 })
                }
            }
        };

        axios.put(`${configJSON.baseURL.baseURL}/${configJSON.editPostApi}/${this.state.currentPost.id}${URL}`, formData, config1)
            .then((response : {data: {data: {}}}) => {
                if (response.data?.data) {
                    this.getAllUpdatedPosts()
                }
            })
            .catch(() => {
            })
            .finally(() => {
                this.setState({
                    loaderComment: false,
                    loading: false,
                    openEditFeedPost: false,
                    currentPost: {
                        id: '',
                        description: '',
                        images: [],
                        selectedTopicId: ''
                    }
                })
            });
    }

    handleDeletePostApi = (postId: number | string) => {
        const authToken = localStorage.getItem("authToken")
        this.setState({
            postToDelete: postId
        })
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            "token": authToken
        };

        const deletePost = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.deletePostApiId = deletePost.messageId;
        deletePost.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.deletePostApi}/${postId}`
        );

        deletePost.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        deletePost.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.deleteMethod
        );

        runEngine.sendMessage(deletePost.id, deletePost);
    }

    updateUserDetailsFromChat = async () => {
        const headers = {
            "token": await getStorageData("authToken")
          };
          this.setState({
            loaderButton: true
          })
          const updateCandidateUserProfile = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
          );
          this.chatUserCandidateProfileUpdatApiID = updateCandidateUserProfile.messageId;
      
          let formData = new FormData();
      
          formData.append("candidate_profile[api_name]", "candidate_chatbot");
          formData.append("candidate_profile[country]", (this.state.chatBotAnswers.country as {
            value: string;
            label: string;
            cId: string;
          }).value);
          formData.append("candidate_profile[city]", (this.state.chatBotAnswers.city as {
            value: string;
            label: string;
          }).value);
          formData.append("candidate_profile[full_phone_number]", this.state.chatBotAnswers.contact);
          formData.append("candidate_profile[current_title]", this.state.chatBotAnswers.job_title);
          formData.append("candidate_profile[experience]", this.state.chatBotAnswers.experience);
          formData.append("candidate_profile[salary]", this.state.chatBotAnswers.current_salary);
          formData.append("candidate_profile[current_company]", this.state.chatBotAnswers.current_company);
          formData.append("candidate_profile[open_for_direct_approach]", "false");
          for(let skill of this.state.chatBotAnswers.skills){
              formData.append("candidate_profile[skills][]", skill);
          }
        
      
          updateCandidateUserProfile.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.candidateProfileApiEndPoint            
          );
      
          updateCandidateUserProfile.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
          );
      
          updateCandidateUserProfile.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            formData
          );
      
          updateCandidateUserProfile.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.putMethod
          );
          runEngine.sendMessage(updateCandidateUserProfile.id, updateCandidateUserProfile);
      
    }

    addDealDetailsFromChat = async () => {
        
        const headers = {
            "token": await getStorageData("authToken")
        };
        
        this.setState({
            loaderButton: true
        });

        const updateDebtOrEquityApi = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.chatDebtUpdatApiID = updateDebtOrEquityApi.messageId;

        let URL: string = "";
        let formData = new FormData();
        formData.append("deal_experience[deal_type]", this.state.chatBotDealFields.type.toLowerCase());
        formData.append("deal_experience[involvement_type]", this.state.chatBotDealFields.involved);
        for(let value of this.state.chatBotDealFields.mainContribution) {
            formData.append("deal_experience[main_contributions][]", value);
        }
        formData.append("deal_experience[transaction_merits]", this.state.chatBotDealFields.persPerspectiveMarite);
        formData.append("deal_experience[potential_risks]", this.state.chatBotDealFields.riskPerspective);
        if(this.state.chatBotDealFields.type ==="Equity") {
            URL= configJSON.dealEquityChatBotApiEndPoint;
            formData.append("deal_experience[transaction_side]", this.state.chatBotDealFields.buyOrSell);
            formData.append("deal_experience[target_name]", this.state.chatBotDealFields.targetName);
            formData.append("deal_experience[buyer_investor_name]", this.state.chatBotDealFields.buyerORInvestorName);
            formData.append("deal_experience[transaction_summary]", this.state.chatBotDealFields.briefSummory);
            formData.append("deal_experience[enterprise_value]", this.state.chatBotDealFields.enterPriceValue);
            formData.append("deal_experience[percentage_stake]", this.state.chatBotDealFields.stackPercentage);
            formData.append("deal_experience[valuation_method]", this.state.chatBotDealFields.valuationMethod);
            formData.append("deal_experience[valuation_multiple]", this.state.chatBotDealFields.valuationMultiple);
        } else {
            URL= configJSON.dealDebtChatBotApiEndPoint;
            formData.append("deal_experience[debt_type]",  this.state.chatBotDealFields.debtBorrowing );
            formData.append("deal_experience[transaction_side]",  this.state.chatBotDealFields.debtBorrowsideORLendingSide );
            formData.append("deal_experience[borrower_name]",  this.state.chatBotDealFields.debtTargetName );
            formData.append("deal_experience[lender_name]",  this.state.chatBotDealFields.debtLenderName );
            formData.append("deal_experience[transaction_summary]",  this.state.chatBotDealFields.debtSummory );
            formData.append("deal_experience[debt_size]",  this.state.chatBotDealFields.debtUSD );
            formData.append("deal_experience[leverage_level]",  this.state.chatBotDealFields.debtGross );
            formData.append("deal_experience[interest_rate]",  this.state.chatBotDealFields.debtInterest );
        }

          updateDebtOrEquityApi.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            URL           
          );
      
          updateDebtOrEquityApi.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
          );
      
          updateDebtOrEquityApi.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            formData
          );
      
          updateDebtOrEquityApi.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.postMethod
          );
          runEngine.sendMessage(updateDebtOrEquityApi.id, updateDebtOrEquityApi);
      
    }

    getUserProfile() {
        const authToken = localStorage.getItem("authToken")
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.userProfileGetApiCallId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.updateUserDetailsEndPoint
        );

        const header = {
            "Content-Type": configJSON.contentTypeApiGetUserProfile,
            token: authToken
        };

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.methodTypeApiGetUserProfile
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    addResume() {
        const authToken = localStorage.getItem("authToken");
        const startTime = Date.now();
        this.setState({loaderResume:true})

        const formData = new FormData();
        formData.append('resume', this.state.selectedFileResume.file!);

        const config: AxiosRequestConfig = {
            headers: { token: authToken ?? '' },
            onUploadProgress: (progressEvent: AxiosProgressEvent) => {
              const { loaded, total } = progressEvent;
              if (total) {
                const percentCompleted = Math.round((loaded * 100) / total);
              
              const currentTime = Date.now();
              const elapsedTime = (currentTime - startTime) / 1000;
      
              const uploadSpeed = loaded / elapsedTime;
      
              const remainingTime = (total - loaded) / uploadSpeed;
      
              this.setState({progressResume:percentCompleted,remainingTimeResume:remainingTime})
              }
            }
          };

        axios.post(`${configJSON.baseURL.baseURL}/${configJSON.uploadResumeApiEndPoint}`, formData, config)
            .then((response : {data: {profile: {data: {attributes : {resume : {url: null}}}}}}) => {
                const resumeUrl = response.data?.profile?.data?.attributes?.resume?.url
                this.setState({
                    selectedFileResume: { ...this.state.selectedFileResume, fileUrl: resumeUrl },
                    loaderResume:false,progressResume:0,remainingTimeResume:0
                })
            })
            .catch(() => {
                toast.error("File Type is not supported")
                this.setState({
                    loaderResume:false,
                    selectedFileResume: { file: null,
                        sizeExceed: false,
                        validFileType: false,
                        fileUrl: null 
                    }
                })
            });
    }

    deleteFile(type: string) {
        const authToken = localStorage.getItem("authToken")
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        if (type === "resume") {
            this.deleteResumeApiCallId = requestMessage.messageId;
        } else {
            this.deleteCoverLetterApiCallId = requestMessage.messageId;
        }

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.updateUserDetailsEndPoint}/remove_attachment?attachment_type=${type}`
        );

        const header = {
            token: authToken
        };

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.deleteMethod
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    addCoverLetter() {
        const authToken = localStorage.getItem("authToken");
        const startTime = Date.now();
        this.setState({loaderCover:true})

        const formData = new FormData();
        formData.append("cover_letter", this.state.selectedFileCoverLetter.file!);

        const config: AxiosRequestConfig = {
            headers: { token: authToken ?? '' },
            onUploadProgress: (progressEvent: AxiosProgressEvent) => {
              const { loaded, total } = progressEvent;
              if (total) {
                const percentCompleted = Math.round((loaded * 100) / total);
              
              const currentTime = Date.now();
              const elapsedTime = (currentTime - startTime) / 1000;
      
              const uploadSpeed = loaded / elapsedTime;
      
              const remainingTime = (total - loaded) / uploadSpeed;
      
              this.setState({progressCover:percentCompleted,remainingTimeCover:remainingTime})
              }
            }
          };

        axios.post(`${configJSON.baseURL.baseURL}/${configJSON.uploadCoverLetterApiEndPoint}`, formData, config)
            .then((response : {data: {profile: {data: {attributes: {cover_letter: { url : null}}}}}}) => {
                const coverLetterUrl = response.data?.profile?.data?.attributes?.cover_letter?.url
                this.setState({
                    selectedFileCoverLetter: { ...this.state.selectedFileCoverLetter, fileUrl: coverLetterUrl },
                    loaderCover:false,progressCover:0,remainingTimeCover:0
                })
            })
            .catch(() => {
                toast.error("File Type is not supported")
                this.setState({
                    loaderResume:false,
                    selectedFileCoverLetter: { file: null,
                        sizeExceed: false,
                        validFileType: false,
                        fileUrl: null 
                    }
                })
            });
    }

    updateDirectApproach(value: boolean) {
        const formData = new FormData();
        formData.append("profile[open_for_direct_approach]", value.toString())

        const authToken = localStorage.getItem("authToken")
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.updateDirectApproachApiCallId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.updateUserDetailsEndPoint}/:${this.state.userDetails.id}`
        );

        const header = {
            token: authToken
        };

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.putMethod
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            formData
        )

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    deleteWorkExperience(workExperienceId: number) {
        const authToken = localStorage.getItem("authToken")
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.deleteWorkExperienceApiCallId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.updateUserDetailsEndPoint}/work_experiences/${workExperienceId}`
        );

        const header = {
            token: authToken
        };

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.deleteMethod
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    deleteDealExperience(experienceId: number) {
        const authToken = localStorage.getItem("authToken")
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.deleteDealExperienceApiCallId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.getDealExperienceEndPoint}/${experienceId}`
        );

        const header = {
            token: authToken
        };

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.deleteMethod
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    getWorkExperience() {
        const authToken = localStorage.getItem("authToken")
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getWorkExperienceApiCallId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.workExperienceApi}`
        );

        const header = {
            token: authToken
        };

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.methodTypeApiGetUserProfile
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    getPosts(filterValue: string) {
        const authToken = localStorage.getItem("authToken")
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        if (filterValue === 'posts') {
            this.getPostsApiCallId = requestMessage.messageId;
        }
        else if (filterValue === "reactions") {
            this.getReactionPostsApiCallId = requestMessage.messageId;
        }
        else {
            this.getCommentsPostsApiCallId = requestMessage.messageId;
        }

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.updateUserDetailsEndPoint}/account_activity?filter=${filterValue}`
        );

        const header = {
            "Content-Type": configJSON.contentTypeApiGetUserProfile,
            token: authToken
        };

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.methodTypeApiGetUserProfile
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    getCommentsOfPost = (commentId: number | string) => {
        const authToken = localStorage.getItem("authToken")

        const header = {
            'Content-Type': configJSON.ApiContentType,
            token: authToken
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getAllCommentsForApiId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `/bx_block_comments/comments?commentable_id=${commentId}&commentable_type=BxBlockPosts::Post`
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.methodTypeApiGetUserProfile
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    downvotePost = (postId: number | string, type: string, activityType: string) => {
        const authToken = localStorage.getItem("authToken")
        this.setState({
            updateActivityType: activityType
        })

        const header = {
            token: authToken,
        };

        let formData = new FormData();
        formData.append("dislikeable_id", `${postId}`);
        formData.append("dislikeable_type", type);

        const downvotePostMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.downvotePostApiCallId = downvotePostMessage.messageId;

        downvotePostMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            formData
        );

        downvotePostMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        downvotePostMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.downvoteEndpoint
        );

        downvotePostMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.postMethod
        );

        runEngine.sendMessage(downvotePostMessage.id, downvotePostMessage);
    }

    upvotePostApi = (postId: number | string, type: string, activityType: string) => {
        this.setState({
            updateActivityType: activityType
        })
        const authToken = localStorage.getItem("authToken")

        const header = {
            token: authToken,
        };


        let formData = new FormData();
        formData.append("likeable_id", `${postId}`);
        formData.append("likeable_type", type);

        const upvotePostMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.upvotePostApiCallId = upvotePostMessage.messageId;

        upvotePostMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        upvotePostMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            formData
        );

        upvotePostMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.upvoteEndpoint
        );

        upvotePostMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.postMethod
        );

        runEngine.sendMessage(upvotePostMessage.id, upvotePostMessage);
    }

    downvotePostComment = (postId: number | string, type: string) => {
        const authToken = localStorage.getItem("authToken")

        const header = {
            token: authToken,
        };

        let formData = new FormData();
        formData.append("dislikeable_id", `${postId}`);
        formData.append("dislikeable_type", type);

        const downvotePostMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.downvotePostCommentApiCallId = downvotePostMessage.messageId;

        downvotePostMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            formData
        );

        downvotePostMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        downvotePostMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.downvoteEndpoint
        );

        downvotePostMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.postMethod
        );

        runEngine.sendMessage(downvotePostMessage.id, downvotePostMessage);
    }

    upvotePostComment = (postId: number | string, type: string) => {
        const authToken = localStorage.getItem("authToken")
        const header = {
            token: authToken,
        };
        let formData = new FormData();
        formData.append("likeable_id", `${postId}`);
        formData.append("likeable_type", type);

        const upvotePostMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.upvotePostCommentApiCallId = upvotePostMessage.messageId;

        upvotePostMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        upvotePostMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            formData
        );

        upvotePostMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.upvoteEndpoint
        );

        upvotePostMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.postMethod
        );

        runEngine.sendMessage(upvotePostMessage.id, upvotePostMessage);
    }

    handleInputChangeDebounced = debounce(() => {

        const headers = {
            "Content-Type": configJSON.validationApiContentType,
            "token": localStorage.getItem("authToken")
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.skillsListApiId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.contentManagementApi}/get_skills`
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.methodTypeApiGetUserProfile
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }, 2000)

    getPostDetails = (postId: number | string) => {
        const authToken = localStorage.getItem("authToken")

        const header = {
            'Content-Type': configJSON.ApiContentType,
            token: authToken
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getPostDetailsApiId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `/bx_block_posts/posts/${postId}`
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.httpGetMethod
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    selectTopicsHandler = () => {
        let token = localStorage.getItem("authToken");

        const header = {
            'Content-Type': configJSON.ApiContentType,
            token: token
        };
        const getTopics = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.selectTopicsId = getTopics.messageId;
        getTopics.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.selectTopicsApi
        );
        getTopics.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        getTopics.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.methodTypeApiGetUserProfile
        );

        runEngine.sendMessage(getTopics.id, getTopics);
    }

    updateComment = async(comment: string = "", files: (File | { url: string })[] = [], nested: boolean = false, commentId: number | string = "", postID: number | string = "") => {
        if (files.length > 0) {
          this.setState({ loaderComment: true })
        }
        const startTime = Date.now();
        const authToken = await getStorageData("authToken")
      
        let commentData = new FormData();
        commentData.append("comment[comment]", `${comment}`);
      
        commentData.append("comment[commentable_type]", !nested ? "BxBlockPosts::Post" : "BxBlockComments::Comment");
        
        files.forEach((file: File | { url: string }, _index: number) => {
            commentData.append(`comment[comment_images][]`, (file as { url: string })?.url ? (file as { url: string }).url : file as File);
        });
      
        const config: AxiosRequestConfig = {
          headers: { token: authToken ?? '' },
          onUploadProgress: (progressEvent: AxiosProgressEvent) => {
            const { loaded, total } = progressEvent;
            if (total) {
              const percentCompletedOne = Math.round((loaded * 100) / total)
              const currentTimeOne = Date.now()

              const elapsedTimeOne = (currentTimeOne - startTime) / 1000;
              const uploadSpeed = loaded / elapsedTimeOne
      
              const remainingTimeOne = (total - loaded) / uploadSpeed;
              this.setState({ 
                progressComment: percentCompletedOne, remainingTimeComment: remainingTimeOne 
            })
            }
          }
        };
      
        axios.patch(`${configJSON.baseURL.baseURL}/${configJSON.getCommentsEndPoint}/${commentId}`, commentData, config)
            .then(() => {
                this.getCommentsOfPost(this.state.postShowID);
            }).finally(() => {
                this.setState({
                    loaderComment: false,
                    progressComment: 0,
                    remainingTimeComment: 0
                })
            });
      }

      handleDeleteSkill = async (skillName: string[] | string) => {
        const authToken = await getStorageData("authToken")
        const skill = typeof skillName === 'string' ? skillName : skillName[0]
      
        const headers = {
          "token": authToken,
          "Content-Type": configJSON.validationApiContentType,
        };

        this.setState({
            selectedSkill: skill
        })

        const skillObject = {
            "skill_name": skill,
        }
      
        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
      
        this.deleteSkillApiId = requestMessage.messageId;
      
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(headers)
        );
      
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          `${configJSON.removeSkillApi}?token=${authToken}`
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(skillObject)
        )
      
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.deleteMethod
        );
      
        runEngine.sendMessage(requestMessage.id, requestMessage);
      }
      
      handleDeleteComment = async(commentId: number | string) => {
        const authToken = await getStorageData("authToken")
      
        const headers = {
          "token": authToken,
        };
      
        const downvotePostMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
      
        this.deleteCommentApiId = downvotePostMessage.messageId;
      
        downvotePostMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(headers)
        );
      
        downvotePostMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          `${configJSON.getCommentsEndPoint}/${commentId}`
        );
      
        downvotePostMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.deleteMethod
        );
      
        runEngine.sendMessage(downvotePostMessage.id, downvotePostMessage);
      }

    getDealExperience = async () => {
        const authToken = await getStorageData("authToken")
        const headers = {
          "token": authToken,
          "Content-Type": configJSON.validationApiContentType,
        };
    
        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getDealExperienceApiId = requestMessage.messageId;
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          `${configJSON.getDealExperienceEndPoint}`
        );
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(headers)
        );
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.validationApiMethodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
      }
    // Customizable Area End
}